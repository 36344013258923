import { useEffect, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import SvgCalendar from '../../icons/SvgCalendar';
import SvgDownload from '../../icons/SvgDownload';
import SvgView from '../../icons/SvgView';
import SvgSubcriptioncrown from '../../icons/Subscriptioncrown';
import SvgPlay from '../../icons/play';
import SvgPause from '../../icons/pause';
import SvgSelected from '../../icons/selected';
import SvgMoreinfo from '../../icons/moreinfo';
import Svgcalenderscheduler from '../../icons/calenderschedule';
import { AppDispatch, RootState } from '../../store';
import Button from '../../uikit/Button/Button';
import Card from '../../uikit/Card/Card';
import { PRIMARY } from '../../uikit/Colors/colors';
import Flex from '../../uikit/Flex/Flex';
import {
  getDateString,
  isEmpty,
  notSpecified,
  toCamelCase,
  workExperience,
} from '../../uikit/helper';
import Text from '../../uikit/Text/Text';
import Loader from '../../uikit/Loader/Loader';
import LinkWrapper from '../../uikit/Link/LinkWrapper';
import { ADD_FAV, dndBoardId, REMOVE_FAV } from '../constValue';
import {
  checkAuthMiddleware,
  getUsersByCompanyMiddleware,
  getEventsMiddleware,
  getGoogleEventsMiddleware,
  syncOutlookMiddleWare,
  applicantFavoriteMiddleWare,
} from '../applicantprofilemodule/store/middleware/applicantProfileMiddleware';
import MeetingSchedulingScreen from '../calendarModule/MeetingSchedulingScreen';
import {
  CALENDAR,
  EditEventDetails,
  GoogleEventType,
  ZitaEventType,
} from '../calendarModule/types';
import { getEditEventsDetails } from '../calendarModule/util';
import SvgHeart from '../../icons/SvgHearts';
import Avatar, { getUserInitials } from '../../uikit/Avatar';
import { CheckBox } from '../../uikit';
import SvgEmail from '../../icons/SvgEmail';
import CustomTooltip from '../../uikit/Tooltip/tooltip';
import { calendarRoute } from '../../appRoutesPath';
import { workYear } from '../common/commonHelper';
import {
  ApplicantEntity,
  GoogleEntity,
  ICardSelectionData,
  IntegrateEntity,
  JobDetailsEntity,
  KANBAN_COLUMN_WIDTH,
} from './applicantPipeLineTypes';
import ProfileView from './ProfileView';

import styles from './multitask.module.css';
import { IStageColumn } from './dndBoardTypes';
import { downloadApplicantsMiddleware } from './store/middleware/applicantpipelinemiddleware';

type Props = {
  task: ApplicantEntity;
  index: number;
  isBorder: string;
  column: IStageColumn;
  outlook?: GoogleEntity[];
  google?: GoogleEntity[];
  job_details: JobDetailsEntity;
  onClick?: (data: ICardSelectionData) => void;
  isSelected: boolean;
  onRefresh?: () => void;
  active_resume: any;
  new_apply_count: any;
  open_subscription: (a: any, b: any) => void;
  setbackfor: any;
  column_detail?: any;
  mailtoggle?: any;
  showAutomatePopup?: any;
  setShowAutomatePopup?: any;
};
const MultiTask = ({
  task,
  index,
  isBorder,
  column,
  google,
  outlook,
  job_details,
  onClick,
  isSelected,
  onRefresh,
  active_resume,
  new_apply_count,
  open_subscription,
  setbackfor,
  column_detail,
  mailtoggle,
  showAutomatePopup,
  setShowAutomatePopup,
}: Props) => {
  const history = useHistory();
  const { section, columnId, stage_name } = column;
  const [isCalender, setCalender] = useState('popup');
  const [isProfileView, setProfileView] = useState(false);
  const [istabsetting, settabsetting] = useState<any>(0);
  const [datachange, setdatachange] = useState<any>(false);
  const [filtered_column, setFilteredColumn] = useState<any>();
  const [isfilterjd, setfilterjd] = useState<any>();
  const [isfiltercan_id, setfiltercan_id] = useState<any>();
  const [isfilterstage_id, setfilterstage_id] = useState<any>();
  const [isuniquid, setuniqueid] = useState<any>(1);
  const [ischangingid, setchangingid] = useState<any>();
  const [isendofbutton, setendofbutton] = useState<any>();
  const [isprofilefromapplicant, setprofilefromapplicant] =
    useState<any>(false);
  const dispatch: AppDispatch = useDispatch();
  const workExp = workExperience(task.work_exp, task.work_exp_mon);
  const match = isEmpty(task.match) ? 0 : task.match;

  const { permission, super_user, roles, stages } = useSelector(
    ({
      permissionReducers,
      userProfileReducers,
      dashboardEmpReducers,
      applicantStausReducers,
    }: RootState) => {
      return {
        permission: permissionReducers.Permission,
        super_user: permissionReducers.super_user,
        roles: permissionReducers.roles,
        stages: applicantStausReducers?.stages,
      };
    },
  );

  useEffect(() => {
    if (google?.length === 0 && outlook?.length === 0) {
      setCalender('popup');
    }
    if (outlook?.length === 1) {
      setCalender('outlook');
    }
    if (google?.length === 1) {
      setCalender('google');
    }
  }, [google, outlook]);

  // let link: string;
  // if (isCalender === 'outlook') {
  //   link = 'https://outlook.office365.com/calendar/view/week';
  // }
  // if (isCalender === 'google') {
  //   link = 'https://calendar.google.com/calendar/u/0/r?tab=rc';
  // }
  const getDate =
    getDateString(new Date(), 'DD MMM YYYY') ===
    getDateString(task.created_on, 'DD MMM YYYY');

  const hanldeProfileView = (data) => {
    setProfileView(true);
    setprofilefromapplicant(true);
    settabsetting(data);
  };
  const [editDetails, setEditDetails] = useState<EditEventDetails>();
  const [editEvent, setEditEvent] = useState(false);
  const [eventId, setEventId] = useState('');
  const editEventHandler = () => {
    let candId = task.candidate_id_id;
    let jdId = task.jd_id_id;
    dispatch(getEventsMiddleware({ candId, jdId })).then((res) => {
      if (res.payload.data === true) {
        setEventId(res.payload.event[0].eventId);
        setEditDetails(
          res.payload.event.map((event: ZitaEventType) =>
            getEditEventsDetails(event),
          ),
        );
        setEditEvent(true);
      } else {
        setEditEvent(false);
      }
    });
  };
  const { checkauth } = useSelector(
    ({ applicantPipeLineDataReducers }: RootState) => {
      return {
        checkauth: applicantPipeLineDataReducers.checkauth,
        // job_details: applicantPipeLineReducers.job_details
      };
    },
  );
  const handleIntegrateTab = () => {
    history.push('/account_setting/integration');
  };
  const calenderTitle =
    checkauth && checkauth.status !== true
      ? 'Integrate your calendar to schedule meetings.'
      : 'Schedule Meetings';

  const identifyCalendarNav = (doc: ApplicantEntity) => {
    if (checkauth && checkauth.status === true) {
      const params = new URLSearchParams();
      params.append('action', 'open-scheduler-form');
      params.append('id', `${doc.id}`);
      params.append('jobId', `${doc.jd_id_id}`);
      params.append('name', doc.name);
      params.append('jobTitle', job_details.job_title);
      params.append('emailid', doc.email);
      params.append('can_id', doc.candidate_id_id.toString());
      const url = `${calendarRoute}?${params}`;
      window.open(url);
    } else {
      // sessionStorage.setItem('superUserTab', '4');
      // sessionStorage.setItem('superUserFalseTab', '3');
      handleIntegrateTab();
      history.push('/account_setting/integration');
    }
  };

  const Upgradeplan = () => {
    history.push('/account_setting/subscription?planFocus=focus');
  };
  // const scheduleEventHandler = () => {
  //   setIsLoad(true);
  //   const params = new URLSearchParams();
  //   params.append('action', 'open-scheduler-form');
  //   const url = `${calendarRoute}?${params}`;
  //   window.open(url);
  //   dispatch(checkAuthMiddleware())
  //     .then((res) => {
  //       const payload = res.payload as IntegrateEntity;
  //       if (payload.status === true) {
  //         if (payload.account === 'google') {
  //           setIsGoogle(1);
  //         } else {
  //           setIsGoogle(0);
  //         }
  //         getEventHandler(payload.account);
  //       } else {
  //         // setActive(0);
  //       }
  //     })
  //     .then(() => {
  //       dispatch(getUsersByCompanyMiddleware()).then((res) => {
  //         setUsers(
  //           res.payload.users.map(
  //             (items: {
  //               email: string;
  //               first_name: string;
  //               last_name: string;
  //               user_id: number;
  //             }) => {
  //               return {
  //                 email: items.email,
  //                 firstName: items.first_name,
  //                 lastName: items.last_name,
  //                 userId: items.user_id,
  //               };
  //             },
  //           ),
  //         );
  //       });
  //     })
  //     .then(() => {
  //       // let candId = task.candidate_id_id
  //       // let jdId = task.jd_id_id
  //       // dispatch(getEventsMiddleware({candId,jdId})).then((res) => {
  //       //   if(res.payload.data === true){
  //       //     let stime = new Date(res.payload.event[0].s_time).toLocaleTimeString('en',
  //       //     { timeStyle: 'short', hour12: false, timeZone: 'UTC' });
  //       //     let etime = new Date(res.payload.event[0].e_time).toLocaleTimeString('en',
  //       //     { timeStyle: 'short', hour12: false, timeZone: 'UTC' });
  //       //     setValue([
  //       //       {
  //       //         'applicant':res.payload.event[0].applicant,
  //       //         'sDate':res.payload.event[0].s_time,
  //       //         'sTime':stime,
  //       //         'eTime':etime,
  //       //         'event':res.payload.event[0].event_type,
  //       //         'interviewers':res.payload.event[0].interviewers.split(",")
  //       //       }
  //       //     ])
  //       //     setIsLoad(false);
  //       //     setCard(true);
  //       //   }
  //       //   else{
  //       //     setIsLoad(false);
  //       //     openForm();
  //       //   }
  //       // }).catch(() => {
  //       //   setIsLoad(false);
  //       // })
  //       editEventHandler();
  //     });
  // };
  // const [value,setValue] = useState<any[]>([]);
  // const [card,setCard] = useState(false);
  const [isGoogle, setIsGoogle] = useState(2);
  const [open, setOpen] = useState(false);
  const [users, setUsers] = useState<any[]>([]);
  const [active, setActive] = useState(0);
  const [isLoad, setIsLoad] = useState(true);
  const [myevents, setMyevents] = useState<any[]>([]);
  const [userName, setUserName] = useState('');

  // const [download,setdownload]=useState(["candidate_resume/AnshulKhare_1_iLsbaL2.doc","candidate_resume/AmarJain_1_w74asKQ.doc"])

  const openForm = () => {
    setOpen((prevState) => !prevState);
  };
  const getEventHandler = (account: string) => {
    if (account === 'google') {
      let tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
      dispatch(getGoogleEventsMiddleware({ tz }))
        .then((res) => {
          const data = res.payload.events;
          let name = '';
          if (res.payload.user[0].user__first_name) {
            name += res.payload.user[0].user__first_name;
            name += ' ';
          }
          if (res.payload.user[0].user__last_name) {
            name += res.payload.user[0].user__last_name;
          }
          setUserName(name);
          setMyevents(
            data.map((items: GoogleEventType) => {
              const eventData: any = {
                user: res.payload.userId,
                title: items.summary,
                start: new Date(items.start.dateTime),
                end: new Date(items.start.dateTime),
                link: 'hangoutLink' in items ? items.hangoutLink : '',
                eventId: items.id,
                color: '#fcba03',
                organizer: items.organizer.email,
                synced: res.payload.user[0].user__first_name,
              };

              if ('attendees' in items) {
                eventData['attendees'] = items.attendees.map(
                  (attendee: { email: any }) => {
                    return attendee.email;
                  },
                );
              }
              return eventData;
            }),
          );
          setIsLoad(false);
          setActive(1);
          setOpen(true);
        })
        .catch((err) => {
          setIsLoad(false);
        });
    } else {
      dispatch(syncOutlookMiddleWare())
        .then((res) => {
          let name = '';
          if (res.payload.user[0].user__first_name) {
            name += res.payload.user[0].user__first_name;
            name += ' ';
          }
          if (res.payload.user[0].user__last_name) {
            name += res.payload.user[0].user__last_name;
          }
          setUserName(name);
          setMyevents(
            res.payload.events.map(
              (items: {
                created_by: string;
                attendees: string;
                event_id: string;
                title: any;
                start_time: string | number | Date;
                end_time: string | number | Date;
              }) => {
                return {
                  user: res.payload.userId,
                  title: items.title,
                  start: new Date(items.start_time),
                  end: new Date(items.end_time),
                  eventId: items.event_id,
                  attendees: items.attendees,
                  organizer: items.created_by,
                  link: '',
                  color: '#fcba03',
                  synced: res.payload.user[0].user__first_name,
                };
              },
            ),
          );
          setIsLoad(false);
          setActive(1);
          setOpen(true);
        })
        .catch((err) => {
          setIsLoad(false);
        });
    }
  };

  useEffect(() => {
    setIsLoad(false);
  }, []);

  const hanldeFavAction = (can_id: number, jd_id: number) => {
    dispatch(applicantFavoriteMiddleWare({ can_id, jd_id }));
  };

  useEffect(() => {
    if (filtered_column) {
      setfilterjd(filtered_column.jd_id_id);
      setfilterstage_id(filtered_column.stage_id_id);
    }
  }, [filtered_column, isfiltercan_id]);

  const datakeysetting = () => {
    setuniqueid(isuniquid + 1);
  };

  // function for handling the next and previous button.
  const buttonchange = (data) => {
    if (data === 1) {
      setchangingid('next');
      localStorage.setItem('buttonhandling', 'next');
    }
    if (data === 0) {
      setchangingid('privious');
      localStorage.setItem('buttonhandling', 'privious');
    }
  };
  useEffect(() => {
    setchangingid(localStorage.getItem('buttonhandling'));
  }, [localStorage.getItem('buttonhandling')]);

  const togglebutton = (val) => {
    if (stages.length !== 0) {
      if (stages[stages.length - 1]?.stage_id === null) {
        const value = column_detail.filter((data) => data.id === 0);
        const currentIndex =
          value &&
          value[0]?.items?.findIndex(
            (item) =>
              item?.candidate_id_id ===
              stages[stages?.length - 1]?.candidate_id,
          );
        if (val === 1) {
          if (value && value[0]?.items.length >= currentIndex + 1) {
            setFilteredColumn(value[0]?.items[currentIndex + 1]);
            setfiltercan_id(value[0]?.items[currentIndex + 1].candidate_id_id);
          }
        } else {
          if (value && value[0]?.items.length >= currentIndex - 1) {
            setFilteredColumn(value[0]?.items[currentIndex - 1]);
            setfiltercan_id(value[0]?.items[currentIndex - 1].candidate_id_id);
          }
        }
      } else {
        const value = column_detail.filter(
          (data) => data.id === stages[stages.length - 1].stage_id,
        );
        const currentIndex =
          value &&
          value[0]?.items?.findIndex(
            (item) =>
              item?.candidate_id_id ===
              stages[stages?.length - 1]?.candidate_id,
          );
        if (val === 1) {
          if (value && value[0]?.items.length >= currentIndex + 1) {
            setFilteredColumn(value[0]?.items[currentIndex + 1]);
            setfiltercan_id(value[0]?.items[currentIndex + 1].candidate_id_id);
          }
        } else {
          if (value && value[0]?.items.length >= currentIndex - 1) {
            setFilteredColumn(value[0]?.items[currentIndex - 1]);
            setfiltercan_id(value[0]?.items[currentIndex - 1].candidate_id_id);
          }
        }
      }
      setdatachange(true);
    }
  };

  useEffect(() => {
    if (filtered_column) {
      setfilterjd(filtered_column.jd_id_id);
      setfilterstage_id(filtered_column.stage_id_id);
    }
  }, [filtered_column, isfiltercan_id]);

  const checkfunction = (val) => {
    var isPresent = active_resume.includes(val);

    if (isPresent) {
      return (
        <Card className={styles.cardStyle}>
          <Flex
            className={styles.cardFlexStyle}
            style={{
              borderColor: isBorder,
              borderLeftColor: isBorder,
              backgroundColor: isSelected ? `${isBorder}40` : undefined,
              cursor: 'pointer',
            }}
            onClick={(e) => {
              setbackfor(1);
              hanldeProfileView(0);
            }}
          >
            <Flex
              row
              style={{
                cursor: 'pointer',
              }}
            >
              <CheckBox
                checked={isSelected}
                onClick={(e) => {
                  if (onClick) {
                    onClick({ task, section, columnId });
                    e.stopPropagation();
                  }
                }}
              />
              <Flex
                style={{ position: 'relative', cursor: 'pointer' }}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <div className={styles.profile} style={{ cursor: 'pointer' }}>
                  {task.image !== 'default.jpg' ? (
                    <>
                      <img
                        style={{ objectFit: 'cover' }}
                        alt="" // Both Profile
                        className={styles.profile}
                        src={`${process.env.REACT_APP_HOME_URL}media/${task.image}`}
                      />
                    </>
                  ) : (
                    <>
                      <Avatar
                        className={styles.profile}
                        initials={getUserInitials({
                          firstName: task.first_name,
                          lastName: task.last_name,
                        })}
                      />
                    </>
                  )}
                  {task.block_descriptive === true ? (
                    <Flex className={styles.percentageblur}>
                      <Text title="Upgrade or purchase an add-on to view scores.">
                        {' '}
                        0 %
                      </Text>
                    </Flex>
                  ) : (
                    <>
                      <div className={styles.percentageStyle}>
                        <Text bold>{match}%</Text>
                      </div>
                    </>
                  )}
                </div>
              </Flex>
              <Flex
                columnFlex
                className={styles.nameContainer}
                style={{ cursor: 'pointer' }}
              >
                <Flex
                  row
                  center
                  // marginRight={16}
                  style={{
                    cursor: 'pointer',
                    flexDirection: 'row',
                    width: '100%',
                  }}
                >
                  <Flex
                    title={`${toCamelCase(task.first_name || '')} ${toCamelCase(
                      task.last_name || '',
                    )}`}
                  >
                    <Button
                      types="link"
                      className={styles.linkBtnStyle}
                      onClick={(e) => {
                        setbackfor(1);
                        hanldeProfileView(0);
                        e.stopPropagation();
                      }}
                    >
                      <Text bold color="theme" textStyle={'ellipsis'}>
                        {`${toCamelCase(task.first_name || '')} ${toCamelCase(
                          task.last_name || '',
                        )}`}
                      </Text>
                    </Button>
                  </Flex>
                  <Flex
                    className={styles.svgView}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    title={
                      isEmpty(task.viewed) ? 'Yet to View' : 'Profile Viewed'
                    }
                  >
                    <SvgView
                      nonView={isEmpty(task.viewed)}
                      width={16}
                      height={16}
                    />
                  </Flex>
                </Flex>
                <Flex row center style={{ cursor: 'pointer' }}>
                  <Flex
                    title={`Location: ${
                      task.location === 'None' ||
                      task.location === 'Not Found' ||
                      task.location === null
                        ? 'Not Specified'
                        : task.location
                    }`}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    {/* {task.location && ( */}
                    <Text
                      size={12}
                      color="black2"
                      // textStyle="ellipsis"
                      className={styles.pointer}
                    >
                      {/* {task.location} */}
                      {task.location === 'None' ||
                      task.location === 'Not Found' ||
                      task.location === null
                        ? 'Not Specified'
                        : task.location}
                    </Text>
                    {/* )} */}
                  </Flex>
                </Flex>
                <Flex row center style={{ cursor: 'pointer' }}>
                  {task.total_exp === null || undefined || '' ? (
                    <Flex
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <Text
                        size={12}
                        color="black2"
                        // textStyle="ellipsis"
                        title={`${'Experience:'} ${notSpecified(
                          workYear(task.total_exp),
                        )}`}
                        style={{
                          // maxWidth: isEmpty(task.qualification) ? '100%' : '95%',
                          marginRight: 2,
                        }}
                        className={styles.pointer}
                      >
                        {notSpecified(workYear(task.total_exp))}
                      </Text>
                    </Flex>
                  ) : (
                    <Flex
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <Text
                        size={12}
                        color="black2"
                        // textStyle="ellipsis"
                        title={
                          task.total_exp === null || undefined || ''
                            ? 'Experience: Not Specified'
                            : `${'Experience:'} ${
                                task.total_exp?.includes('-' || 'years') ||
                                !/\d+ Years?/.test(task.total_exp) ||
                                !/\d+ Year?/.test(task.total_exp)
                                  ? notSpecified(workYear(task.total_exp))
                                  : notSpecified(task.total_exp)
                              }`
                        }
                        style={{
                          // maxWidth: isEmpty(task.qualification) ? '100%' : '95%',
                          marginRight: 2,
                        }}
                        className={styles.pointer}
                      >
                        {task.total_exp?.includes('-' || 'years') ||
                        !/\d+ Years?/.test(task.total_exp) ||
                        !/\d+ Year?/.test(task.total_exp)
                          ? notSpecified(workYear(task.total_exp))
                          : notSpecified(task.total_exp)}
                      </Text>
                    </Flex>
                  )}

                  {task.qualification && <Text color="black2"> | </Text>}
                  <Flex
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <Text
                      size={12}
                      color="black2"
                      title={task.qualification}
                      // textStyle="ellipsis"
                      style={{ marginLeft: 2 }}
                      className={styles.pointer}
                    >
                      {task.qualification}
                    </Text>
                  </Flex>
                </Flex>
                <Flex
                  style={{ cursor: 'pointer' }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <Text
                    size={12}
                    color="black2"
                    textStyle="ellipsis"
                    title={getDateString(task.created_on, 'll hh:mm A')}
                  >
                    {getDateString(task.created_on, 'll hh:mm A')}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
            <Flex
              row
              center
              between
              style={{
                display: 'flex',
                flexDirection: 'row',
                cursor: 'pointer',
                width: '100%',
              }}
            >
              <Flex row between center style={{ marginLeft: '8px' }}>
                {/* {task?.stage_id_id !== null && (
                  <Flex center title={task?.current_stage} marginRight={8}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    {task?.current_stage === 'To Do' ? (
                      <Flex marginTop={1}>
                        <SvgMoreinfo width={14} height={14} />
                      </Flex>
                    ) : task?.current_stage === 'In Progress' ? (
                      <Flex marginTop={1}>
                        <SvgPlay width={14} height={14} />
                      </Flex>
                    ) : task?.current_stage === 'On-Hold' ? (
                      <Flex marginTop={1}>
                        <SvgPause width={14} height={14} />
                      </Flex>
                    ) : (
                      <Flex marginTop={1}>
                        <SvgSelected width={14} height={14} />
                      </Flex>
                    )}
                  </Flex>
                )} */}
                <Flex row center>
                  {/* {columnId === 0 && getDate && (
                    <Flex
                      className={styles.svgNewTag}
                      style={{ cursor: 'pointer' }}
                    >
                      <img
                        style={{ objectFit: 'contain' }}
                        alt=""
                        height={19}
                        width={45}
                        src="https://i.ibb.co/fFSqFCW/new.png"
                      />
                    </Flex>
                  )}
                  {task.interview_scheduled === true &&
                    task?.event_timings !== null && (
                      <Flex
                        center
                        row
                        onClick={(e) => {
                          setbackfor(1);
                          hanldeProfileView(2);
                          e.stopPropagation();
                        }}
                        style={{ cursor: 'pointer' }}
                        marginLeft={columnId === 0 && getDate && 10}
                      >
                        <SvgCalendar fill={PRIMARY} width={16} height={16} />
                        {
                          <Text
                            bold
                            style={{ marginLeft: '2px', color: '#581845' }}
                          >
                            {moment(task?.event_timings).format('MMM DD')}
                          </Text>
                        }
                      </Flex>
                    )} */}
                  <Text
                    color="theme"
                    bold
                    size={12}
                    className={styles.pointerstage}
                    title={task.current_stage}
                    style={{
                      cursor: 'pointer',
                      fontFamily: 'georgia',
                      fontStyle: 'italic',
                    }}
                    onClick={(e) => {
                      hanldeProfileView(5);
                      e.stopPropagation();
                    }}
                  >
                    {task.current_stage}
                  </Text>
                </Flex>
              </Flex>

              <Flex
                row
                end
                center
                className={styles.svgContainer}
                style={{ cursor: 'pointer' }}
              >
                {mailtoggle === true && (
                  <CustomTooltip title="Automation is ON, and email will be sent automatically.">
                    <div
                      tabIndex={-1}
                      role={'button'}
                      onClick={(e) => {
                        setShowAutomatePopup(true);
                        e.stopPropagation();
                      }}
                    >
                      <div style={{ marginRight: '5px' }}>
                        <SvgEmail height={13} width={18} fill={'none'} />
                      </div>
                    </div>
                  </CustomTooltip>
                )}
                <CustomTooltip title="Download Resume">
                  <div
                    onClick={(e) => {
                      dispatch(
                        downloadApplicantsMiddleware({
                          jd_id: task.jd_id_id.toString(),
                          candidate_id: [task.candidate_id_id],
                          download: 'download',
                        }),
                      );
                      e.stopPropagation();
                    }}
                    tabIndex={-1}
                    role={'button'}
                    onKeyPress={() => {}}
                  >
                    <SvgDownload
                      className={styles.svgDownloadStyle}
                      width={16}
                      height={16}
                    />
                  </div>
                </CustomTooltip>
                <CustomTooltip title={isEmpty(task.fav) ? ADD_FAV : REMOVE_FAV}>
                  <div
                    onClick={(e) => {
                      hanldeFavAction(task.candidate_id_id, task.jd_id_id);
                      e.stopPropagation();
                    }}
                    tabIndex={-1}
                    role={'button'}
                    onKeyPress={() => {}}
                  >
                    <SvgHeart
                      className={styles.svgDownloadStyle}
                      width={16}
                      height={16}
                      filled={!isEmpty(task.fav)}
                      fill="#ED4857"
                    />
                  </div>
                </CustomTooltip>
                {columnId !== 0 && stage_name !== 'Rejected' && (
                  <Flex
                    onClick={(e) => {
                      identifyCalendarNav(task);
                      e.stopPropagation();
                    }}
                    style={{ cursor: 'pointer' }}
                    title={calenderTitle}
                  >
                    <Svgcalenderscheduler
                      fill={PRIMARY}
                      width={16}
                      height={16}
                    />
                  </Flex>
                  // </a>
                )}
              </Flex>
            </Flex>
          </Flex>
        </Card>
      );
    } else {
      return (
        <Flex className={styles.sample}>
          <Flex className={styles.sampletext}>
            <Flex middle center>
              <Flex className={styles.crownicons} center middle>
                <SvgSubcriptioncrown />
              </Flex>
            </Flex>
            <Text style={{ marginTop: '10px' }}>
              {new_apply_count()}+ Applicants
            </Text>
            <Text
              style={{ marginTop: '5px', cursor: 'pointer' }}
              bold
              color="theme"
              onClick={() => open_subscription(true, 0)}
            >
              Upgrade Now
            </Text>
          </Flex>
          <Card className={styles.cardsample}>
            <Flex
              className={styles.cardFlexStyle}
              style={{
                borderColor: isBorder,
                borderLeftColor: isBorder,
                backgroundColor: isSelected ? `${isBorder}40` : undefined,
                cursor: 'pointer',
              }}
            >
              <Flex
                row
                style={{
                  cursor: 'pointer',
                }}
              >
                <div style={{ position: 'relative', cursor: 'pointer' }}>
                  <div className={styles.profile} style={{ cursor: 'pointer' }}>
                    <Avatar
                      className={styles.profile}
                      avatar={
                        task.image && task.image !== 'default.jpg'
                          ? `${process.env.REACT_APP_HOME_URL}media/${task.image}`
                          : undefined
                      }
                      initials={getUserInitials({
                        firstName: 'Sample',
                        lastName: 'Test',
                      })}
                    />
                    {/* <img
                        style={{ objectFit: 'cover' }}
                        alt=""
                        className={styles.profile}
                        src={`${process.env.REACT_APP_HOME_URL}media/${task.image}`}
                      /> */}
                    <div className={styles.percentageStyle}>
                      <Text bold>{0}%</Text>
                    </div>
                  </div>
                </div>
                <Flex
                  columnFlex
                  className={styles.nameContainer}
                  style={{ cursor: 'pointer' }}
                >
                  <Flex
                    row
                    center
                    // marginRight={16}
                    style={{
                      cursor: 'pointer',
                      flexDirection: 'row',
                      width: '100%',
                    }}
                  >
                    <Button
                      types="link"
                      className={styles.linkBtnStyle}
                      onClick={(e) => {
                        hanldeProfileView(0);
                        e.stopPropagation();
                      }}
                    >
                      <Text
                        bold
                        color="theme"
                        textStyle={'ellipsis'}
                        title={`${'sample' || ''} ${'card' || ''}`}
                      >
                        {`${'Sample' || ''} ${'Card' || ''}`}
                      </Text>
                    </Button>
                    <CustomTooltip
                      title={
                        isEmpty(task.viewed) ? 'Yet to View' : 'Profile Viewed'
                      }
                    >
                      <div className={styles.svgView}>
                        <SvgView
                          nonView={isEmpty(task.viewed)}
                          width={16}
                          height={16}
                        />
                      </div>
                    </CustomTooltip>
                  </Flex>
                  <Flex row center style={{ cursor: 'pointer' }}>
                    {task.location && (
                      <Text
                        size={12}
                        color="black2"
                        textStyle="ellipsis"
                        title={'Dummy card'}
                      >
                        {'Dummy card'}
                      </Text>
                    )}
                  </Flex>
                  <Flex row center style={{ cursor: 'pointer' }}>
                    {task.total_exp === null || undefined || '' ? (
                      <Text
                        size={12}
                        color="black2"
                        textStyle="ellipsis"
                        title={'Not Specified'}
                        style={{
                          maxWidth: task.qualification ? '50%' : '80%',
                          marginRight: 2,
                        }}
                      >
                        {'data hide'}
                      </Text>
                    ) : (
                      <Text
                        size={12}
                        color="black2"
                        textStyle="ellipsis"
                        title={`${workYear(task.total_exp)} ${
                          task &&
                          task?.work_exp_mon !== 0 &&
                          task?.work_exp_mon !== null
                            ? task?.work_exp_mon
                            : ''
                        } ${
                          task &&
                          task?.work_exp_mon !== 0 &&
                          task?.work_exp_mon !== null
                            ? 'Months'
                            : ''
                        }`}
                        style={{
                          maxWidth: task.qualification ? '50%' : '80%',
                          marginRight: 2,
                        }}
                      >
                        {'data hide'}
                      </Text>
                    )}
                    {task.qualification && <Text color="black2"> | </Text>}
                    <Text
                      size={12}
                      color="black2"
                      textStyle="ellipsis"
                      title={task.qualification}
                      style={{ marginLeft: 2 }}
                    >
                      {'Data hide'}
                    </Text>
                  </Flex>
                  <Flex style={{ cursor: 'pointer' }}>
                    <Text
                      size={12}
                      color="black2"
                      textStyle="ellipsis"
                      title={getDateString(task.created_on, 'll hh:mm A')}
                    >
                      {'data hide'}
                      {/* {task.created_on} */}
                      {/* {getDateString(user_info?.last_login, 'll hh:mm A')} */}
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
              <Flex
                row
                end
                center
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  cursor: 'pointer',
                  width: '100%',
                }}
              >
                {columnId === 0 && getDate && (
                  <Flex
                    className={styles.svgNewTag}
                    style={{ cursor: 'pointer' }}
                  >
                    <img
                      style={{ objectFit: 'contain' }}
                      alt=""
                      height={19}
                      width={45}
                      src="https://i.ibb.co/fFSqFCW/new.png"
                    />
                  </Flex>
                )}
                <Flex
                  row
                  end
                  center
                  className={styles.svgContainer}
                  style={{ cursor: 'pointer' }}
                >
                  <CustomTooltip title="Download Resume">
                    <div
                      onClick={(e) => {}}
                      tabIndex={-1}
                      role={'button'}
                      onKeyPress={() => {}}
                    >
                      <SvgDownload
                        className={styles.svgDownloadStyle}
                        width={16}
                        height={16}
                      />
                    </div>
                  </CustomTooltip>
                  <CustomTooltip
                    title={isEmpty(task.fav) ? ADD_FAV : REMOVE_FAV}
                  >
                    <div
                      onClick={(e) => {
                        hanldeFavAction(task.candidate_id_id, task.jd_id_id);
                        e.stopPropagation();
                      }}
                      tabIndex={-1}
                      role={'button'}
                      onKeyPress={() => {}}
                    >
                      <SvgHeart
                        className={styles.svgDownloadStyle}
                        width={16}
                        height={16}
                        filled={!isEmpty(task.fav)}
                        fill="#ED4857"
                      />
                    </div>
                  </CustomTooltip>
                  {columnId !== 0 && stage_name !== 'Rejected' && (
                    <Flex
                      onClick={() => identifyCalendarNav(task)}
                      style={{ cursor: 'pointer' }}
                      title={calenderTitle}
                    >
                      <SvgCalendar fill={PRIMARY} width={16} height={16} />
                    </Flex>
                    // </a>
                  )}
                </Flex>
              </Flex>
            </Flex>
          </Card>
        </Flex>
      );
    }
  };

  return (
    <>
      {isLoad && <Loader />}
      {open && active === 1 ? (
        <div>
          {editEvent === true ? (
            <MeetingSchedulingScreen
              username={userName}
              currentUserEvents={myevents}
              cand_email={task.email}
              editEventDetails={editDetails}
              EventId={eventId}
              cand_id={task.candidate_id_id}
              jd_id={task.jd_id_id}
              cand_name={task.name}
              jd_name={job_details.job_title}
              // app_pipeline={true}
              calendarProvider={isGoogle ? CALENDAR.Google : CALENDAR.Outlook}
              teamMembers={users}
              openScheduleForm={open}
              handleEventScheduleForm={openForm}
            />
          ) : (
            <MeetingSchedulingScreen
              username={userName}
              currentUserEvents={myevents}
              cand_email={task.email}
              cand_id={task.candidate_id_id}
              jd_id={task.jd_id_id}
              cand_name={task.name}
              jd_name={job_details.job_title}
              // app_pipeline={true}
              calendarProvider={isGoogle ? CALENDAR.Google : CALENDAR.Outlook}
              teamMembers={users}
              openScheduleForm={open}
              handleEventScheduleForm={openForm}
            />
          )}
        </div>
      ) : null}
      {/* {card && 
        <Modal open={true}>
          <Flex column className={styles.overAll}>
            <Flex row marginBottom={"1%"}>
              <Text>{value[0].event}  with {value[0].applicant}</Text>
              <Flex onClick={cardHandler}>
                <SvgCloseSmall />
              </Flex>
            </Flex>
            <Flex row marginBottom={"2%"}>
              <SvgInterviewCalendar />
              <Flex column marginLeft={'1%'}>
                <Text>{value[0].sDate.slice(0,25)}</Text>
                <Flex row>
                  <Text>{value[0].sTime} to </Text>
                  <Text>{value[0].eTime}</Text>
                </Flex>
              </Flex>
            </Flex>
            <Flex row marginBottom={'1%'}>
              <SvgInterviewers />
              <Flex marginLeft={'4%'}>
                {value[0].interviewers.length>0 ?
                    <Flex column>
                        <Text>Attendees</Text>
                        {value[0].interviewers.map((items: string) => (
                            <Text key={index}>{items}</Text>
                        ))}
                    </Flex>
                    : null
                }
              </Flex>
            </Flex>
            <Flex row marginBottom={'1%'}>
              <SvgOrganizer />
              <Flex column marginLeft={'4%'}>
                  <Text>{userName}</Text>
                  <Text>Organizer</Text>
              </Flex>
            </Flex>
          </Flex>

        </Modal>
      } */}

      {datachange ? (
        <>
          <ProfileView
            key={isuniquid}
            open={datachange}
            cancel={() => {
              setbackfor(0);
              setProfileView(false);
              setdatachange(false);
              onRefresh();
            }}
            jobId={JSON.stringify(isfilterjd)}
            column_detail={column_detail}
            candidateId={isfiltercan_id}
            setdatachange={setdatachange}
            togglebutton={togglebutton}
            activeState={istabsetting}
            applicantpipeline={datachange}
            datakey={datakeysetting}
            buttonchange={buttonchange}
            isendofbutton={isendofbutton}
          />
        </>
      ) : (
        <>
          {isProfileView && (
            <ProfileView
              open={isProfileView}
              cancel={() => {
                setbackfor(0);
                setProfileView(false);
                onRefresh();
              }}
              jobId={JSON.stringify(task.jd_id_id)}
              column_detail={column_detail}
              candidateId={task.candidate_id_id}
              setdatachange={setdatachange}
              togglebutton={togglebutton}
              activeState={istabsetting}
              applicantpipeline={isprofilefromapplicant}
              datakey={datakeysetting}
              buttonchange={buttonchange}
              isendofbutton={isendofbutton}
            />
          )}
        </>
      )}
      <Draggable
        draggableId={task.id.toString() + dndBoardId}
        index={index}
        isDragDisabled={!active_resume.includes(task.candidate_id_id)}
      >
        {(provided) => (
          <div
            className={styles.container}
            style={{ cursor: 'pointer', minWidth: KANBAN_COLUMN_WIDTH - 20 }}
            ref={provided.innerRef}
            // eslint-disable-next-line
            {...provided.dragHandleProps}
            // eslint-disable-next-line
            {...provided.draggableProps}
            // isDragging={snapshot.isDragging}
          >
            {checkfunction(task.candidate_id_id)}
          </div>
        )}
      </Draggable>
    </>
  );
};

export default MultiTask;
