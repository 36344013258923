// import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames/bind';
import { useFormik } from 'formik';
import DatePicker from "react-multi-date-picker"
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import SvgNoevent from '../../../icons/SvgNoevent';
import SvgCalendar from '../../../icons/SvgCalendar';
// import SvgNewTab from '../../../icons/SvgNewTab';
import SvgRefresh from '../../../icons/SvgRefresh';
import { AppDispatch, RootState } from '../../../store';
import Button from '../../../uikit/Button/Button';
import Card from '../../../uikit/Card/Card';
import Loader from '../../../uikit/Loader/Loader';

import { BLACK, PRIMARY, WHITE } from '../../../uikit/Colors/colors';
import Flex from '../../../uikit/Flex/Flex';
import { CALENDAR } from '../../calendarModule/types';
import { getUrlMiddleware } from '../../calendarModule/store/middleware/calendarmiddleware';
import {
  getDateString,
  // isEmpty
} from '../../../uikit/helper';
import LinkWrapper from '../../../uikit/Link/LinkWrapper';
import Text from '../../../uikit/Text/Text';
// import {
//   calenderTokenGetMiddleWare,
//   calenderTokenMiddleWare,
// } from '../../accountsettingsmodule/integrationmodule/store/middleware/integrationmiddleware';
import {
  syncOutlookMiddleWare,
  // syncGoogleMiddleWare,
  checkAuthMiddleware,
  getGoogleEventsMiddleware,
} from '../../applicantprofilemodule/store/middleware/applicantProfileMiddleware';
// import { clientSecret, googleClientId } from '../../accountsettingsmodule/integrationmodule/IntegrationScreen';
import styles from './calendercard.module.css';
import { EventsEntity } from './DashBoardTypes';
import { dashboardCalenderMiddleWare } from './store/dashboardmiddleware';
import { outlookTimeZone } from './mock';

const email = getGoogleEventsMiddleware;

const cx = classNames.bind(styles);

type Props = {
  events: EventsEntity[];
  checkCalendar: boolean;
  checkCalendarOutlook: boolean;
  outlook: any;
  google: any;
};

const CalenderCard = ({
  events,
  checkCalendar,
  checkCalendarOutlook,
  outlook,
  google,
}: Props) => {
  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();
  const [isCalLoad, setCalLoad] = useState(false);
  const [show, setshow] = useState(false);
  const [loader, setloader] = useState(false);
  const [calendarProvider, setCalendarProvider] = useState<any>(null);

  const { permission, super_user, roles } = useSelector(
    ({
      permissionReducers,
      userProfileReducers,
      dashboardEmpReducers,
    }: RootState) => {
      return {
        permission: permissionReducers.Permission,
        super_user: permissionReducers.super_user,
        roles: permissionReducers.roles,
      };
    },
  );

  const handleIntegrateTab = () => {
    sessionStorage.setItem('CommunicationDropdown', '1');
    window.location.href = '/account_setting/integration';
  };

  const formik = useFormik({
    initialValues: { date: getDateString(new Date(), 'MM/DD/YYYY') },
    onSubmit: () => { },
  });
  const getOutLookTime: any = 'Asia/Kolkata';
  // outlook && outlook[0].timeZone;

  useEffect(() => {
    if (checkCalendar) {
      localStorage.setItem(
        'timeZone',
        checkCalendarOutlook ? getOutLookTime : google[0].timeZone,
      );
    }
  }, [outlook, google, checkCalendarOutlook, checkCalendar]);

  const [isGoogle, setIsGoogle] = useState(0);
  const [active, setActive] = useState(0);
  const [event, setEvent] = useState([
    {
      title: '',
      start: '',
      end: '',
      web_url: '',
      event_id: '',
      slotter: false,
    },
  ]);
  const [tz, setTz] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone,
  );

  const getEventHandler = (account: string) => {
    setCalendarProvider(account);
    if (account === 'google') {
      if (google !== null) {
        setloader(true);
      }
      dispatch(
        dashboardCalenderMiddleWare({
          date: getDateString(formik.values.date, 'YYYY-MM-DD'),
        }),
      ).then((res) => {
        setloader(false);
        const data = res.payload.events;

        const filteredData = data.filter(
          (item) =>
            getDateString(new Date(item.start.dateTime), 'MM/DD/YYYY') ===
            formik.values.date,
        );
        if (data !== undefined) {
          setEvent(
            filteredData.map(
              (items: {
                summary: any;
                start: { dateTime: any };
                end: { dateTime: any };
                hangoutLink: any;
                slotter?: boolean;
              }) => {
                return {
                  title: items.summary,
                  start: new Date(items.start.dateTime),
                  end: new Date(items.end.dateTime),
                  web_url: items.hangoutLink,
                  slotter: items.slotter,
                };
              },
            ),
          );
        }
      });
    } else {
      if (outlook !== null) {
        setloader(true);
      }
      dispatch(
        dashboardCalenderMiddleWare({
          date: getDateString(formik.values.date, 'YYYY-MM-DD'),
        }),
      ).then((res) => {
        setloader(false);
        const dataout = res.payload.events;
        if (dataout !== undefined) {
          const filteredData = res.payload.events.filter(
            (item) =>
              getDateString(item.start_time, 'MM/DD/YYYY') ===
              formik.values.date,
          );
          setEvent(
            filteredData.map(
              (items: {
                title: any;
                start_time: string | number | Date;
                end_time: string | number | Date;
                web_url: any;
                id?: any;
                slotter?: boolean;
              }) => {
                return {
                  title: items.title,
                  start: new Date(items.start_time),
                  end: new Date(items.end_time),
                  web_url: items.web_url,
                  event_id: items.id,
                  slotter: items.slotter,
                };
              },
            ),
          );
        }
      });
    }
  };
  const checkAuth = () => {
    // console.log('Chikking the Sync ----');

    dispatch(checkAuthMiddleware()).then((res) => {
      if (res.payload.status === true) {
        if (res.payload.account === 'google') {
          setIsGoogle(1);
        } else {
          setIsGoogle(0);
        }
        setActive(1);
        getEventHandler(res.payload.account);
      } else {
        setActive(0);
      }
    });
  };
  useEffect(() => {
    checkAuth();
  }, []);
  const getOut: any = localStorage.getItem('timeZone');

  const getEventHasMeeting = (title: string, index) => {
    let eventType = new RegExp(/[a-zA-Z]+ [a-zA-Z]+ /).exec(title);
    if (eventType) {
      if (eventType[0].includes('Google Meet')) {
        return true;
      } else if (eventType[0].includes('Microsoft Teams')) {
        return true;
      } else if (index.slotter === true) {
        return true;
      } else if (
        !title.includes('On-site Interview') &&
        !title.includes('Phone Interview') &&
        !title.includes('Onsite Interview')
      ) {
        return true;
      }
    }
    return false;
  };

  //  join the meeting while clicking the jion button.
  const handleJoinMeeting = (eventId: string, event_id?: any) => {
    if (calendarProvider.toUpperCase() === CALENDAR.Google) {
      window.open(eventId);
    } else if (calendarProvider.toUpperCase() === CALENDAR.Outlook) {
      dispatch(getUrlMiddleware({ event_id: event_id })).then((res) => {
        if (res.payload.data.onlineMeeting.joinUrl) {
          window.open(res.payload.data.onlineMeeting.joinUrl);
        } else if (res.payload.data.onlineMeeting.joinURL) {
          window.open(res.payload.data.onlineMeeting.joinURL);
        }
      });
    }
  };
  return (
    <Card className={styles.overAll}>
      <Flex row between className={styles.msgText}>
        <Flex row center>
          <Flex>
            <Text bold size={14} style={{ marginRight: 5, marginTop: '4px' }}>
              Calendar
            </Text>
          </Flex>
          {active === 1 && (
            // <Flex >
            <Text color="gray" size={12}>
              {outlookTimeZone[getOut]}
              {checkCalendarOutlook
                ? outlookTimeZone[getOut]
                : localStorage.getItem('timeZone')}
            </Text>
            // </Flex>
          )}
        </Flex>
        {active === 1 && (
          // <Flex row>
          <Flex row center>
            <Flex>
              <Flex>
                <Button
                  types="primary"
                  className={styles.syncBtn}
                  onClick={checkAuth}
                >
                  <Flex row center>
                    <Text
                      bold
                      size={13}
                      style={{ cursor: 'pointer', color: 'white' }}
                    >
                      Sync
                    </Text>
                    <Flex marginLeft={8}>
                      <SvgRefresh height={14} width={14} fill={WHITE} />
                    </Flex>
                  </Flex>
                </Button>
                {isCalLoad && (
                  <div style={{ marginLeft: 16 }}>
                    <Loader withOutOverlay size={'medium'} />
                  </div>
                )}
              </Flex>
            </Flex>
            <Flex marginLeft={10} width={'80%'}>
              <Flex row style={{ position: 'relative', display: 'flex' }}>
                <DatePicker
                  id="calendar___open"
                  format="DD/MM/YYYY"
                  value={getDateString(formik.values.date, 'DD/MM/YYYY')}
                  onChange={(date) => {
                    console.log(date.year, date.month.number, date.day, '0))))))))))))))))')
                    formik.setFieldValue(
                      'date',
                      date.format('MM/DD/YYYY'),
                    );
                    dispatch(
                      dashboardCalenderMiddleWare({
                        date: date.format("YYYY-MM-DD"),
                      }),
                    ).then((res) => {
                      const dataout = res.payload.events;
                      setEvent(
                        res.payload.events.map(
                          (items: {
                            title: any;
                            start_time: string | number | Date;
                            end_time: string | number | Date;
                            web_url: any;
                            id?: any;
                            slotter?: boolean;
                          }) => {
                            return {
                              title: items.title,
                              start: new Date(items.start_time),
                              end: new Date(items.end_time),
                              web_url: items.web_url,
                              event_id: items.id,
                              slotter: items.slotter,
                            };
                          },
                        ),
                      );
                    });
                  }}
                  className={styles.datePicker}
                />
                <div style={{ position: 'absolute', right: 8, top: 4 }} >
                  <label htmlFor="calendar___open" className={styles.cursor}
                  >
                    <SvgCalendar width={16} height={16} />
                  </label>
                </div>
              </Flex>
            </Flex>
          </Flex>
        )}
      </Flex>

      <Flex
        columnFlex
        className={
          active === 0 || event.length === 0
            ? styles.scrollStyleOne
            : styles.scrollStyleTwo
        }
      >
        {active === 0 ? (
          <>
            {loader === false ? (
              <>
                <Flex
                  center
                  flex={1}
                  middle
                  columnFlex
                  className={styles.noContent}
                >
                  <Text
                    size={13}
                    color="placeholder"
                    style={{ marginBottom: 10 }}
                  >
                    Integrate your calendar with zita to schedule your meetings
                  </Text>
                  <Flex
                    onClick={() => {
                      handleIntegrateTab();
                    }}
                  // to={'/account_setting/integration'}
                  >
                    <Button>Integrate</Button>
                  </Flex>
                </Flex>
              </>
            ) : (
              ''
            )}
          </>
        ) : event.length >= 1 ? (
          event.map((list, index) => {
            if (
              getDateString(list.start, 'MM/DD/YYYY') === formik.values.date
            ) {
              const startTime = moment(list.start);
              const endTime = moment(list.end);
              const duration = moment.duration(endTime.diff(startTime));
              const durationInMinutes = Math.floor(duration.asMinutes());
              const hours = Math.floor(durationInMinutes / 60);
              const minutes = durationInMinutes % 60;
              return (
                <Card key={list.title + index} className={styles.cardListStyle}>
                  <Flex row between center>
                    <Flex
                      flex={4}
                      className={styles.borderRight}
                      marginLeft={10}
                      marginRight={10}
                    >
                      <Text color="black2" bold size={13}>
                        {moment(list.start).format('dddd')}
                      </Text>
                      <Flex row>
                        <Text>{getDateString(list.start, 'hh:mm A')}</Text>
                        <Text style={{ marginLeft: '2px', marginRight: '2px' }}>
                          -
                        </Text>
                        <Text>{getDateString(list.end, 'hh:mm A')}</Text>
                      </Flex>
                      <Text color="black2" size={13}>
                        {hours !== 0 && minutes !== 0 ? (
                          <Text
                            size={12}
                          >{`(${hours}hour ${minutes}minutes)`}</Text>
                        ) : minutes === 0 ? (
                          <Text>{`(${hours}hour)`}</Text>
                        ) : (
                          <Text>{`(${minutes}minutes)`}</Text>
                        )}
                      </Text>
                    </Flex>
                    <Flex flex={8} row center>
                      <Flex flex={10} title={list.title}>
                        <Text
                          color="theme"
                          className={styles.title}
                          style={{
                            padding: '2px',
                          }}
                        >
                          {list.title}
                        </Text>
                      </Flex>
                      {getEventHasMeeting(list.title, list) && (
                        <Flex
                          flex={2}
                          center
                          end
                          style={{
                            paddingRight: '5px',
                          }}
                        >
                          <Button
                            onClick={() =>
                              handleJoinMeeting(list.web_url, list.event_id)
                            }
                          >
                            Join
                          </Button>
                        </Flex>
                      )}
                    </Flex>
                  </Flex>
                </Card>
              );
            }
          })
        ) : (
          <Flex flex={1} center middle columnFlex className={styles.noContent}>
            <SvgCalendar
              width={16}
              height={16}
              fill={'#888888'}
              stroke={'#888888'}
            />
            <Text color="placeholder" style={{ marginTop: '2px' }}>
              {' '}
              No event scheduled
            </Text>
          </Flex>
        )}
      </Flex>
    </Card>
  );
};

export default CalenderCard;
