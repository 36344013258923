import { useFormik } from 'formik';
import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import OverlayTrigger from 'react-bootstrap/esm/OverlayTrigger';
import { Popover } from 'react-bootstrap';
import Lottie from 'react-lottie';
import {
    Flex,
    Text,
    Button,
    CheckBox,
    InputCheckBox,
    InputRadio,
    InputText,
    ErrorMessage,
    Loader,
    Toast,
    Card,
    Modal,
} from '../../uikit';
import { AppDispatch } from '../../store';
import Tab from '../../uikit/Tab/Tab';
import Tabs from '../../uikit/Tab/Tabs';
import SvgRadioWithLine from '../../icons/SvgRadioWithLine';
import SvgModuleicon from '../../icons/SvgModuleicon';
import { Difficultylevel, Typeofinterviewquestion, level } from '../myjobposting/mock';
import SvgAddquestion from '../../icons/addquestion';
import { PRIMARY } from '../../uikit/Colors/colors';
import MyLottieComponent from '../../loader/Loader';
import robort from '../../actions/QuestionGeneration.json'
import styles from './styles/createScheduleForm.module.css';
import { Interview_question_middleware } from './store/middleware/calendarmiddleware';
import { LevelValue } from './Questiontype';


interface Props {
    interviewer: any;
    setinterviewer: any;
    formikval: any;
    isQuestionChecked: any;
    handlecheck: any;
    handlechange1: any;
    handlefunction1: any;
    handlechange: any;
    meetingForm: any;
    setShowPopup: any;
    setViewMeetingSummary: any;
    sample: any;
    update_state: any;
    setnewquestion: any;
    newquestion1: any;
    setallids: any;
    setaddquestion: any;
    formik: any;
    setquestionerror: any;
    questionerror: any;
    errorstate: any;
    setvalidateerror: any;
    validateerror: any;
    seterrorstate: any;
    field: any;
    setfield: any;
    editid: any;
    tick: any;
    isblockquestion?: any;
    setblockquestion?: any;
    setUnchecked?: any;
    setsample?: any;
};

export const QuestionListModel = ({
    interviewer,
    setinterviewer,
    formikval,
    isQuestionChecked,
    handlecheck,
    handlechange1,
    handlefunction1,
    handlechange,
    meetingForm,
    setShowPopup,
    setViewMeetingSummary,
    sample,
    update_state,
    setnewquestion,
    newquestion1,
    setallids,
    setaddquestion,
    formik,
    setquestionerror,
    questionerror,
    errorstate,
    setvalidateerror,
    validateerror,
    seterrorstate,
    field,
    setfield,
    editid,
    tick,
    isblockquestion,
    setblockquestion,
    setUnchecked,
    setsample
}: Props) => {
    const [isupdateresponse, setupdateresponse] = useState<any>(false);
    const [showstate, setshowstate] = useState(false)
    const [questions, setquestions] = useState([])
    const [openmodel, setopenmodel] = useState(false)
    const [openpopup, setopenpopup] = useState(false);
    // const [isSubmitLoader, setSubmitLoader] = useState(false);
    const [error, seterror] = useState(false)
    const [currentLetter, setcurrentLetter] = useState('A');
    const dispatch: AppDispatch = useDispatch();
    const [showerror, setshowerror] = useState(false)
    const params = new URLSearchParams(window.location.search);
    //useEffects for  set formik question value
    useEffect(() => {
        setallids(formik.values.question)
    }, [formik.values])
    useEffect(() => {
        if (formikval.values.checkedValues && formik.values.levellist) {
            const showlist = formikval.values.checkedValues.map(checkedItem => {
                const levelItem = formik.values.levellist.find(levItem => levItem.id === checkedItem.id);
                const isLevelActive = levelItem && levelItem.level.some(levelDetail => levelDetail.checked);
                return {
                    id: checkedItem.id,
                    active: isLevelActive,
                };
            });

            formik.setFieldValue('Errorshow', showlist);
        }
    }, [formikval.values.checkedValues, formik.values.levellist]);

    useEffect(() => {
        setinterviewer(0)
    }, [])

    // level selecting checkbox
    const handleCheckboxChange = (index: number, event: React.ChangeEvent<HTMLInputElement>, ids: number) => {
        const isChecked = event.target.checked;
        const updatedValues = JSON.parse(JSON.stringify(formik.values.levellist));
        let listItem = updatedValues.find(item => item.id === ids);
        if (!listItem) {
            return;
        }
        let levelItemIndex = listItem.level.findIndex(lvl => lvl.name === level[index].value);
        if (levelItemIndex !== -1) {
            if (!isChecked) {
                listItem.level.splice(levelItemIndex, 1);
            }
        } else if (isChecked) {
            listItem.level.push({
                name: level[index].value,
                easy: 0,
                iseasycheck: false,
                medium: 0,
                ismediumcheck: false,
                hard: 0,
                ishardcheck: false,
                checked: isChecked
            });
        }
        formik.setFieldValue('levellist', updatedValues);
    };
    const isCheckboxChecked = (userId: number, jobName: string): boolean => {
        const userItem = formik.values.levellist.find(item => item.id === userId);
        return userItem?.level.some(lvl => lvl.name === jobName && lvl.checked) || false;
    };
    // Error validartion for question count

    const validateFunctionCall = () => {
        let val = false;

        formik.values?.levellist[interviewer]?.level?.forEach(data => {
            // if (data?.iseasycheck === true || data?.ishardcheck === true || data?.ismediumcheck === true) {
            //     val = true;
            // }

            if (data?.easy !== '0' || data?.hard !== '0' || data?.medium !== '0') {
                val = true;
            }
        });

        return val;
    };
    // dispatch for generating question
    const generatequestion = (listIndex, id) => {
        formik.setFieldValue(`showstate[${interviewer}].showstate`, true)
        formik.setFieldValue(`errorfield[${interviewer}].errorfield`, true)
        if (formik.values?.levellist[interviewer]?.level.length !== 0) {
            const answer = validateFunctionCall()
            const isInterviewerPresent = formik.errors?.levellist?.some((item, i) => i === listIndex);
            if (answer) {
                setupdateresponse(true);
                if (!isInterviewerPresent || formik.errors?.levellist === undefined) {
                    const errorItem = formik.errors.levellist?.[listIndex];
                    if (typeof errorItem !== 'string' && errorItem?.totalError) return null;
                    formik.setFieldValue(`loader[${interviewer}].loader`, true)

                    // setSubmitLoader(true);
                    const transformLevelListData = (levellist1, targetId) => {
                        return levellist1
                            .filter(item => item.id === targetId)
                            .map(item => {
                                const questionArray = item.level.flatMap(levelItem => {
                                    if (!levelItem.checked) return [];

                                    const mappings = [
                                        { key: 'iseasycheck', countKey: 'easy', level: 'Easy' },
                                        { key: 'ismediumcheck', countKey: 'medium', level: 'Medium' },
                                        { key: 'ishardcheck', countKey: 'hard', level: 'Hard' },
                                    ];

                                    return mappings.reduce((acc, map) => {
                                        //interview question with checkbox
                                        // if (levelItem[map.key] && levelItem[map.countKey]) {
                                        if (levelItem[map.countKey] !== '0') {
                                            acc.push({
                                                level: map.level,
                                                type: levelItem.name,
                                                count: levelItem[map.countKey]
                                            });
                                        }
                                        return acc;
                                    }, []);
                                });
                                return {
                                    id: item.id,
                                    role: item.role,
                                    question: questionArray
                                };
                            });
                    };
                    const combinedData = transformLevelListData(formik.values.levellist, id);
                    const formData = new FormData();
                    const can_id = params.get('can_id_meeting')
                    const jd_id = params.get('jd_id_meeting')
                    formData.append('role', JSON.stringify(combinedData));
                    formData.append('summary', formikval.values.brieftext);
                    formData.append('can_id', can_id ? can_id : meetingForm.applicant.id);
                    formData.append('jd_id', meetingForm.job.value === 0 ? (jd_id ? jd_id : editid) : (jd_id ? jd_id : meetingForm.job.value));

                    dispatch(Interview_question_middleware({ formData }))
                        .then((response) => {
                            if (response?.payload?.success === true) {
                                setupdateresponse(false)
                                formik.setFieldValue(`loader[${interviewer}].loader`, false)
                                // setSubmitLoader(false);
                                setshowstate(true);
                                update_state(response.payload.data);
                                const addQuestion = response.payload.data;
                                if (!Array.isArray(addQuestion)) {
                                    setquestions(prevQuestions => [...prevQuestions, addQuestion]);
                                } else {
                                    setquestions(prevQuestions => [...prevQuestions, ...addQuestion]);
                                }
                                setViewMeetingSummary(false);
                                setShowPopup(true);
                                setquestionerror(false)
                            } else {
                                formik.setFieldValue(`loader[${interviewer}].loader`, false)
                                // setSubmitLoader(false);
                                Toast('Oops! Something went wrong. Please try again shortly.', 'LONG', 'error');
                            }
                        });
                }
                return null;
            }
        };

    }
    // validating the question while appending
    const validatequestion = () => {
        if (formik.values.addquestion[0] !== undefined && formik.values.addquestion[0]?.type !== '' && formik.values.addquestion[0]?.level !== '' && formik.values.addquestion[0]?.question !== '') {
            const [{ addquestion }] = [formik.values];
            const [newQuestion] = addquestion;
            const index = sample.findIndex(({ id }) => id === newQuestion.attendees);
            if (index === -1) {
                setopenmodel(false);
                return;
            }
            const [questionItem] = sample.splice(index, 1);
            let category = questionItem.question.Question.find(({ Category }) => Category === newQuestion.type);
            if (!category) {
                category = {
                    Category: newQuestion.type,
                    Value: [{ Map_question: [newQuestion] }]
                };
                questionItem.question.Question.push(category);
            } else {
                let levelMap = category.Value.find(val => val.Map_question.some(({ level: mapLevel }) => mapLevel === newQuestion.level));
                if (!levelMap) {
                    levelMap = { Map_question: [newQuestion] };
                    category.Value.push(levelMap);
                } else {
                    levelMap.Map_question.push(newQuestion);
                }
            }
            sample.splice(index, 0, questionItem);
            setcurrentLetter(newQuestion.id);
            setopenmodel(false);
            seterror(false)

        }
        else {
            seterror(true)
        }
    };
    // id generation for question
    function getNextLetter(letter) {
        if (letter.length === 1) {
            if (letter === 'Z') {
                return 'AA';
            } else {
                return String.fromCharCode(letter.charCodeAt(0) + 1);
            }
        } else {
            const lastChar = letter.slice(-1);
            const remaining = letter.slice(0, -1);
            if (lastChar === 'Z') {
                return getNextLetter(remaining) + 'A';
            } else {
                return remaining + String.fromCharCode(lastChar.charCodeAt(0) + 1);
            }
        }
    }
    const functioncall = () => {
        setopenmodel(true)
        formik.setFieldValue('addquestion[0].type', '')
        formik.setFieldValue('addquestion[0].level', '')
        formik.setFieldValue('addquestion[0].question', '')
    }
    const nextLetter = getNextLetter(currentLetter);
    //changing level radio thumb based on value
    const handlelevelradio = (val) => {
        const value = val.toLowerCase();
        if (value === 'easy') {
            return <SvgRadioWithLine fill="#34CC65" width={16} height={16} />;
        }
        if (value === 'medium') {
            return <SvgRadioWithLine fill="#F29111" width={16} height={16} />;
        }
        if (value === 'hard') {
            return <SvgRadioWithLine fill="#ED4857" width={16} height={16} />;
        }
        return null;
    };
    const filteredIds = sample?.filter(item => item.success === false)
        .map(item => item.id);
    const renderTextComponents = () => {
        let textCount = 0;
        // Check if there's any item with success === false 
        const hasError = sample?.some(value => value.success === false);

        return (
            <Flex row style={{ textAlign: 'justify', padding: '0px 25px 25px 25px' }}>
                {hasError && (
                    <Text color='error'>
                        Please generate questions for the interviewer{
                            sample?.map((value, i) => {
                                if (value.success === false) {
                                    textCount++;
                                }
                                const isLastItem = filteredIds.length === textCount;
                                const isSecondToLastItem = filteredIds.length - 1 === textCount;
                                const separator = isLastItem ? '.' : (isSecondToLastItem ? ' and ' : ', ');
                                return (

                                    <Text color='error' key={i}>
                                        {value.success === false && ` ${formik.values.levellist[i]?.firstname} ${formik.values.levellist[i]?.lastname}${separator} `}
                                    </Text>
                                );
                            })
                        }
                    </Text>
                )}

            </Flex>
        );
    };


    const validateError = (listIndex) => {
        const errorItem = formik.errors.levellist?.[listIndex];
        if (errorItem && typeof errorItem !== 'string') {
            const errorForThisItem = errorItem.totalError || errorItem.showError;
            return errorForThisItem ? <Text color='error'>{errorForThisItem}</Text> : null;
        }
        return null;
    }

    const emherror = () => {
        let isChecked = false;
        const levels = formik.values?.levellist[interviewer]?.level || [];

        // levels.forEach((data) => {
        //     if (data?.iseasycheck || data?.ishardcheck || data?.ismediumcheck) {
        //         isChecked = true;
        //     }
        // });
        levels.forEach((data) => {
            if (data?.easy !== '0' || data?.hard !== '0' || data?.medium !== '0') {
                isChecked = true;
            }
        });

        if (isChecked) {
            if (formik.values?.Errorshow[interviewer]?.active) {
                return validateError(interviewer);
            }
        } else {
            setfielderror();
        }
    };


    const setfielderror = () => {
        const fieldPath = `required[${interviewer}].required`;
        const currentValue = formik.values.required[interviewer]?.required;

        if (!currentValue) {
            formik.setFieldValue(fieldPath, true);
        }
    };


    const renderErrorComponents = () => {
        const errorNames = formik.values?.question
            .map((obj, indexid) => (obj.question.length === 0 ? formik.values.levellist[indexid]?.firstname + ' ' + formik.values.levellist[indexid]?.lastname : null))
            .filter(name => name !== null);
        if (errorNames.length > 0) {
            const joinedNames = errorNames.join(', ');
            // Find the index of the last comma in the joined string
            const lastCommaIndex = joinedNames.lastIndexOf(',');
            // If there is a comma, replace it with "and"

            return (
                <Flex>
                    <Text style={{ padding: ' 0 25px' }} color='error'>Please select questions to be included for this interviewer.</Text>
                </Flex>
            );
        } else {
            return null;
        }
    };
    const handlemodel = () => {
        setopenmodel(false)
        seterror(false)
    }
    const filterObj = (datas) => {
        const filteredData = datas.map(item => {
            const filteredA = [];
            const targetType = "string";
            item.question?.Question?.forEach(question => {
                question.Value.forEach(values1 => {
                    values1.Map_question.forEach(mapQuestion => {
                        if (typeof mapQuestion.id === targetType) {
                            filteredA.push(mapQuestion);
                        }
                    });
                });
            });
            return filteredA;
        }).flat();

        return filteredData;
    }
    const [continues, setcontinues] = useState([]);

    const submit = () => {
        const samples = sample[interviewer]
        const ind = formik.values.question?.findIndex(res => res?.id === samples?.id)
        formik.setFieldValue(`showstate[${ind}].showstate`, true);

        if (sample[interviewer]?.success === true) {
            if (!formik.values.question[ind] || formik.values.question[ind].question.length === 0) {
                // Handle the case where the question is empty
                setquestionerror(true);
                return; // Exit the function if the condition is not met
            }
        }

        const questionErrors = [];
        formik.values.question?.forEach((item, index) => {
            if (item.question.length === 0) {
                questionErrors.push({ index: index, error: 'This question must not be empty.' });
            }
        });

        const emptyIndexes = findEmptyQuestionIndexes(questionErrors);
        setcontinues(emptyIndexes);

        const a = list_of_interviewer();
        if (a.length !== 0 || emptyIndexes.length !== 0) {
            setquestionerror(false);

            if (a.length !== 0) {
                tabupdate(a[0]);
            } else if (emptyIndexes.length !== 0 && sample[emptyIndexes[0]]?.success === true) {
                tabupdate(emptyIndexes[0]);
            }

        } else {
            seterrorstate(true);
            const filteredData = filterObj(sample);

            const result = formik.values.question.every(obj => obj.question.length !== 0);
            if (result) {
                handlechange();
                setaddquestion(filteredData);
            } else {
                setquestionerror(true);
            }
        }
    };

    const findEmptyQuestionIndexes = (questionErrors) => {
        return questionErrors.map(errorObject => errorObject.index);
    };


    const validshow = sample?.map((obj, indexid) => {
        if (obj.success === true) {
            const firstname = formik.values.levellist[indexid]?.firstname;
            const lastname = formik.values.levellist[indexid]?.lastname;
            return firstname && lastname ? firstname + ' ' + lastname : null;
        }
        return null;
    }).some(element => element === null);
    const tabupdate = (val) => {
        setinterviewer(val);
        sessionStorage.setItem('interviewer', val);

    }

    const list_of_interviewer = () => {
        const samples = sample.filter(value => value.success === true)
        const successIndices = sample?.map((item, index) => item.success ? null : index).filter(index => index !== null);
        return successIndices;
    };

    const counterror = () => {
        const levels = formik.values?.levellist[interviewer]?.level || [];
        return levels.some(data => data?.easy !== '0' || data?.hard !== '0' || data?.medium !== '0');
    };
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: robort,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    const [scrollNeeded, setScrollNeeded] = useState(false);
    const containerRef = useRef(null);
    useEffect(() => {
        const checkOverflow = () => {
            const { current } = containerRef; // Destructuring to get 'current'
            if (current) {
                const isOverflowing = current?.scrollWidth > current?.clientWidth;
                setScrollNeeded(isOverflowing);
            }
        };

        checkOverflow();
        window.addEventListener('resize', checkOverflow);

        return () => window.removeEventListener('resize', checkOverflow);
    }, []);

    const samples = sample[interviewer]
    const questionindex = formik.values.question?.findIndex(res => res?.id === samples?.id)

    // updating the tap so setting the data to saple state and formik question based on checked values
    useEffect(() => {
        if (formik.values.question === undefined) {
            let newarrayone = []
            formikval.values.checkedValues?.map(y => {
                newarrayone.push({
                    id: y?.id,
                    question: [],
                    success: false,
                })
            });
            formik.setFieldValue('question', newarrayone)
        }
        else {
            const currentQstions = formikval.values.checkedValues.map(data => data.id);
            const fil = formik.values.question?.filter(drs => currentQstions.includes(drs.id));
            const currentQuestions = fil.map(data => data.id);
            const filter = formikval.values.checkedValues?.filter(drs => !currentQuestions.includes(drs.id));
            if (filter.length !== 0) {
                const dataUpdate = filter?.map(item => ({
                    id: item.id,
                    question: [],
                    success: false,
                }));

                const updatedDataWithEmptyQuestions = [...fil, ...dataUpdate];
                for (let i = 0; i < sample.length; i++) {
                    const currentData = sample[i];

                    // Check if the question array is empty
                    if (currentData.question.length === 0) {
                        // Find the index of the item with the same id
                        const indexToUpdate = updatedDataWithEmptyQuestions.findIndex(item => item.id === currentData.id);

                        // Update the question array at the found index
                        if (indexToUpdate !== -1) {
                            updatedDataWithEmptyQuestions[indexToUpdate].question = []
                        }
                    }
                }
                formik.setFieldValue('question', updatedDataWithEmptyQuestions);
            }
            else {
                for (let i = 0; i < sample.length; i++) {
                    const currentData = sample[i];

                    // Check if the question array is empty
                    if (currentData?.question.length === 0) {
                        // Find the index of the item with the same id
                        const indexToUpdate = fil?.findIndex(item => item?.id === currentData?.id);

                        // Update the question array at the found index
                        if (indexToUpdate !== -1) {
                            fil[indexToUpdate].question = []
                        }
                    }
                }
                formik.setFieldValue('question', fil);
            }
            const currentQuest = formikval.values.checkedValues?.map(data => data?.id);
            const sampledata = sample?.filter(e => currentQuest.includes(e.id))
            setsample(sampledata)
        }

    }, [])


    return (
        <>
            {/* Add  Question modal popup */}
            <Modal open={openmodel} >
                <Flex style={{ backgroundColor: '#FFF', width: '600px', height: 'auto', padding: '25px', borderRadius: '4px' }}>
                    <Flex style={{ borderBottom: '1px solid #581845', paddingBottom: '5px' }}>
                        <Text size={14} bold>Add Question</Text>
                    </Flex>
                    <Flex>
                        <Flex marginTop={9}>
                            <Text size={13} color='theme'>Choose the type of interview questions.<span >*</span></Text>
                        </Flex>
                        <Flex row>
                            {Typeofinterviewquestion.map((data, index) => {
                                return (<Flex key={index} row marginRight={15} marginTop={7} center>
                                    <Flex>
                                        <InputRadio
                                            checked={formik.values?.addquestion[0]?.type === data.value}
                                            onClick={() => {
                                                formik?.setFieldValue('addquestion[0].type', data.value)
                                                formik?.setFieldValue('addquestion[0].id', nextLetter)
                                                formik?.setFieldValue('addquestion[0].attendees', sample[interviewer].id)
                                            }
                                            }
                                        />
                                    </Flex>
                                    <Flex>
                                        {data.label}
                                    </Flex>
                                </Flex>)
                            })}
                        </Flex>
                        {error && !(formik?.values?.addquestion[0]?.type !== '') && <Flex marginTop={5}><Text color='error'>This field is required.</Text></Flex>}
                        <Flex marginTop={9}>
                            <Text size={13} color='theme'>Choose the difficulty level of the question and question count.<span >*</span></Text>
                        </Flex>
                        <Flex row>
                            {Difficultylevel.map((data, index) => {
                                return (<Flex key={index} row marginRight={15} marginTop={7} center>
                                    <Flex>
                                        <InputRadio
                                            checked={formik.values?.addquestion[0]?.level === data.value}
                                            onClick={() => formik.setFieldValue('addquestion[0].level', data.value)}
                                        />
                                    </Flex>
                                    <Flex>
                                        {data.label}
                                    </Flex>
                                </Flex>)
                            })}
                        </Flex>
                        {error && !(formik?.values.addquestion[0]?.level !== '') && <Flex marginTop={5}><Text color='error'> This field is required.</Text></Flex>}
                        <Flex marginTop={10}>
                            <InputText
                                className={styles.addinput}
                                placeholder='Type your interview question here'
                                onChange={(e) => formik.setFieldValue('addquestion[0].question', e.target?.value?.trim())}
                                required
                            />
                        </Flex>
                        {error && !(formik.values.addquestion[0]?.question !== '') && <Flex marginTop={5}><Text color='error'> This field is required.</Text></Flex>}
                        <Flex row marginTop={17} end>
                            <Flex marginRight={20} onClick={() => handlemodel()}>
                                <Button types="close" width="75px">Cancel</Button>
                            </Flex>
                            <Flex>
                                <Button types='primary' width="75px" onClick={validatequestion} >Add</Button>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </Modal>

            {/* Both generate and selection of levels modal popup*/}
            <Flex className={styles.scrollfornav} style={{ backgroundColor: '#FFF', width: '700px', }}>
                <Flex center row style={{ paddingBottom: '5px', padding: '25px 25px  0px 25px' }}>
                    <Flex row center>
                        <Flex >
                            <Text size={14} bold >Generate Interview Questions by AI</Text>
                        </Flex>
                        <Flex marginLeft={5} >
                            <OverlayTrigger
                                trigger="hover"
                                placement="bottom"
                                show={openpopup}
                                overlay={
                                    <Popover id="popover" >
                                        <Popover.Content className={styles.popup}>
                                            <Text size={13}>
                                                Customize and generate interview questions based on type, difficulty
                                                and number of the questions, with an emphasis on ease of setup and
                                                personalization for each interviewer.
                                            </Text>
                                        </Popover.Content>
                                    </Popover>
                                }
                            >
                                <div
                                    onMouseEnter={() => setopenpopup(true)}
                                    onMouseLeave={() => setopenpopup(false)}
                                >
                                    <SvgModuleicon />
                                </div>
                            </OverlayTrigger>
                        </Flex>
                    </Flex>
                </Flex>
                <Flex style={{ display: 'flex', flexWrap: 'nowrap', overflowX: scrollNeeded ? 'scroll' : 'hidden' }} ref={containerRef}>
                    <Tabs
                        activeKey={interviewer}
                        onSelect={!isupdateresponse ? (keys) => tabupdate(keys) : undefined}
                    >
                        {formikval?.values?.checkedValues?.map((user, index) => (
                            <Tab key={index} eventKey={JSON.stringify(index)} title={`${user.firstName} ${user.lastName}`} >
                                {formik.values?.loader[interviewer]?.loader ? (
                                    <Flex style={{ padding: '10px 25px  5px 25px' }}>
                                        <Lottie options={defaultOptions} height={360} width={400} isClickToPauseDisabled={true} />
                                    </Flex>
                                ) : (<Flex >
                                    <Flex key={`${user.firstName} ${user.lastName}`} between row style={{ padding: '5px 25px  5px 25px' }}>
                                        <Flex>
                                            <Text size={12} bold style={{ margin: '0 0 2px 0' }} >
                                                {`${user.role} - Interview Questions`}
                                            </Text>
                                            {sample[interviewer]?.success === false ? (<Text>Generate Interview Questions by Selecting Counts for Each Difficulty Level Across Categories.</Text>) :
                                                (<Text>Select question from the generated list for the interview and evaluation process.</Text>)
                                            }
                                        </Flex>
                                        <Flex >
                                            {sample[interviewer]?.success === true ? (
                                                <Flex onClick={functioncall} row center style={{ cursor: 'pointer' }}>
                                                    <Flex marginTop={3} style={{ cursor: 'pointer' }}>
                                                        <SvgAddquestion fill={PRIMARY} width={18} height={18} />
                                                    </Flex>
                                                    <Flex marginLeft={2} style={{ cursor: 'pointer' }}>
                                                        <Text color='link' bold >Add Question</Text>
                                                    </Flex>
                                                </Flex>
                                            ) :
                                                // (<Button
                                                //     onClick={() => generatequestion(index, user.id)

                                                //     } width='85px'>Generate</Button>)
                                                ''
                                            }
                                        </Flex>
                                    </Flex>
                                    {sample[index]?.success === false ? (
                                        <Flex style={{ padding: '0px 25px  0px 25px' }}>
                                            { /* old interview question with check box*/}
                                            {/* <Flex marginTop={5}>
                                                <Text color='theme'> Choose the type(s) of interview questions.<span >*</span></Text>
                                            </Flex> */}
                                            {/* <Flex style={{ borderBottom: '0.5px solid #C3C3C3' }}>
                                                <Flex row >
                                                    {level.map((jobList, idx) => {

                                                        const modifiedJobList = {
                                                            ...jobList,
                                                            name: jobList.value,
                                                        };

                                                        return (
                                                            <Flex key={idx} style={{ margin: '10px  20px  10px 0 ' }}>
                                                                <InputCheckBox
                                                                    label={modifiedJobList.name}
                                                                    checked={isCheckboxChecked(user.id, modifiedJobList.name)}
                                                                    onChange={(event) => {
                                                                        handleCheckboxChange(idx, event, user.id)

                                                                    }

                                                                    }
                                                                />
                                                            </Flex>
                                                        );
                                                    })}

                                                </Flex>
                                                {formik.values?.errorfield[interviewer]?.errorfield && formik.values?.levellist[interviewer]?.level.length === 0 && (<Text color='error'>This field is required.</Text>)}
                                            </Flex> */}
                                            {
                                                formik.values.levellist
                                                    .filter(item => item.id === user.id)
                                                    .map((item, listIndex) => {
                                                        return (
                                                            <>
                                                                {item.level.length !== 0 &&
                                                                    <Flex marginTop={5} style={{ borderBottom: '0.5px solid #C3C3C3', marginBottom: '5px' }}>
                                                                        {/* <Text color='theme'>Choose the difficulty level of the question and question count.<span >*</span></Text> */}
                                                                        <Flex key={listIndex}>
                                                                            {item.level.map((lvl, lvlIndex) => (
                                                                                <Flex key={lvlIndex} row style={{ margin: "10px 0 10px 0" }}>

                                                                                    <Text style={{ width: '85px' }}>{lvl.name}:</Text>
                                                                                    <Flex row style={{ display: "flex", alignItems: "center" }}>
                                                                                        {/* <Flex style={{ padding: '0 5px' }}>
                                                                                            <InputCheckBox
                                                                                                onChange={() => {
                                                                                                    const updatedLevellist = [...formik.values.levellist];
                                                                                                    updatedLevellist[interviewer].level[lvlIndex].iseasycheck = !updatedLevellist[interviewer]?.level[lvlIndex]?.iseasycheck;
                                                                                                    updatedLevellist[interviewer].level[lvlIndex].easy = updatedLevellist[interviewer]?.level[lvlIndex]?.iseasycheck === false && 0;
                                                                                                    formik.setFieldValue('levellist', updatedLevellist);


                                                                                                }}
                                                                                                checked={formik.values?.levellist[interviewer]?.level[lvlIndex]?.iseasycheck}
                                                                                            />
                                                                                        </Flex> */}
                                                                                        <Flex style={{ padding: '0 5px' }}>
                                                                                            <Text>Easy</Text>
                                                                                        </Flex>
                                                                                        {/* {disabled={!formik.values?.levellist[interviewer]?.level[lvlIndex]?.iseasycheck}} */}
                                                                                        <Flex style={{ padding: '0 5px' }} >
                                                                                            <input
                                                                                                min="0"
                                                                                                max="15"
                                                                                                type="number"
                                                                                                value={lvl.easy}
                                                                                                onChange={(e) => {
                                                                                                    const updatedLevellist = formik.values.levellist.map(levelItem => ({
                                                                                                        ...levelItem,
                                                                                                        level: [...levelItem.level]
                                                                                                    }));
                                                                                                    if (updatedLevellist[interviewer]?.id === user.id) {
                                                                                                        updatedLevellist[interviewer].level[lvlIndex] = {
                                                                                                            ...updatedLevellist[interviewer].level[lvlIndex],
                                                                                                            easy: e.target.value
                                                                                                        };

                                                                                                        formik.setFieldValue('levellist', updatedLevellist);
                                                                                                    }
                                                                                                }}
                                                                                                //this is old checkbox
                                                                                                // disabled={!formik.values?.levellist[interviewer]?.level[lvlIndex]?.iseasycheck}
                                                                                                // onChange={(e) => {
                                                                                                //     const updatedLevellist = [...formik.values.levellist];
                                                                                                //     updatedLevellist[interviewer].level[lvlIndex].easy = e.target.value;
                                                                                                //     formik.setFieldValue('levellist', updatedLevellist);
                                                                                                // }}
                                                                                                maxLength={1}
                                                                                                style={{ border: '1px solid #A5889C' }}
                                                                                                className={styles.scoreinputfield}
                                                                                            />
                                                                                        </Flex>
                                                                                    </Flex>
                                                                                    <Flex row style={{ display: "flex", alignItems: "center" }} marginLeft={15}>
                                                                                        {/* <Flex style={{ padding: '0 5px' }}>
                                                                                            <InputCheckBox
                                                                                                onChange={() => {
                                                                                                    const updatedLevellist = [...formik.values.levellist];
                                                                                                    updatedLevellist[interviewer].level[lvlIndex].ismediumcheck = !updatedLevellist[interviewer]?.level[lvlIndex]?.ismediumcheck;
                                                                                                    updatedLevellist[interviewer].level[lvlIndex].medium = updatedLevellist[interviewer]?.level[lvlIndex]?.ismediumcheck === false && 0;
                                                                                                    formik.setFieldValue('levellist', updatedLevellist);

                                                                                                }}
                                                                                                checked={formik.values?.levellist[interviewer]?.level[lvlIndex]?.ismediumcheck}
                                                                                            />
                                                                                        </Flex> */}
                                                                                        <Flex style={{ padding: '0 5px' }}>
                                                                                            <Text>Medium </Text>
                                                                                        </Flex>
                                                                                        {/* {disabled={!formik.values?.levellist[interviewer]?.level[lvlIndex]?.ismediumcheck}} */}
                                                                                        <Flex style={{ padding: '0 5px' }} >
                                                                                            <input
                                                                                                min="0"
                                                                                                max="15"
                                                                                                type="number"
                                                                                                value={lvl.medium}
                                                                                                onChange={(e) => {
                                                                                                    const updatedLevellist = formik.values.levellist.map(levelItem => ({
                                                                                                        ...levelItem,
                                                                                                        level: [...levelItem.level]
                                                                                                    }));
                                                                                                    if (updatedLevellist[interviewer]?.id === user.id) {
                                                                                                        updatedLevellist[interviewer].level[lvlIndex] = {
                                                                                                            ...updatedLevellist[interviewer].level[lvlIndex],
                                                                                                            medium: e.target.value
                                                                                                        };
                                                                                                        formik.setFieldValue('levellist', updatedLevellist);
                                                                                                    }
                                                                                                }}

                                                                                                // this is old checkbox
                                                                                                // disabled={!formik.values?.levellist[interviewer]?.level[lvlIndex]?.ismediumcheck}
                                                                                                // onChange={(e) => {
                                                                                                //     const updatedLevellist = [...formik.values.levellist];
                                                                                                //     updatedLevellist[interviewer].level[lvlIndex].medium = e.target.value;
                                                                                                //     formik.setFieldValue('levellist', updatedLevellist);

                                                                                                // }}
                                                                                                maxLength={1}
                                                                                                style={{ border: '1px solid #A5889C' }}
                                                                                                className={styles.scoreinputfield}
                                                                                            />
                                                                                        </Flex>
                                                                                    </Flex>
                                                                                    <Flex row style={{ display: "flex", alignItems: "center" }} marginLeft={15}>
                                                                                        {/* <Flex style={{ padding: '0 5px' }}>
                                                                                            <InputCheckBox
                                                                                                onChange={() => {
                                                                                                    const updatedLevellist = [...formik.values.levellist];
                                                                                                    updatedLevellist[interviewer].level[lvlIndex].ishardcheck = !updatedLevellist[interviewer]?.level[lvlIndex]?.ishardcheck;
                                                                                                    updatedLevellist[interviewer].level[lvlIndex].hard = updatedLevellist[interviewer]?.level[lvlIndex]?.ishardcheck === false && 0;
                                                                                                    formik.setFieldValue('levellist', updatedLevellist);

                                                                                                }}
                                                                                                checked={formik.values?.levellist[interviewer]?.level[lvlIndex]?.ishardcheck}
                                                                                            />
                                                                                        </Flex> */}
                                                                                        <Flex style={{ padding: '0 5px' }}>
                                                                                            <Text>Hard</Text>
                                                                                        </Flex>
                                                                                        {/* {disabled={!formik.values?.levellist[interviewer]?.level[lvlIndex]?.ishardcheck}} */}
                                                                                        <Flex style={{ padding: '0 5px' }} >
                                                                                            <input
                                                                                                min="0"
                                                                                                max="15"
                                                                                                type="number"
                                                                                                value={lvl.hard}
                                                                                                // disabled={!formik.values?.levellist[interviewer]?.level[lvlIndex]?.ishardcheck}
                                                                                                onChange={(e) => {
                                                                                                    const updatedLevellist = formik.values.levellist.map(levelItem => ({
                                                                                                        ...levelItem,
                                                                                                        level: [...levelItem.level]
                                                                                                    }));
                                                                                                    if (updatedLevellist[interviewer]?.id === user.id) {
                                                                                                        updatedLevellist[interviewer].level[lvlIndex] = {
                                                                                                            ...updatedLevellist[interviewer].level[lvlIndex],
                                                                                                            hard: e.target.value
                                                                                                        };
                                                                                                        formik.setFieldValue('levellist', updatedLevellist);
                                                                                                    }
                                                                                                }}

                                                                                                //interview question old check box
                                                                                                // onChange={(e) => {
                                                                                                //     const updatedLevellist = [...formik.values.levellist];
                                                                                                //     updatedLevellist[interviewer].level[lvlIndex].hard = e.target.value;
                                                                                                //     formik.setFieldValue('levellist', updatedLevellist);
                                                                                                // }}
                                                                                                maxLength={1}
                                                                                                style={{ border: '1px solid #A5889C' }}
                                                                                                className={styles.scoreinputfield}
                                                                                            />
                                                                                        </Flex>
                                                                                    </Flex>
                                                                                </Flex>
                                                                            ))}
                                                                        </Flex>


                                                                        <Flex> {formik.values?.showstate[interviewer]?.showstate === true && (emherror())}</Flex>
                                                                        {formik.values?.required[interviewer]?.required === true && !(counterror()) && (<Text color='error'>Please specify the number of questions to generate.</Text>)}

                                                                    </Flex>}
                                                            </>
                                                        );
                                                    })
                                            }

                                        </Flex>
                                    ) : (

                                        <Flex>

                                            {Array(sample && sample[index]?.question)?.map((val, index1) => (
                                                <Flex key={index1} style={{ overflowY: 'scroll', height: '400px', overflowX: 'hidden', padding: '0px 8px 0px 25px' }} marginRight={5}>

                                                    <Flex>
                                                        {val?.Question?.map((value, ind) => (
                                                            <Card key={ind} className={styles.cardview} >
                                                                <Flex>
                                                                    <Text style={{ textTransform: "capitalize" }} bold>
                                                                        {value.Category}
                                                                    </Text>
                                                                    {value?.Value?.map((label, idx) => (
                                                                        <Flex key={idx} style={{ borderBottom: value.Value.length - 1 === idx ? '' : '0.3px solid #C3C3C3', paddingBottom: '7px' }}>
                                                                            <Flex row marginTop={7}>
                                                                                <Flex marginRight={7} marginTop={1}>
                                                                                    {handlelevelradio(label?.Map_question[label?.Map_question?.length - 1].level)}
                                                                                </Flex>
                                                                                <Flex>
                                                                                    <Text color='theme' style={{ textTransform: "capitalize" }}>{label?.Map_question[label?.Map_question?.length - 1].level}</Text>
                                                                                </Flex>
                                                                            </Flex>
                                                                            {label?.Map_question?.map((ques, i) => (
                                                                                <Flex row key={i} marginTop={5}>
                                                                                    <Flex style={{ margin: '1.2px 5px 0 0' }}>
                                                                                        <InputCheckBox
                                                                                            checked={formik.values?.question?.some(obj => obj?.id === Number(val.Id) && obj?.question?.includes(ques?.id))}
                                                                                            onChange={() => {
                                                                                                setUnchecked(true);
                                                                                                const existingIndex = formik.values.question?.findIndex(obj => obj.id === Number(val.Id));
                                                                                                if (existingIndex > -1) {
                                                                                                    const foundObject = formik.values.question[existingIndex];
                                                                                                    const questionIndex = foundObject?.question?.indexOf(ques.id);

                                                                                                    if (questionIndex > -1) {
                                                                                                        foundObject?.question?.splice(questionIndex, 1);
                                                                                                    } else {

                                                                                                        foundObject?.question?.push(ques.id);
                                                                                                    }
                                                                                                } else {
                                                                                                    formik.values?.question?.push({ id: Number(val.Id), question: [ques.id] });
                                                                                                }

                                                                                                formik.setFieldValue('question', [...formik.values.question]);
                                                                                            }}
                                                                                        />
                                                                                    </Flex>
                                                                                    <Text>{i + 1}.&emsp;</Text>
                                                                                    <Text style={{ textAlign: 'justify' }}>{ques.question}</Text>
                                                                                </Flex>
                                                                            ))}
                                                                        </Flex>
                                                                    ))}
                                                                </Flex>
                                                            </Card>
                                                        ))}
                                                    </Flex>

                                                </Flex>
                                            ))}
                                        </Flex>
                                    )

                                    }

                                    {
                                        sample[interviewer]?.success && formik.values?.question[questionindex]?.question?.length === 0 && questionerror && (
                                            <Flex >
                                                {renderErrorComponents()}
                                            </Flex>
                                        )
                                    }
                                    <Flex row between marginTop={7} style={{ padding: '0px 25px 25px 25px' }}>
                                        <Flex  >
                                            <Button types="secondary" onClick={handlechange1} width='85px'>
                                                Back
                                            </Button>
                                        </Flex>
                                        <Flex row>
                                            <Button types="close" onClick={handlefunction1} width='85px'>
                                                Cancel
                                            </Button>
                                            {sample[interviewer]?.success ? (<Button style={{ margin: '0 0 0 10px' }} onClick={submit} width='85px'>
                                                Continue
                                            </Button>) : (
                                                (<Button
                                                    style={{ margin: '0 0 0 10px' }}
                                                    onClick={() => generatequestion(index, user.id)

                                                    } width='85px'>Generate</Button>)
                                            )
                                            }
                                        </Flex>

                                    </Flex>
                                </Flex>)}

                            </Tab>
                        ))}
                    </Tabs>
                </Flex>

            </Flex>
        </>
    )
};

