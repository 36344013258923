import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { backendProcessApi, creditsPurchaseApi, orderSummaryApi } from '../../../routes/apiRoutes';
const qs = require('qs');

export const orderSummaryMiddleWare = createAsyncThunk(
  'orderSummary',
  async (
    {
      key,
      count,
      discounts,
      add_on_id,
      add_on_count,
      state
    }: { key: string; count: string; discounts?: string,add_on_id:any,add_on_count:any,state:any; },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await axios.get(orderSummaryApi, {
        params: {
          key,
          c: count,
          discounts,
          add_on_id,
          add_on_count,
          state
        },
      });
      return data;
    } catch (error) {
      const typedError = error as Error;
      return rejectWithValue(typedError);
    }
  },
);

export const orderSummaryPostMiddleWare = createAsyncThunk(
  'orderSummaryPost',
  async (
    {
      update,
      discounts,
      key,
      count,
      finalvalue,
    }: { update?: string; discounts?: string; key?: string; count?: any ;finalvalue?:any},
    { rejectWithValue },
  ) => {
    try {
      const { data } = await axios.post(
        orderSummaryApi,
        qs.stringify(
          { update, discounts, key, c: count, finalvalue:finalvalue },
          { arrayFormat: 'comma' },
        ),
      );
      return data;
    } catch (error) {
      const typedError = error as Error;
      return rejectWithValue(typedError);
    }
  },
);

export const backendProcessMiddleWare = createAsyncThunk(
  'backendProcess',
  async ({ session_id }: { session_id?: string }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(backendProcessApi, {
        params: { session_id },
      });
      return data;
    } catch (error) {
      const typedError = error as Error;
      return rejectWithValue(typedError);
    }
  },
);

export const creditsPurchaseMiddleWare = createAsyncThunk(
  'creditsPurchaseApi',
  async ({ session }: { session?: string }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(creditsPurchaseApi, {
        params: { session },
      });
      return data;
    } catch (error) {
      const typedError = error as Error;
      return rejectWithValue(typedError);
    }
  },
);
