import { useFormik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { jobSelect, reports, homeRoute, jobCreateNonDs } from '../../../appRoutesPath';
import SvgSearch from '../../../icons/SvgSearch';
import { routesPath } from '../../../routes/routesPath';
import { RootState } from '../../../store';
import { WHITE } from '../../../uikit/Colors/colors';
import InputSearch from '../../../uikit/InputSearch/InputSearch';
import InputSearchnav from '../../../uikit/InputSearch/inputsearchnav';
import { searchOptions } from './mock';
import styles from './navbar.module.css';

type Props = {
  onButtonClick: () => void;
  onbuttonchange: () => void;
};
const NavigationSearch = ({ onButtonClick, onbuttonchange }) => {
  const history = useHistory();
  const formik = useFormik({
    initialValues: { value: '' },
    onSubmit: () => { },
  });
  const { permission, super_user, plan_id, roles } = useSelector(
    ({ permissionReducers }: RootState) => {
      return {
        permission: permissionReducers.Permission,
        super_user: permissionReducers.super_user,
        plan_id: permissionReducers.plan_id,
        roles: permissionReducers.roles,
      };
    },
  );

  // search redirection condition
  const searchNavigate = (value: string) => {
    switch (value.toLocaleLowerCase()) {
      case ' my job postings':
        return (
          history.push(routesPath.MY_JOB_POSTING),
          sessionStorage.setItem('ViewJobsDropdown', '1')
        );
      case 'job postings':
        return (
          history.push(routesPath.MY_JOB_POSTING),
          sessionStorage.setItem('ViewJobsDropdown', '1')
        );
      case 'job listings':
        return (
          history.push(routesPath.MY_JOB_POSTING),
          sessionStorage.setItem('ViewJobsDropdown', '1')
        );
      case 'zita match':
        return (
          history.push(routesPath.MY_JOB_POSTING),
          sessionStorage.setItem('ViewJobsDropdown', '1')
        );
      case 'applicants pipeline':
        return (
          history.push(routesPath.MY_JOB_POSTING),
          sessionStorage.setItem('ViewJobsDropdown', '1')
        );
      case 'dashboard':
        return history.push(homeRoute);
      case 'manage users':
        return (
          sessionStorage.setItem('MyAccountDropdown', '1'),
          history.push('/account_setting/manage_user')
        );
      case 'user profile':
        return (
          // super_user === true &&
          history.push('/account_setting/profiles'),
          sessionStorage.setItem('MyAccountDropdown', '1'),
          permission.includes('manage_account_settings')
        );
      case 'password change':
        onButtonClick();
        break;
      case 'profile update':
        sessionStorage.setItem('MyAccountDropdown', '1');
        history.push('/account_setting/profiles');
        break;
      case 'calendar':
        return history.push('/calendar'),
          sessionStorage.setItem('CommunicationDropdown', '1')

      case 'calendar integrations':
        return history.push('/account_setting/integration'),
        sessionStorage.setItem('CommunicationDropdown', '1')
      case 'integrations':
        return history.push('/account_setting/integration'),
        sessionStorage.setItem('CommunicationDropdown', '1')
      case 'email':
        return history.push('/mail'),
          sessionStorage.setItem('CommunicationDropdown', '1')
      case 'event scheduler':
        return history.push('/event_scheduler'),
          sessionStorage.setItem('CommunicationDropdown', '1')
      case 'inbox':
        return history.push('/mail'),
          sessionStorage.setItem('CommunicationDropdown', '1')
      case 'email integration':
        return history.push('/account_setting/integration'),
        sessionStorage.setItem('CommunicationDropdown', '1')
      case 'mail integration':
        return history.push('/account_setting/integration'),
        sessionStorage.setItem('CommunicationDropdown', '1')

      case 'build your careers page':
        return history.push('/account_setting/build_careers_page'),
        sessionStorage.setItem('BrandDropdown', '1');
      case 'careers page':
        return history.push('/account_setting/build_careers_page'),
        sessionStorage.setItem('BrandDropdown', '1');
      case 'setup careers page':
        return history.push('/account_setting/build_careers_page'),
        sessionStorage.setItem('BrandDropdown', '1');
      case 'company profile':
        sessionStorage.setItem('MyAccountDropdown', '1');
        return history.push('/account_setting/profiles');
      case 'manage subscription':
        sessionStorage.setItem('MyAccountDropdown', '1');
        return history.push('/account_setting/subscription');

      case 'contact credits':
        sessionStorage.setItem('MyAccountDropdown', '1');
        return history.push('/account_setting/subscription');
      case 'billing':
        sessionStorage.setItem('MyAccountDropdown', '1');
        return history.push('/account_setting/subscription');
      case 'pricing':
        sessionStorage.setItem('MyAccountDropdown', '1');
        return history.push('/account_setting/subscription');
      case 'plans':
        sessionStorage.setItem('MyAccountDropdown', '1');
        return history.push('/account_setting/subscription');
      case 'database':
        return (
          history.push(routesPath.MYDATABASE),
          sessionStorage.setItem('CandidateDropdown', '1')
        );
      case 'candidates':
        return (
          history.push(routesPath.MYDATABASE),
          sessionStorage.setItem('CandidateDropdown', '1')
        );
      case 'invite candidates':
        return (
          history.push(routesPath.MYDATABASE),
          sessionStorage.setItem('CandidateDropdown', '1')
        );
      case 'match candidate':
        return (
          history.push(routesPath.MYDATABASE),
          sessionStorage.setItem('CandidateDropdown', '1')
        );
      case 'post job':
        return (
          history.push(jobCreateNonDs),
          sessionStorage.setItem('JobsDropdown', '1')
        );
      case 'post jobs':
        return (
          history.push(jobCreateNonDs),
          sessionStorage.setItem('JobsDropdown', '1')
        );
      case 'create job':
        return (
          history.push(jobCreateNonDs),
          sessionStorage.setItem('JobsDropdown', '1')
        );
      case 'post new jobs':
        return (
          history.push(jobCreateNonDs),
          sessionStorage.setItem('JobsDropdown', '1')
        );
      // case 'my job postings':
      //   return history.push('/job_list');
      case 'my job postings':
        return (
          history.push('/job_list'),
          sessionStorage.setItem('ViewJobsDropdown', '1')
        );
      case 'zita match candidates':
        return history.push('/job_list');
      case 'reports':
        return history.push(reports);

      case 'bulk import candidates':
        return history.push(routesPath.BULK_IMPORT);
      case 'upload candidates':
        return history.push(routesPath.BULK_IMPORT);
      case 'applicants importing':
        return (
          history.push(routesPath.BULK_IMPORT),
          sessionStorage.setItem('CandidateDropdown', '1')
        );
      case 'upload applicants':
        return (
          history.push(routesPath.BULK_IMPORT),
          sessionStorage.setItem('CandidateDropdown', '1')
        );
      case 'bulk import':
        return (
          history.push(routesPath.BULK_IMPORT),
          sessionStorage.setItem('CandidateDropdown', '1')
        );
      case 'bulk upload':
        return (
          history.push(routesPath.BULK_IMPORT),
          sessionStorage.setItem('CandidateDropdown', '1')
        );
      case 'resume upload':
        return (
          history.push(routesPath.BULK_IMPORT),
          sessionStorage.setItem('CandidateDropdown', '1')
        );
      case 'resume importing':
        return (
          history.push(routesPath.BULK_IMPORT),
          sessionStorage.setItem('CandidateDropdown', '1')
        );

      case 'support':
      case 'help':
        return window.open(
          'https://share.hsforms.com/1WPpWRzsQT6CyEVAQuDP6wg49hg8',
        );
      case 'talent sourcing':
        return (
          history.push(routesPath.TALENT_SOURCING),
          sessionStorage.setItem('CandidateDropdown', '1')
        );
      case 'source candidates':
        return (
          history.push(routesPath.TALENT_SOURCING),
          sessionStorage.setItem('CandidateDropdown', '1')
        );
      case 'sourcing':
        return (
          history.push(routesPath.TALENT_SOURCING),
          sessionStorage.setItem('CandidateDropdown', '1')
        );

      case 'workflow':
        return (
          history.push('/account_setting/hiring_workflow'),
          sessionStorage.setItem('JobsDropdown', '1')
        );
      case 'workflow settings':
        return (
          history.push('/account_setting/hiring_workflow'),
          sessionStorage.setItem('JobsDropdown', '1')
        );
      case 'event scheduler':
        return (
          history.push('/event_scheduler'),
          sessionStorage.setItem('CommunicationDropdown', '1')
        )
    }
  };

  useEffect(() => {
    if (formik.values.value !== '') searchNavigate(formik.values.value);
  }, [formik.values.value]);

  const optionsArray: any = searchOptions;

  // search list condition
  useEffect(() => {
    if (plan_id !== 1) {
      optionsArray.push('Reports');
    }
    if (super_user === true) {
      optionsArray.push(
        'Manage Subscription',
        'Billing',
        'Contact Credits',
        'Pricing',
        'Plans',
        'Manage Users',
      );
    }
    optionsArray.push(
      // 'My Job Postings',
      'Careers Page',
      'Setup Careers Page',
      'integrations',
      'Zita Match Candidates',
      'Applicants Pipeline',
      'Calendar',

      'Email',
      'Inbox',
      'Email Integration',
      'Mail Integration',

      'Match Candidate',

      'Source Candidates',
      'Sourcing',

      'Candidates Importing',

      'Applicants Importing',
      'Upload Applicants',
      'Bulk Import',
      'Bulk Upload',
      'Resume Upload',
      'Resume Importing',

      'Workflow Settings',
      'Workflow',

      'Event Scheduler'
    );

    if (permission.includes('my_database')) {
      optionsArray.push('Database', 'Candidates', 'Invite Candidates');
    }
    if (permission.includes('talent_sourcing')) {
      optionsArray.push('Talent Sourcing');
    }
    if (permission.includes('bulkImport_candidates')) {
      optionsArray.push('Bulk Import Candidates', 'Upload Candidates');
    }
    if (permission.includes('create_post')) {
      optionsArray.push('Create Job', 'Post Job', 'Post New Jobs');
    }
    if (
      permission.includes('manage_account_settings') &&
      super_user === false
    ) {
      optionsArray.push('', 'Company Profile');
    }
  }, [searchOptions, plan_id, super_user, permission]);
  const toFindDuplicates = (arry: string[]) =>
    arry.filter((item, index) => arry.indexOf(item) === index);
  const duplicateElementa = toFindDuplicates(optionsArray);

  return (
    <div style={{ position: 'relative', width: '195px' }}>
      <div style={{ position: 'absolute', zIndex: 11, top: 3, left: 10 }}>
        <SvgSearch fill={'#581845'} />
      </div>

      <InputSearchnav
        style={styles.searchStyle}
        placeholder="Enter your search here..."
        options={duplicateElementa}
        setFieldValue={formik.setFieldValue}
        name="value"
        onkeyPress={(event) => {
          if (event.key === 'Enter') {
            searchNavigate(event.target.value);
            formik.setFieldValue('value', event.target.value);
          }
        }}
      />
    </div>
  );
};

export default NavigationSearch;
