import { FormikProps } from 'formik';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import { RootState } from '../../store';
import Flex from '../../uikit/Flex/Flex';
import { getBlur, getFocus } from '../../uikit/helper';
import Pangination from '../../uikit/Pagination/Pangination';
import SvgNomessage from '../../icons/SvgNomessage';
import SvgNotinterests from '../../icons/SvgNotinterests';
import Text from '../../uikit/Text/Text';
import Card from '../../uikit/Card/Card';
import Avatar from '../../uikit/Avatar/Avatar';
import Blur from '../../uikit/Blur/blur';
import SvgAdd from '../../icons/SvgAdd';
import SvgNotInterested from '../../icons/SvgNotInterested';
import LabelWithSvg from '../common/LabelWithSvg';
import SvgNotesOne from '../../icons/SvgNotesOne';
import { GARY_4, PRIMARY } from '../../uikit/Colors/colors';
import SvgView from '../../icons/SvgView';
import SvgSubcriptioncrown from '../../icons/Subscriptioncrown';
import SubscriptionModal from '../subscriptionmodule/subscriptionmoduleScreen';

import InputCheckBox from '../../uikit/InputCheckbox/InputCheckBox';
import SvgInvite from '../../icons/SvgInvite';
import { option } from '../myjobposting/mock';
import MyDataBaseBulkAction from './MyDataBaseBulkAction'; // eslint-disable-line
import MyDataBaseCard from './MyDataBaseCard'; // eslint-disable-line
import { MyDataFormProps } from './MyDataBaseScreen'; // eslint-disable-line
import { DataEntity } from './myDataBaseTypes';
import styles from './mydatabaselist.module.css';
import InviteContainer from './InviteContainer';
import SkillContainer from './SkillContainer';

type Props = {
  data: DataEntity[];
  tabKey: string;
  totalCount: number;
  filterFormik: FormikProps<MyDataFormProps>;
  qaValue: string;
  skillsOptionsList: any;
  jobId: string | boolean;
  hanldeInvite: (arg: number) => void;
  isFav: boolean;
  handleFav: () => void;
  handleSelectAll: () => void;
  isCheckAll: boolean;
  isCheck: string[];
  handleCheckBoxClick: (e: {
    target: { id: string; checked: boolean };
  }) => void;
  hanldeDownload: () => void;
  isSortOptions: {
    value: string;
    label: string;
  };
  setSortOptions: Dispatch<
    SetStateAction<{
      value: string;
      label: string;
    }>
  >;
  isPage: number;
  setPage: (arg: number) => void;
  addFavFilter: string;
  tab?: string;
  cleartick: () => void;
  pagination_count?: number;
  setpagecount?: (arg: number) => void;
};

const MyDataBaseList = ({
  data,
  tabKey,
  totalCount,
  filterFormik,
  qaValue,
  skillsOptionsList,
  jobId,
  hanldeInvite,
  isFav,
  handleFav,
  isCheckAll,
  isCheck,
  handleSelectAll,
  handleCheckBoxClick,
  hanldeDownload,
  isSortOptions,
  setSortOptions,
  isPage,
  setPage,
  addFavFilter,
  tab,
  cleartick,
  pagination_count,
  setpagecount
}: Props) => {
  // subscription setstate
  const [isopensubcription, setopensubcription] = useState(false);
  const scrollableContainerRef = useRef<HTMLDivElement>(null);
  const sidebar = sessionStorage.getItem('EmpToggle');
  const size = sidebar === '1';
  const [componentHeight, setComponentHeight] = useState(window.innerHeight - 260);

  // pagination function
  const handleSetPagination = (a: number) => {
    setPage(a);
    if (data.length !== 0) {
      getFocus(data[0].id.toString());
      getBlur(data[0].id.toString());
    }
  };
  const { disable_count, datas } = useSelector(
    ({ SubscriptionReducers, myDataBaseDataReducers }: RootState) => {
      return {
        disable_count: SubscriptionReducers.disable_count,
        datas: myDataBaseDataReducers.data,

      };
    },
  );

  // pagination count pased on user
  const usersPerPage = pagination_count;
  const pageCount = Math.ceil(totalCount / usersPerPage);

  // const getHeight = jobId === false ? 293 : 303;
  const getHeight = jobId === false ? 271 : 303;
  const isTablet = useMediaQuery({ query: '(max-width: 1000px)' });
  const normal = useMediaQuery({
    query: '(min-width: 1000px) and (max-width: 1411px)',
  });
  const tabsubcription = ['All', 'Applicants'];

  useEffect(() => {
    if (scrollableContainerRef.current) {
      scrollableContainerRef.current.scrollTop = 0;
    }
  }, [tabKey, isPage, addFavFilter, isPage])


  useEffect(() => {
    const handleResize = () => {
      setComponentHeight(window.innerHeight - 260);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // custom styles for page selection text box and dropdown.
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#581845' : '#fff',
      color: state.isSelected ? '#fff' : '#000',
      justifyContent: "center",
      '&:hover': {
        backgroundColor: state.isSelected ? 'none' : '#D7C7D2',
      },
    }),
    control: (provided, state) => ({
      ...provided,
      border: state.isFocused ? '1px solid #581845' : '1px solid #d7c7d2',
      fontSize: '12px',
      minHeight: '16px',
      lineHeight: '16px',
      boxShadow: state.isFocused ? 'none' : 'none',
      '&:hover': {
        border: '1px solid #581845',
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      height: '16px',
      lineHeight: '16px',
      textAlign: 'center',
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      margin: '5px 0',
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      transition: 'transform 0.3s ease',
      height: '16px',
      marginTop: '-20px'
    }),
    menu: (provided) => ({
      ...provided,
      top: 'auto',
      bottom: 22,
    }),
  };


  return (
    <div  >
      <MyDataBaseBulkAction
        cleartick={cleartick}
        totalCount={totalCount}
        data={data}
        filterFormik={filterFormik}
        isFav={isFav}
        handleFav={handleFav}
        handleSelectAll={handleSelectAll}
        isCheckAll={isCheckAll}
        hanldeDownload={hanldeDownload}
        isCheck={isCheck}
        setSortOptions={setSortOptions}
        isSortOptions={isSortOptions}
        tabKey={tabKey}
        addFavFilter={addFavFilter}
        qaValue={qaValue}
        skillsOptionsList={skillsOptionsList}
        isPage={isPage}
      />

      <div
        style={{
          height: componentHeight,
          overflowY: 'scroll',
          overflowX: 'hidden',
          paddingRight: 0,
          paddingTop: 0,
          display: 'flex',
          flexWrap: 'wrap',
          alignContent: 'flex-start',
        }}
        id="scrollableContainer"
        ref={scrollableContainerRef}
      >
        {datas.length === 0 && data && data.length === 0 && (
          <Flex
            flex={1}
            center
            middle
            height={'100%'}
            style={{ display: 'flex' }}
          >
            <SvgNotinterests
              style={{ marginBottom: '15px', filter: 'opacity(0.5)' }}
            />
            <Text color="gray" style={{ position: 'relative', bottom: 16 }}>
              No candidate found
            </Text>
          </Flex>
        )}

        {/* Dummy card for subcription purpose */}

        {datas.length !== 0 && disable_count !== 0 && tabsubcription.includes(tab) && (
          <Flex
            className={styles.cardwrap}
            style={{
              width: isTablet ? '98%' : normal ? '48%' : '32%',
            }}
          >
            <Blur
              width={isTablet ? '100%' : normal ? '96.5%' : '96%'}
              height="110px"
              content="Feature to access Applicants view."
              popuponly
              rightalign="9px"
              marginTop={8}
              Addon
              iscount
              count={disable_count}
              upgrade
              handlefunction={setopensubcription}
            />
            <Card className={styles.cardOverAll}>
              <Flex row columnFlex className={styles.cardHeight}>
                <Flex columnFlex top>
                  <InputCheckBox onChange={handleCheckBoxClick} checked />
                </Flex>
                <Flex flex={1} width={'100%'} row>
                  <Flex className={styles.profileFlex} width={'70%'}>
                    <Flex row marginLeft={5}>
                      <Flex className={styles.profileDiv} middle>
                        <Avatar className={styles.profile} initials={'MR'} />
                      </Flex>
                      <Flex className={styles.nameFlex}>
                        <Flex row style={{ width: '100%' }} center>
                          <Text bold color={'link'}>
                            Zita
                          </Text>
                          <Flex className={styles.svgView} marginLeft={8}>
                            <SvgView height={15} width={15} nonView />
                          </Flex>
                        </Flex>
                        <Flex>
                          <Text size={11}>Coimbatore</Text>
                        </Flex>
                        <Flex row>
                          <Text size={11} style={{ marginRight: '3px' }}>
                            Bachelors
                          </Text>
                          <Text size={11} color="gray" className={styles.slace}>
                            |
                          </Text>
                          <Text size={11} style={{ marginLeft: '3px' }}>
                            4 Years
                          </Text>
                        </Flex>
                      </Flex>
                    </Flex>
                    <Flex marginTop={5} marginLeft={10}>
                      <Flex marginTop={5} className={styles.skillFlex}>
                        <Text
                          color="black_1"
                          className={styles.skillTextStyle}
                          size={11}
                        >
                          <Text
                            color="black_1"
                            bold
                            size={11}
                            style={{ maxWidth: '90%' }}
                          >
                            Skills:{' '}
                          </Text>
                          Not specified
                        </Text>
                        <Flex
                          row
                          between
                          center
                          className={styles.labelSvg}
                          style={{ top: !size ? '5%' : '-1%' }}
                        >
                          <LabelWithSvg
                            label="Notes"
                            svg={
                              <SvgNotesOne
                                height={14}
                                width={14}
                                fill={PRIMARY}
                              />
                            }
                          />
                          <Flex marginRight={10}>
                            <Text color={'link'} size={11} bold>
                              Show All Matching Jobs
                            </Text>
                          </Flex>
                        </Flex>
                      </Flex>
                    </Flex>
                  </Flex>
                  <Flex
                    width="30%"
                    height={'auto'}
                    center
                    middle
                    style={{ borderLeft: '1px solid #A5889C' }}
                  >
                    <Flex>
                      <Flex middle style={{ opacity: 0.3 }}>
                        <SvgInvite width={28} height={28} />
                      </Flex>
                      <Flex>
                        <Text color="black_1" size={11}>
                          Invite to apply
                        </Text>
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            </Card>
          </Flex>
        )}

        <>{data &&
          data.map((dataList, index) => {
            return (
              <>
                <MyDataBaseCard
                  index={index}
                  key={dataList.first_name + index}
                  dataList={dataList}
                  qaValue={qaValue}
                  skillsOptionsList={skillsOptionsList}
                  tabKey={tabKey}
                  filterFormik={filterFormik}
                  jobId={jobId}
                  hanldeInvite={hanldeInvite}
                  isFav={isFav}
                  isCheck={isCheck}
                  handleCheckBoxClick={handleCheckBoxClick}
                  isSortOptions={isSortOptions}
                  isPage={isPage}
                  addFavFilter={addFavFilter}
                />
              </>
            );
          })}
        </>
        <div style={{ width: '100%' }}>
          {totalCount !== 0 &&
            <Flex center row between className={styles.pagination} flex={12}>
              <Flex flex={4}></Flex>
              <Flex flex={4} middle>
                <Pangination
                  maxPages={pageCount - 1}
                  currentPage={isPage}
                  setCurrentPage={handleSetPagination}
                />
              </Flex>
              <Flex row center flex={4} end>
                <Flex style={{ marginRight: '10px', fontSize: '13px' }}>Candidates per page :</Flex>
                <Flex marginRight={20}>
                  <Select
                    options={option}
                    value={option.find((e) => e.value === pagination_count)}
                    onChange={(e) => {
                      setpagecount(e.value);
                    }}
                    menuPosition="fixed"
                    styles={customStyles}
                  />
                </Flex>
              </Flex>
              {/* )} */}
            </Flex>}
        </div>




      </div>
      {isopensubcription && (
        <SubscriptionModal
          openmodel={isopensubcription}
          setopensubcription={setopensubcription}
          addon_name={['AI Resume Parsing Credits']}
          differece_key={['Ai_matching_applicant']}
          purpose={
            <div>
              <strong>AI Resume Parsing Limit Reached:</strong> To continue viewing applicants, you can &apos;Upgrade your plan&apos; or &apos;Buy additional AI Resume parsing Credits&apos;.
            </div>
          }

          purpose_freetrial={
            <div>
              <strong>AI Resume Parsing Limit Reached:</strong> To continue viewing applicants, you can &apos;Upgrade your plan&apos;.
            </div>
          }

          purpose_addon={
            <div>
              <strong>AI Resume Parsing Limit Reached:</strong> To continue viewing applicants, you can &apos;Buy additional AI Resume parsing Credits&apos;.
            </div>
          }

        />
      )}
    </div>
  );
};

export default MyDataBaseList;
