import { FormikProps } from 'formik';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SvgSearch from '../../icons/SvgSearch';
import SvgLocation from '../../icons/SvgLocation';
import SvgIntomark from '../../icons/SvgCancel';
import SvgJobselection from '../../icons/SvgJobselection';
import Button from '../../uikit/Button/Button';
import Flex from '../../uikit/Flex/Flex';
import SvgInfinity from '../../icons/SvgInfinity';
import Totalcount from '../../globulization/TotalCount';
import { enterKeyPress, toCamelCase } from '../../uikit/helper';
import InputText from '../../uikit/InputText/InputText';
import SelectTag from '../../uikit/SelectTag/SelectTag';
import Text from '../../uikit/Text/Text';
import { AppDispatch, RootState } from '../../store';
import { isEmpty } from '../../uikit/helper';
import SvgParsing from '../../icons/SvgParsing';
import SvgJobTitleIcon from '../../icons/SvgJobTitleIcon';
import { InputSearch, Loader } from '../../uikit';
import SvgRefresh from '../../icons/SvgRefresh';
import { zitaMatchProgressMiddleWare } from '../zitamatchcandidatemodule/store/middleware/zitamatchcandidatemiddleware';
import { MyDataFormProps } from './MyDataBaseScreen'; // eslint-disable-line
import styles from './mydatabasesearchaction.module.css';
import { JobTitleEntity } from './myDataBaseTypes';




type Props = {
  jobTitle: JobTitleEntity[];
  formik: FormikProps<MyDataFormProps>;
  isSearchValue: any;
  setSearchValue: any;
  handleSearchClose: () => void;
  refreshdata: () => void;
  candidate_name?: any;
};

const sidebar = sessionStorage.getItem("EmpToggle");
const size = sidebar === "1"

const MyDataBaseSearchAction = ({
  jobTitle,
  formik,
  isSearchValue,
  setSearchValue,
  handleSearchClose,
  refreshdata,
  candidate_name }: Props) => {
  const selectInputRef = useRef<any>();

  const hanldeSearch = () => {  
    formik.setFieldValue('searchValue', isSearchValue); 
  }


  const customFilter = (option: { label: string }, inputValue: string) => {
    const result = option.label
      .toLocaleLowerCase()
      .includes(inputValue.toLocaleLowerCase());
    return result;
  };

  const getOptionLabel = (option: { job_title: string }) =>
    ` ${toCamelCase(option.job_title)}`;
  const getOptionValue = (option: { id: any }) => option.id;

  const getValue = jobTitle
    ? jobTitle.find(
      (option) =>
        Number(option.id) === Number(formik.values.jobTitle),
    )
    : ''

  // onkeypress function
  const handleKeyPressfunction = (e) => {
    if (e.key === 'Enter') {
      hanldeSearch(); 
      e.target.blur(); 
    }
  };

  const {
    candidate_available,
    current_resume_count,
    status,
  } = useSelector(
    ({ myDataBaseInitalReducers, SubscriptionReducers, zitaMatchprogressReducers }: RootState) => {
      return {
        candidate_available: myDataBaseInitalReducers.candidate_available,
        current_resume_count: SubscriptionReducers.current_resume_count,
        status: zitaMatchprogressReducers.status,
      };
    },
  );
  const dispatch: AppDispatch = useDispatch();
  const refreshloaderoff = () => {
    const jdidFromLocalStorage = localStorage.getItem('jdid');
    // if(localStorage.getItem('jdid')){
    const jdIdAsInt = parseInt(jdidFromLocalStorage, 10);
    dispatch(zitaMatchProgressMiddleWare({
      jd_id: formik?.values?.jobTitle
    })).then((res) => {
      if (res.payload.status === false) {
        localStorage.setItem('databaseloader', 'false');
        localStorage.removeItem('jdid')
      }
    })
    refreshdata();
    //}
  }
  const myRef = useRef<any>();
  // updating the name and email of candidate in the input field.
  const handlelocationupdate = (event) => {
    if (event !== '') {
      if (event.target.innerText) {
        setSearchValue(event.target.innerText)
      }
      else {
        setSearchValue(event.target.value)
      }
    }
  }
  return (
    <Flex row between marginBottom={15} className={styles.screenrow}>
      <Flex
        row
        style={{ position: 'relative', overFlowX: 'auto' }}
        className={styles.searchbox}
      >
        <Flex row className={styles.searchstyle}>
          <Flex row center middle className={styles.jobstext}>
            <Flex marginBottom={1}><SvgJobTitleIcon width={20} height={20} fill={'#581845'} /></Flex>
          </Flex>
          <Flex row className={styles.searchboxoverall}>
            <Flex className={styles.boxstyle} >
              <SelectTag
                value={
                  typeof getValue === 'undefined' ? '' : getValue
                }
                stylechangess
                isSearchable={true}
                ref={selectInputRef}
                options={jobTitle}
                placeholder="Select job to find match & invite profiles"
                getOptionLabel={getOptionLabel}
                getOptionValue={getOptionValue}
                onChange={(options) => {
                  formik.setFieldValue('jobTitle', options.id.toString());
                  dispatch(zitaMatchProgressMiddleWare({
                    jd_id: options.id
                  }))
                }}
                noOptionsMessage={({ }) => 'You have no posted jobs to display.'}
                filterOption={customFilter}
              />
            </Flex>
            <Flex className={styles.middleline}></Flex>
            <Flex className={styles.locationicon}>
              <SvgJobselection width={18} height={18} />
            </Flex>
            <InputSearch
              initialValue={isSearchValue}
              placeholder="Search candidate by name or email"
              options={candidate_name}
              CrossiconSpace
              setFieldValue={(e) => handlelocationupdate(e)}
              style={styles.boxstyle1}
              onkeyPress={handleKeyPressfunction}
              onChange={(e) => handlelocationupdate(e)}
            />
            <Flex className={styles.searchicons}>
              <Button
                types="link"
                onClick={hanldeSearch}
                onKeyDown={() => { }}
              >
                <div style={{ marginTop: '-22px' }}>
                  <SvgSearch width={12} height={12} fill="#ffffff" />
                </div>
              </Button>
              {isSearchValue.trim() !== '' && (
                <button
                  className={styles.crossIcon}
                  onClick={handleSearchClose}
                >
                  <SvgIntomark width={14} height={14} fill="#888888" />
                </button>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Flex row center className={styles.infiStyle}>
        <Flex row center>
          {localStorage.getItem('databaseloader') === 'true' ? (<Flex row center >

            <Flex className={styles.updateBtnLoader}>
              <Loader size="small" withOutOverlay />
            </Flex>
            <Text className={styles.process}>
              AI Matching in progress...
            </Text>
            <Flex onClick={refreshloaderoff} marginLeft={5}>
              <SvgRefresh width={22} height={22} />
            </Flex>
          </Flex>
          ) : ('')}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default MyDataBaseSearchAction; 