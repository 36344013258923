import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { AppDispatch, RootState } from '../../store';
import Card from '../../uikit/Card/Card';
import Flex from '../../uikit/Flex/Flex';
import { isEmpty } from '../../uikit/helper';
import Loader from '../../uikit/Loader/Loader';
import StepProgressBar from '../../uikit/StepProgressBar/StepProgressBar';
import Text from '../../uikit/Text/Text';
import useUnsavedChangesWarning from '../common/useUnsavedChangesWarning';
import { THIS_FIELD_REQUIRED } from '../constValue';
import SubscriptionModal from '../subscriptionmodule/subscriptionmoduleScreen';
import MyLottieComponent from '../../loader/Loader';
import loader from '../../actions/ImportJD.json';
import Tour from '../tour/tour';
import Highlighter from '../tour/highlighter';
import { tourdataget } from '../tour/store/middleware/middleware';
import { CountryEntity } from './createJdTypes';
import styles from './createjdwithds.module.css';
import type { dsFormProps } from './formikTypes';
import {
  handleNonDsValidateForm,
  jdNonDsSubmit,
  jdNonDsDraftSubmit,
} from './jdHelpers';
import JobDetails from './JobDetails';
import JobTitleDescription from './JobTitleDescription';
import NonDsSkill from './NonDsSkill';
import {
  createJdMiddleWare,
  duplicateMiddleWare,
  jdTemplatesApiMiddleWare,
  locationMiddleWare,
  industryType,
} from './store/middleware/createjdmiddleware';
import UploadJd from './UploadJd';
import JobDescriptionTemplate from './JobDescriptionTemplate';
import JdParserLoader from './JdParserLoader';

type ParamsType = {
  jdId: string;
  editJdId: string;
};
const CreateJdWithNonDs = () => {
  const { jdId, editJdId } = useParams<ParamsType>();
  const history = useHistory();
  const dispatch: AppDispatch = useDispatch();
  const [isGetCountry, setCountry] = useState<CountryEntity[]>([]);
  const [isTemp, setTemp] = useState(false);
  const [isVacancies, setVacancies] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
  const [isDrftLoader, setDrftLoader] = useState(false);
  const [isNextLoader, setNextLoader] = useState(false);
  const [isDraftSave, setDraftSave] = useState(false);
  const { routerPrompt, onDirty, onPristine } = useUnsavedChangesWarning();
  const [jd_postcount, setjd_postcount] = useState(0);
  const [apiCalled, setApiCalled] = useState(false);
  const [isopensubcription, setopensubcription] = useState(false);
  document.title = 'Post job';
  // initial api call
  useEffect(() => {
    if (!isEmpty(jdId)) {
      dispatch(duplicateMiddleWare({ jd_id: jdId }));
    }
    if (!isEmpty(editJdId)) {
      dispatch(duplicateMiddleWare({ jd_id: editJdId }));
    }
    dispatch(createJdMiddleWare());
    dispatch(industryType());
    dispatch(locationMiddleWare({}));
    dispatch(jdTemplatesApiMiddleWare({ ds_role: '0', user: '2' }));
  }, []);

  const {
    job_title,
    job_description,
    jdParserLoader,
    createJdLoader,
    getCountry,
    jdTemplates,
    jd_output,
    jdDuplicateLoader,
    updateLocation,
    updateQualification,
    is_taken,
    skill_list,
    skills,
    database_skills,
    programming_skills,
    tool_skills,
    misc_skills,
    platform_skills,
    is_plan,
    data,
    reparse,
    nice_to_have,
    current_jd_count,
    Permission1,
    super_user,
    status,
    explore,
    user,
    pipelineData,
  } = useSelector(
    ({
      jdParserReducers,
      createJdReducers,
      locationReducers,
      jdTemplatesReducers,
      duplicateReducers,
      validateJobIDReducers,
      permissionReducers,
      getindustery,
      SubscriptionReducers,
      TourdataReducer,
      userProfileReducers,
      pipelinePageReducers,
    }: RootState) => {
      return {
        job_title: jdParserReducers.job_title,
        jdParserLoader: jdParserReducers.isLoading,
        job_description: jdParserReducers.job_description,
        createJdLoader: createJdReducers.isLoading,
        getCountry: locationReducers.country,
        temTitle: jdTemplatesReducers.job_title,
        jdTemplates: jdTemplatesReducers.jd_templates,
        jd_output: duplicateReducers.jd_output,
        jdDuplicateLoader: duplicateReducers.isLoading,
        updateLocation: duplicateReducers.location,
        updateQualification: duplicateReducers.qualification,
        skills: duplicateReducers.skills,
        is_taken: validateJobIDReducers.is_taken,
        skill_list: createJdReducers.skill_list,
        programming_skills: jdParserReducers.programming_skills,
        tool_skills: jdParserReducers.tool_skills,
        database_skills: jdParserReducers.database_skills,
        misc_skills: jdParserReducers.misc_skills,
        platform_skills: jdParserReducers.platform_skills,
        is_plan: permissionReducers.is_plan,
        data: getindustery.data,
        reparse: duplicateReducers.reparse,
        nice_to_have: duplicateReducers.nice_to_have,
        current_jd_count: SubscriptionReducers.current_jd_count,
        Permission1: permissionReducers.Permission,
        super_user: permissionReducers.super_user,
        explore: TourdataReducer.is_first_login,
        status: TourdataReducer.is_steps,
        user: userProfileReducers.user,
        pipelineData: pipelinePageReducers.pipeline,
      };
    },
  );
  useEffect(() => {
    if (!is_plan) {
      history.push('/account_setting/subscription');
    }
  });

  useEffect(() => {
    if ((current_jd_count === 0)&&(!status)) {
      if (editJdId === undefined && jdId === undefined) {
        history.push('/job_list');
      }
    }
  }, [current_jd_count]);

  useEffect(() => {
    if (!isEmpty(editJdId)) {
      setjd_postcount(reparse);
    } else {
      setjd_postcount(0);
    }
  }, [reparse]);

  const combinedArray = [
    ...(Array.isArray(database_skills) ? database_skills : []),
    ...(Array.isArray(programming_skills) ? programming_skills : []),
    ...(Array.isArray(tool_skills) ? tool_skills : []),
    ...(Array.isArray(misc_skills) ? misc_skills : []),
    ...(Array.isArray(platform_skills) ? platform_skills : []),
  ];

  const jdParseSkill = combinedArray.map((list: any) => {
    return { value: list.skill, label: list.skill };
  });
  const jdParseSkillEmptyCheck = jdParseSkill.filter(
    (x) => x.value !== undefined,
  );

  useEffect(() => {
    if (getCountry && getCountry.length !== 0) {
      setCountry(getCountry);
    }
  }, [getCountry]);

  const initial: dsFormProps = {
    qualification: [{ education: '', specialization: '' }],
    jobTitle: '',
    jobRole: '6',
    jobId: '',
    jobDescription: '',
    jobType: '',
    minimumExperience: '',
    maximumExperience: '',
    vacancies: '',
    country: '',
    state: '',
    city: '',

    skills: [],
    remoteWork: '0',
    minimumSalary: '',
    maximumSalary: '',
    currency: '',
    showSalaryCandidates: '0',
    industryType: ' ',
    IndustryType: '',
    work_space_type: '',
    nonDsSkill: [],
    skillData: {
      dataBaseTags: [],
      toolsTags: [],
      platformsTags: [],
      othersTags: [],
      programTags: [],
    },
    onsite: '',
    skillerror: '',
    hybrid: '',
    nicetohave: [],
  };

  // open template function
  const handleTempOpen = () => {
    setTemp(true);
  };
  const [step4, setstep4] = useState(false);
  const [currentindex, setcurrentindex] = useState(0);
  useEffect(() => {
    dispatch(tourdataget({}));
    if (status) {
      setstep4(true);
    }
  }, [status]);

  return (
    <>
      {status && (
        <Highlighter left={'38px'} top={'173px'} text={'Post New Job'} />
      )}
      {step4 && (
        <Tour
          process={() => {
            if (
              Permission1.includes('manage_account_settings') &&
              Permission1.includes('create_post') &&
              Permission1.includes('bulkImport_candidates') &&
              Permission1.includes('talent_sourcing')
            ) {
              return '4/9';
            } else if (
              Permission1.includes('manage_account_settings') &&
              Permission1.includes('create_post') &&
              Permission1.includes('bulkImport_candidates')
            ) {
              return '4/8';
            } else if (
              Permission1.includes('manage_account_settings') &&
              Permission1.includes('create_post') &&
              Permission1.includes('talent_sourcing')
            ) {
              return '4/7';
            } else if (
              Permission1.includes('manage_account_settings') &&
              Permission1.includes('bulkImport_candidates') &&
              Permission1.includes('talent_sourcing')
            ) {
              return '4/8';
            } else if (
              Permission1.includes('create_post') &&
              Permission1.includes('bulkImport_candidates') &&
              Permission1.includes('talent_sourcing')
            ) {
              return '3/8';
            } else if (
              Permission1.includes('manage_account_settings') &&
              Permission1.includes('create_post')
            ) {
              return '4/6';
            } else if (
              Permission1.includes('manage_account_settings') &&
              Permission1.includes('bulkImport_candidates')
            ) {
              return '4/8';
            } else if (
              Permission1.includes('manage_account_settings') &&
              Permission1.includes('talent_sourcing')
            ) {
              return '4/7';
            } else if (
              Permission1.includes('create_post') &&
              Permission1.includes('bulkImport_candidates')
            ) {
              return '3/7';
            } else if (
              Permission1.includes('create_post') &&
              Permission1.includes('talent_sourcing')
            ) {
              return '3/6';
            } else if (
              Permission1.includes('bulkImport_candidates') &&
              Permission1.includes('talent_sourcing')
            ) {
              return '3/8';
            } else if (Permission1.includes('manage_account_settings')) {
              return '4/6';
            } else if (Permission1.includes('create_post')) {
              return '3/5';
            } else if (Permission1.includes('bulkImport_candidates')) {
              return '2/7';
            } else if (Permission1.includes('talent_sourcing')) {
              return '3/6';
            } else {
              return '3/5';
            }

            return null;
          }}
          skiponclick={() => {
            setstep4(false);
            setcurrentindex(4);
            sessionStorage.setItem('JobsDropdown', '0');
            sessionStorage.setItem('ViewJobsDropdown', '0');
            sessionStorage.setItem('CandidateDropdown', '0');
            sessionStorage.setItem('CommunicationDropdown', '0');
            sessionStorage.setItem('BrandDropdown', '0');
            sessionStorage.setItem('MyAccountDropdown', '1');
            sessionStorage.setItem('SourcingCandidateDropdown', '0');
            dispatch(
              tourdataget({ skip_id: 4, restart_status: false, explore: 0 }),
            ).then((res) => {
              history.push('/account_setting/profiles');
            });
          }}
          previousonclick={() => {
            if (Permission1.includes('manage_account_settings')) {
              sessionStorage.setItem('JobsDropdown', '0');
              sessionStorage.setItem('ViewJobsDropdown', '0');
              sessionStorage.setItem('CandidateDropdown', '0');
              sessionStorage.setItem('CommunicationDropdown', '0');
              sessionStorage.setItem('BrandDropdown', '1');
              sessionStorage.setItem('MyAccountDropdown', '0');
              sessionStorage.setItem('SourcingCandidateDropdown', '0');
              history.push('/account_setting/build_careers_page');
            } else {
              sessionStorage.setItem('JobsDropdown', '0');
              sessionStorage.setItem('ViewJobsDropdown', '0');
              sessionStorage.setItem('CandidateDropdown', '0');
              sessionStorage.setItem('CommunicationDropdown', '0');
              sessionStorage.setItem('BrandDropdown', '0');
              sessionStorage.setItem('MyAccountDropdown', '1');
              sessionStorage.setItem('SourcingCandidateDropdown', '0');
              history.push('/account_setting/profiles');
            }
            setstep4(false);
          }}
          nextonclick={() => {
            setstep4(false);
            if (Permission1.includes('bulkImport_candidates')) {
              sessionStorage.setItem('JobsDropdown', '0');
              sessionStorage.setItem('ViewJobsDropdown', '0');
              sessionStorage.setItem('CandidateDropdown', '1');
              sessionStorage.setItem('CommunicationDropdown', '0');
              sessionStorage.setItem('BrandDropdown', '0');
              sessionStorage.setItem('MyAccountDropdown', '0');
              sessionStorage.setItem('SourcingCandidateDropdown', '0');
              history.push('/bulk_import');
            } else if (Permission1.includes('talent_sourcing')) {
              sessionStorage.setItem('JobsDropdown', '0');
              sessionStorage.setItem('ViewJobsDropdown', '0');
              sessionStorage.setItem('CandidateDropdown', '0');
              sessionStorage.setItem('CommunicationDropdown', '0');
              sessionStorage.setItem('BrandDropdown', '0');
              sessionStorage.setItem('MyAccountDropdown', '0');
              sessionStorage.setItem('SourcingCandidateDropdown', '1');
              history.push('/source_candidates');
            } else {
              if (super_user) {
                sessionStorage.setItem('JobsDropdown', '1');
                sessionStorage.setItem('ViewJobsDropdown', '0');
                sessionStorage.setItem('CandidateDropdown', '0');
                sessionStorage.setItem('CommunicationDropdown', '0');
                sessionStorage.setItem('BrandDropdown', '0');
                sessionStorage.setItem('MyAccountDropdown', '0');
                sessionStorage.setItem('SourcingCandidateDropdown', '0');
                sessionStorage.setItem('template', '1');
                sessionStorage.setItem('pipeline', '1');
                sessionStorage.setItem(
                  'wk_id',
                  pipelineData[0]?.wk_id.toString(),
                );
                history.push('/account_setting/hiring_workflow');
              } else {
                if (Permission1.includes('manage_account_settings')) {
                  sessionStorage.setItem('JobsDropdown', '1');
                  sessionStorage.setItem('ViewJobsDropdown', '0');
                  sessionStorage.setItem('CandidateDropdown', '0');
                  sessionStorage.setItem('CommunicationDropdown', '0');
                  sessionStorage.setItem('BrandDropdown', '0');
                  sessionStorage.setItem('MyAccountDropdown', '0');
                  sessionStorage.setItem('SourcingCandidateDropdown', '0');
                  sessionStorage.setItem('template', '1');
                  sessionStorage.setItem('pipeline', '1');
                  sessionStorage.setItem(
                    'wk_id',
                    pipelineData[0]?.wk_id.toString(),
                  );
                  history.push('/account_setting/hiring_workflow');
                } else {
                  sessionStorage.setItem('JobsDropdown', '1');
                  sessionStorage.setItem('ViewJobsDropdown', '0');
                  sessionStorage.setItem('CandidateDropdown', '0');
                  sessionStorage.setItem('CommunicationDropdown', '0');
                  sessionStorage.setItem('BrandDropdown', '0');
                  sessionStorage.setItem('MyAccountDropdown', '0');
                  sessionStorage.setItem('SourcingCandidateDropdown', '0');
                  sessionStorage.setItem('template', '1');
                  sessionStorage.setItem('pipeline', '1');
                  sessionStorage.setItem(
                    'wk_id',
                    pipelineData[0]?.wk_id.toString(),
                  );
                  history.push('/account_setting/hiring_workflow');
                }
              }
            }
          }}
          borderTop
          previous
          types={'arrowLeftTop'}
          top={'155px'}
          left={'205px'}
          // text={"Let's direct our focus to 'Jobs'. Here, you'd craft detailed job postings to attract the perfect candidates. Consider the role’s responsibilities, required skills, and how you'll convey the opportunity to entice top talent. Each listing is a new chapter waiting to be filled with the right protagonist."}
          text={
            '<div style="font-weight: bold; margin-bottom: 15px;">Let\'s direct our focus to \'Jobs\'.</div> Here, you\'d craft detailed job postings to attract the perfect candidates.<div style="margin-top:10px;">Consider the <div style="display:contents;font-weight: bold;">role’s responsibilities, required skills,</div> and how you\'ll convey the opportunity to entice top talent.</div>'
          }
        />
      )}
      <Flex row center className={styles.step}>
        <StepProgressBar roundFill />
        <StepProgressBar
          title="Weightage Matching"
          titleclassName={styles.stepTwo}
          stepIndex="2"
        />
        <StepProgressBar
          title="Applicant Questionnaire"
          titleclassName={styles.stepThree}
          stepIndex="3"
        />
        <StepProgressBar
          title="Preview & Post Job"
          titleclassName={styles.stepFour}
          stepIndex="4"
        />
      </Flex>
      <Flex columnFlex className={styles.overAll} height={window.innerHeight}>
        {(createJdLoader || jdDuplicateLoader) && <Loader />}
        {jdParserLoader && (
          <MyLottieComponent data={loader} width={450} height={320} />
        )}

        <Formik
          initialValues={initial}
          onSubmit={(values) =>
            isDraftSave
              ? jdNonDsDraftSubmit({
                  values,
                  dispatch,
                  editJdId,
                  generate_ai: jd_postcount,
                })
              : jdNonDsSubmit({
                  values,
                  dispatch,
                  editJdId,
                  generate_ai: jd_postcount,
                })
          }
          validate={(values) => handleNonDsValidateForm(values, is_taken)}
          validationSchema={Yup.object({
            jobDescription: Yup.string().required(THIS_FIELD_REQUIRED),
            qualification: Yup.array().of(
              Yup.object().shape({
                education: Yup.string().required(THIS_FIELD_REQUIRED),
                specialization: Yup.string().max(50, ''),
              }),
            ),
            vacancies: isVacancies
              ? Yup.string().required(THIS_FIELD_REQUIRED)
              : Yup.string(),
          })}
        >
          {({
            values,
            handleChange,
            setFieldValue,
            handleSubmit,
            errors,
            touched,
          }) => (
            <Form>
              <UploadJd
                handleTempOpen={handleTempOpen}
                values={values}
                onDirty={onDirty}
                setFieldValue={setFieldValue}
                getCountry={isGetCountry}
                jd_postcount={jd_postcount}
                setjd_postcount={setjd_postcount}
                jd_id={editJdId}
                skill_list={skill_list}
                setApiCalled={setApiCalled}
              />
              <Flex className={styles.cardOverAll}>
                <JobDescriptionTemplate
                  jdTemplates={jdTemplates}
                  open={isTemp}
                  close={() => setTemp(false)}
                  setFieldValue={setFieldValue}
                />
                <JobTitleDescription
                  onPristine={onPristine}
                  is_taken={is_taken}
                  values={values}
                  setFieldValue={setFieldValue}
                  job_title={job_title}
                  job_description={job_description}
                  errors={errors}
                  touched={touched}
                  handleTempOpen={handleTempOpen}
                  jd_output={jd_output}
                  isNonDs
                  jd_id={editJdId}
                  updateJd={jdId}
                  formik={Formik}
                  onDirty={onDirty}
                  view={true}
                />
              </Flex>
              <JobDetails
                values={values}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
                getCountry={isGetCountry}
                jd_output={jd_output}
                isDrftLoader={isDrftLoader}
                updateLocation={updateLocation}
                updateQualification={updateQualification}
                handleSubmit={handleSubmit}
                setNextLoader={setNextLoader}
                isNextLoader={isNextLoader}
                setVacancies={setVacancies}
                setDrftLoader={setDrftLoader}
                errors={errors}
                touched={touched}
                setDraftSave={setDraftSave}
                onPristine={onPristine}
                onDirty={onDirty}
                is_taken={is_taken}
                view={true}
              />
              {/*Based on the new suggestion, the job description is set at the bottom of the page so that JobTitleDescription can be re-used. */}

              <Flex center className={styles.jobTitleFlex}>
                <Text size={14} bold className={styles.jobTitle}>
                  Job Description
                </Text>
              </Flex>
              <Flex marginTop={10}>
                <NonDsSkill
                  setFieldValue={setFieldValue}
                  skill_list={skill_list}
                  values={values}
                  errors={errors}
                  touched={touched}
                  skills={skills}
                  jdParseSkill={jdParseSkillEmptyCheck}
                  job_description={job_description}
                  onDirty={onDirty}
                  setApiCalled={setApiCalled}
                  apiCalled={apiCalled}
                  nice_to_have={nice_to_have}
                  onPristine={onPristine}
                />
              </Flex>

              <JobTitleDescription
                onPristine={onPristine}
                is_taken={is_taken}
                values={values}
                setFieldValue={setFieldValue}
                job_title={job_title}
                job_description={job_description}
                errors={errors}
                touched={touched}
                handleTempOpen={handleTempOpen}
                jd_output={jd_output}
                isNonDs
                jd_id={editJdId}
                updateJd={jdId}
                formik={Formik}
                onDirty={onDirty}
                view={false}
              />

              {/*Based on the new suggestion, the job description is set at the bottom of the page so that JobDetails can be re-used. */}
              <JobDetails
                values={values}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
                getCountry={isGetCountry}
                jd_output={jd_output}
                isDrftLoader={isDrftLoader}
                updateLocation={updateLocation}
                updateQualification={updateQualification}
                handleSubmit={handleSubmit}
                setNextLoader={setNextLoader}
                isNextLoader={isNextLoader}
                setVacancies={setVacancies}
                setDrftLoader={setDrftLoader}
                errors={errors}
                touched={touched}
                setDraftSave={setDraftSave}
                onPristine={onPristine}
                onDirty={onDirty}
                is_taken={is_taken}
                view={false}
                routerPrompt={routerPrompt}
              />
            </Form>
          )}
        </Formik>
      </Flex>
      {routerPrompt}
    </>
  );
};
export default CreateJdWithNonDs;
