export const routesPath = {
  TALENT_SOURCING: '/source_candidates',
  BULK_IMPORT: '/bulk_import',
  MYDATABASE: '/all_candidates',
  JOBS: '/jobs',
  MY_JOB_POSTING: '/job_list',
  REPORTS: '/reports',
  CALENDAR: '/calendar',
  MAIL: '/mail',  
};
