import Card from '../../uikit/Card/Card';
import Flex from '../../uikit/Flex/Flex';
import { isEmpty, toCamelCase } from '../../uikit/helper';
import SvgLocation from '../../icons/SvgLocation';
import Text from '../../uikit/Text/Text';
import SvgJobTitles from '../../icons/SvgJobTitles';
import styles from './jobtitlecard.module.css';
import { JobDetails } from './zitaMatchCandidateTypes';

type TitleProps = {
  title: string;
  value: string;
  
};

const TitleLabel = ({ title, value }: TitleProps) => {
  return (
    <Flex row center className={styles.labelContainer}>
    {/* <Text color="theme">{title}</Text> */}
    <Text className={styles.valueText}>{value}</Text>
  </Flex>
  );
};
type Props = {
  job_details: JobDetails;
};
const JobTitleCard = ({ job_details }: Props) => {
  return (
      <Flex row center marginTop={3}> 
      <div style={{display:"flex" }}>
        <SvgJobTitles />
      {/* </div> */}
      <TitleLabel
        title="Job Title:"
        value={`${toCamelCase(job_details.job_title)} - ${job_details.job_id}`}
      /></div>
      <div style={{display:"flex", alignItems: 'center'}}>
        <SvgLocation width={16} height={16} fill="#581845" />
      
      {/* <TitleLabel
        title="Location:"
        // value={`${job_details.city}, ${job_details.state}, ${job_details.country} `}
        value={job_details.city === null && job_details.state === null && job_details.country === null ? "Remote" : (`${job_details.city}, ${job_details.state}, ${job_details.country} `)}
      /> */}

{job_details.city === null && job_details.state === null && job_details.country === null && job_details.work_space_type === '3' ? (
        <TitleLabel
        title="Location:"
        value='Remote'
      />
      ):(
      <>
      {job_details.city !== null && job_details.state !== null && job_details.country !== null && job_details.work_space_type === '3' ? (
        <TitleLabel
        title="Location:"
        value={`${job_details.city}, ${job_details.state}, ${job_details.country} (Remote)`} 
      />
      ):(
        <TitleLabel
        title="Location:"
        value={`${job_details.city}, ${job_details.state}, ${job_details.country}`} 
      />
      )}
      </>
      )}
      </div>
    </Flex>
    
  );
};

export default JobTitleCard;
