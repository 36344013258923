import { ReactNode } from 'react';
import SvgInfo from '../../icons/SvgInfo';
import Button from '../../uikit/Button/Button';
import Flex from '../../uikit/Flex/Flex';
import Loader from '../../uikit/Loader/Loader';
import Modal from '../../uikit/Modal/Modal';
import Text from '../../uikit/Text/Text';
import styles from './parsingloadingmodal.module.css';

type Props = {
  close: (a: any) => void;
  open: boolean;
  des: ReactNode;
  title: string;
  loader?: boolean;
  info?: boolean;
  css?: boolean;
  jd_postcount?: number;
  isFileUpload?: any;
};

const ParsingLoadingModal = ({
  close,
  open,
  title,
  des,
  loader,
  info,
  css,
  jd_postcount,
  isFileUpload,
}: Props) => {
  return (
    <Modal open={open}>
      <Flex className={styles.overAll}>
        <Flex column>
          <Flex row center>
            {loader && <Loader withOutOverlay size={'small'} />}

            <Text
              className={css ? null : styles.flexConatiner}
              type="titleMedium"
            >
              {title}
            </Text>
          </Flex>
          <Flex className={styles.des}>{des}</Flex>
          {jd_postcount !== undefined && !isFileUpload ? (
            <>
              {jd_postcount === 3 ? (
                ''
              ) : (
                <Flex marginTop={15} row>
                  <Flex style={{ padding: '3px 0px 0px 3px' }}>
                    <SvgInfo height={14} width={14} fill={'#2E6ADD'} />
                  </Flex>
                  <Text style={{ marginLeft: '10px' }}>
                    You have {3 - jd_postcount} more generation remaining for this job description refinement process.
                  </Text>
                </Flex>
              )}
            </>
          ) : (
            ''
          )}
        </Flex>

        <Flex row center middle marginTop={10}>
          {/* {loader ?(  
        <Flex className={styles.updateBtnLoader}>
          <Loader withOutOverlay size={'small'} />
        </Flex>):(
          <Button onClick={close} className={styles.btn}>
            OK
          </Button>)} */}
          <Button onClick={close} className={styles.btn}>
            OK
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};

export default ParsingLoadingModal;
