import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import SvgRoundClose from '../../icons/SvgRoundClose';
import { AppDispatch, RootState } from '../../store';
import Button from '../../uikit/Button/Button';
import Card from '../../uikit/Card/Card';
import SvgClose from '../../icons/SvgClose';

import {
  ErrorMessage,
  InputSearch,
  InputText,
  LabelWrapper,
  Loader,
  Modal,
  SelectTag,
  Toast,
} from '../../uikit';
import { getFocus, isEmpty } from '../../uikit/helper';
import { GARY_4 } from '../../uikit/Colors/colors';
import Flex from '../../uikit/Flex/Flex';
import Text from '../../uikit/Text/Text';
import SvgDoublestar from '../../icons/Svgdoublestar';
import { fileAccept, FILE_2MB, THIS_FIELD_REQUIRED, MIN_MAX_EXP } from '../constValue';
import ParsingLoadingModal from '../bulkimportmodule/ParsingLoadingModal';
import MenuLists from '../common/MenuList';
import MyLottieComponent from '../../loader/Loader';
import robort from '../../actions/GenerateJDAI.json';
import Required from '../../uikit/Required/required';
import {
  jdParserMiddleWare,
  locationCityMiddleWare,
  locationStateMiddleWare,
  AioutputApimiddleware,
} from './store/middleware/createjdmiddleware';
import styles from './uploadjd.module.css';
import { workspacetype } from './mock';
import {
  CityEntity,
  CountryEntity,
  JdOutput,
  LocationUpdate,
  QualificationEntity,
  StatesEntity,
} from './createJdTypes';
import JdrestrictModal from './jdrestrictModal';

type Props = {
  handleTempOpen?: any;
  values: any;
  onDirty: any;
  setFieldValue: any;
  getCountry: any;
  jd_postcount: number;
  setjd_postcount: (val: number) => void;
  jd_id: any;
  skill_list: any;
  setApiCalled: any;
};
const UploadJd = ({
  handleTempOpen,
  values,
  onDirty,
  setFieldValue,
  getCountry,
  jd_postcount,
  setjd_postcount,
  jd_id,
  skill_list,
  setApiCalled,
}) => {
  const [file, setFile] = useState<any>([]);
  const [isMb, setMb] = useState(false);
  const [modal, setmodal] = useState(false);
  const [openmodel, setopenmodel] = useState(false);
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [isSelectCurrOpen, setIsSelectCurrOpen] = useState(false);
  const dispatch: AppDispatch = useDispatch();
  const [getState, setState] = useState<StatesEntity[]>([]);
  const [getCity, setCity] = useState<CityEntity[]>([]);
  const [isSubmitLoader, setSubmitLoader] = useState(false);
  const [uploaderror, setuploaderror] = useState('');
  const [popup, setpopup] = useState(false);
  const [verifymodel, setverifymodel] = useState(false);
  const [isFileUpload, setFileUpload] = useState (false);
  const handleSubmit1 = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if (file.length !== 0) {
      uploadFile(file);
      setopenmodel(false);
      setmodal(false);
    } else {
      setuploaderror(' This field is required.');
    }
    setFileUpload(true);
  };

  const handlecancel = () => {
    setmodal(false);
    setFile([]);
    setuploaderror('');
  };
  // resume clear function
  const handleClear = () => setFile([]);

  // resume upload function
  const uploadFile = (files: any) => {
    const formData = new FormData();
    formData.append('jd_file', files);
    return dispatch(jdParserMiddleWare({ upload: formData })).then((res) => {
      if (res.payload.success === true) {
        //setjd_postcount(jd_postcount + 1);
        handleClear();
        setpopup(true);
        setApiCalled(true);
      } else if (res.payload.success === false) {
        handleClear();
        Toast('Oops! Something went wrong. Please try again shortly.', 'LONG', 'error');
      }
    });
  };

  const handleOnChange = (e: any) => {
    var fileExt = e.target.value;
    fileExt = fileExt.substring(fileExt.lastIndexOf('.'));
    if (fileAccept.indexOf(fileExt) < 0) {
      alert(
        'Invalid file selected, valid files are of ' +
        fileAccept.toString() +
        ' types.',
      );
    } else if (e.target.files && e.target.files[0].size / 1024 / 1024 > 2) {
      setMb(true);
    } else {
      setFile(e.target.files[0]);
      setMb(false);
      setuploaderror('');
      onDirty();
    }
  };

  const dragOver = (e: { preventDefault: () => void }) => {
    e.preventDefault();
  };

  const dragEnter = (e: { preventDefault: () => void }) => {
    e.preventDefault();
  };

  const dragLeave = (e: { preventDefault: () => void }) => {
    e.preventDefault();
  };
  useEffect(() => {
    if (file.length !== 0) {
      formik.resetForm();
    }
  }, [file]);
  // File drag and drop Function
  const fileDrop = (e: {
    preventDefault: () => void;
    dataTransfer: { files: any };
  }) => {
    e.preventDefault();
    var fileName = e.dataTransfer.files && e.dataTransfer.files[0].name;
    fileName = fileName.substring(fileName.lastIndexOf('.'));
    if (fileAccept.indexOf(fileName) < 0) {
      alert(
        'Invalid file selected, valid files are of ' +
        fileAccept.toString() +
        ' types.',
      );
    } else if (
      e.dataTransfer.files[0] &&
      e.dataTransfer.files[0].size / 1024 / 1024 > 2
    ) {
      setMb(true);
    } else {
      setMb(false);
      setFile(e.dataTransfer.files[0]);
      setuploaderror('');
    }
  };

  const checkSelectLength = file.length === 0 ? false : true;

  const initialValues: MyFormValues = {
    work_space_type1: '',
    jobTitle1: '',
    industryType1: '',
    country1: '',
    city1: '',
    state1: '',
    Overview: '',
    Department_and_reporting: '',
    maxexp: '',
    minexp: '',
    mandatoryskill: [],
    nicetohave: [],
  };

  interface MyFormValues {
    work_space_type1: string;
    jobTitle1: string;
    industryType1: string;
    country1: string;
    city1: string;
    state1: string;
    Overview: string;
    Department_and_reporting: string;
    maxexp: string;
    minexp: string;
    mandatoryskill: { value: any; label: any }[];
    nicetohave: { value: any; label: any }[];
  }
  interface FormErrors {
    work_space_type1?: string;
    jobTitle1?: string;
    industryType1?: string;
    country1?: string;
    city1?: string;
    state1?: string;
    Overview?: string;
    Department_and_reporting?: string;
    maxexp?: string;
    minexp?: string;
    mandatoryskill?: string;
    nicetohave?: string;
  }
  const handleValidation = (formValues: MyFormValues) => {
    const errors: FormErrors = {};
    if (!formValues.jobTitle1.trim()) {
      errors.jobTitle1 = THIS_FIELD_REQUIRED;
    }
    if (!(formValues.industryType1 || '').trim()) {
      errors.industryType1 = THIS_FIELD_REQUIRED;
    }
    if (!formValues.minexp.trim()) {
      errors.minexp = THIS_FIELD_REQUIRED;
    }
    if (!formValues.Overview.trim()) {
      errors.Overview = THIS_FIELD_REQUIRED;
    }
    if (formValues.mandatoryskill.length === 0) {
      errors.mandatoryskill = THIS_FIELD_REQUIRED;
    }
    if(!isEmpty(formik.values.maxexp) &&
    formik.values.maxexp.trim() &&
    Number(formik.values.maxexp) <=
    Number(formik.values.minexp) ){
      errors.maxexp='';
    }
    return errors;
  };

  const handleSubmit = (formValues: MyFormValues, { resetForm }: any) => {
    setFileUpload(false)
    setSubmitLoader(true);

    dispatch(
      AioutputApimiddleware({
        jobTitle: formik.values.jobTitle1,
        Industry_and_Domain: formik.values.industryType1,
        mandatory_skills: skill.toString(),
        Nice_to_Have: nice.toString(),
        Overview_the_Role: formik.values.Overview,
        jd_id: jd_id === undefined || null ? '' : jd_id,
        generate_ai: jd_postcount,
        min_exp: formik.values.minexp,
        max_exp: formik.values.maxexp,
      }),
    ).then((res) => {
      if (res.payload.success === true) {
        setjd_postcount(jd_postcount + 1);

        setSubmitLoader(false);
        setFieldValue('jobDescription', res.payload.job_description);
        setFieldValue('jobTitle', res.payload.job_title);
        if (typeof res.payload?.skills === 'string') {
          const skillsArray = res.payload.skills.split(',');
          const formattedSkills = skillsArray.map((skill) => ({
            label: skill.trim(),
            value: skill.trim(),
          }));
          setFieldValue('nonDsSkill', formattedSkills);
        } else if (
          Array.isArray(res.payload?.skills) &&
          res.payload?.skills.length > 0
        ) {
          if (typeof res.payload.skills[0].skill === 'string') {
            const formattedSkills = res.payload.skills.map((skillObj) => ({
              label: skillObj.skill,
              value: skillObj.skill,
            }));
            setFieldValue('nonDsSkill', formattedSkills);
          } else if (typeof res.payload.skills[0] === 'string') {
            const formattedSkills = res.payload.skills.map((skill) => ({
              label: skill,
              value: skill,
            }));
            setFieldValue('nonDsSkill', formattedSkills);
          }
        }
        setFieldValue(
          'work_space_type',
          res?.payload?.work_space_type?.toString(),
        );
        setFieldValue('industryType', formik.values.industryType1);
        setFieldValue('industryType1', '');
        setFieldValue('work_space_type1', '');
        setFieldValue('country1', '');
        setFieldValue('city1', '');
        setFieldValue('state1', '');
        setFieldValue('Overview', '');
        setFieldValue('minimumExperience', res?.payload?.min_exp);
        setFieldValue('maximumExperience', res?.payload?.max_exp);
        setFieldValue('Department_and_reporting', '');
        Toast('Request Completed Successfully.', 'LONG', 'success');
        if (res && res.payload && res.payload.nice_to_have) {
          // Splitting each item into individual skills and flattening the array
          const splitSkills = res.payload.nice_to_have.flatMap((skill) =>
            skill.split(','),
          );

          // Creating a Set to remove duplicates
          const uniqueSkills = Array.from(new Set(splitSkills));

          // Formatting the unique skills
          const formattedSkill = uniqueSkills.map((skill) => ({
            label: skill,
            value: skill,
          }));

          // Setting the formatted skills to the 'nicetohave' field
          setFieldValue('nicetohave', formattedSkill);
        }

        setpopup(true);
        setSubmitLoader(false);
      } else {
        Toast(
          'Oops! Something went wrong. Please try again shortly.',
          'LONG',
          'error',
        );
      }
    });

    setopenmodel(false);
  };
  const handleReset = () => {
    formik.resetForm();
  };
  const formik = useFormik({
    initialValues: initialValues,
    validate: handleValidation,
    onSubmit: handleSubmit,
    validateOnBlur: true,
    validateOnChange: true,
  });
  const skill = formik.values.mandatoryskill?.map((list: any) => {
    return list.value;
  });
  const nice = formik.values.nicetohave?.map((list: any) => {
    return list.value;
  });
  const correct = formik.values.work_space_type1 === '3';
  const handlefunction2 = () => {
    if (jd_postcount < 3) {
      handleReset();
      setopenmodel(true);
    } else {
      //setverifymodel(true);
      setmodal(true);
    }
  };
  useEffect(() => {
    if (!isEmpty(formik.values.country1)) {
      dispatch(
        locationStateMiddleWare({ country: formik.values.country1 }),
      ).then((res) => {
        if (res.payload.states && res.payload.states.length !== 0) {
          setState(res.payload.states);
        }
      });
    }
  }, [formik.values.country1]);

  useEffect(() => {
    if (!isEmpty(formik.values.state1)) {
      dispatch(locationCityMiddleWare({ state: formik.values.state1 })).then(
        (res) => {
          if (res.payload.city && res.payload.city.length !== 0) {
            setCity(res.payload.city);
          }
        },
      );
    }
  }, [formik.values.state1]);
  const closemodel = () => {
    if (!isFormEmpty(formik.values) && file.length !== undefined) {
      const userResponse = window.confirm(
        'Do you want to leave this site? Changes you made may not be saved.',
      );
      if (userResponse) {
        setopenmodel(false);
        setFile([]);
        setuploaderror('');
      }
    } else {
      setopenmodel(false);
      setFile([]);
      setuploaderror('');
    }
  };
  const close = () => {
    setpopup(false);
  };

  const cancel_module = () => {
    setmodal(false);
    setuploaderror('');
  };

  const { data } = useSelector(({ getindustery }: RootState) => {
    return {
      data: getindustery.data,
    };
  });

  const upload_hide =
    formik.values.jobTitle1 !== '' ||
    formik.values.industryType1 !== '' ||
    formik.values.minexp !== '' ||
    formik.values.mandatoryskill.length !== 0 ||
    formik.values.Overview !== ''
      ? true
      : false;

  const handleChange = useCallback(
    (newValue, datas) => {
      if (formik.values.nicetohave.length !== 0) {
        var check = formik.values.nicetohave.some(
          (obj) => obj.value === datas.option.value,
        );
        if (check === false) {
          if (datas.action === 'select-option') {
            onDirty();
            formik.setFieldValue('mandatoryskill', newValue);
          }
          if (datas.action === 'create-option') {
            onDirty();
            const tagsValue = newValue.slice();
            var tagArr1 = datas.option.value.split(',');
            tagArr1.map((list) => {
              return (
                !list.includes(',') &&
                tagsValue.push({ value: list, label: list })
              );
            });
            const nonSkillFilter = tagsValue.filter(
              (innerData) => !innerData.value.includes(','),
            );
            const nonDsDuplicate =
              nonSkillFilter &&
              nonSkillFilter.filter(
                (value, index, self) =>
                  index ===
                  self.findIndex(
                    (t) =>
                      t.value?.toLocaleLowerCase() ===
                      value.value?.toLocaleLowerCase(),
                  ),
              );
            const jdParseSkillEmptyCheck = nonDsDuplicate.filter(
              (innerData) => innerData.value !== undefined,
            );
            formik.setFieldValue('mandatoryskill', jdParseSkillEmptyCheck);
          }
        } else {
          Toast('This field already exists.', 'LONG', 'error');
        }
      } else {
        if (datas.action === 'select-option') {
          onDirty();
          formik.setFieldValue('mandatoryskill', newValue);
        }
        if (datas.action === 'create-option') {
          onDirty();
          const tagsValue = newValue.slice();
          var tagArr = datas.option.value.split(',');
          tagArr.map((list) => {
            return (
              !list.includes(',') &&
              tagsValue.push({ value: list, label: list })
            );
          });
          const nonSkillFilter = tagsValue.filter(
            (innerData) => !innerData.value.includes(','),
          );
          const nonDsDuplicate =
            nonSkillFilter &&
            nonSkillFilter.filter(
              (value, index, self) =>
                index ===
                self.findIndex(
                  (t) =>
                    t.value?.toLocaleLowerCase() ===
                    value.value?.toLocaleLowerCase(),
                ),
            );
          const jdParseSkillEmptyCheck = nonDsDuplicate.filter(
            (innerData) => innerData.value !== undefined,
          );
          formik.setFieldValue('mandatoryskill', jdParseSkillEmptyCheck);
        }
      }
      if (datas.action === 'remove-value') {
        onDirty();
        formik.setFieldValue('mandatoryskill', newValue);
      }
    },
    [onDirty, setFieldValue, formik.values.nicetohave],
  );
  const handleChangefunction = useCallback(
    (newValue, valdata) => {
      if (formik.values.mandatoryskill.length !== 0) {
        var check = formik.values.mandatoryskill.some(
          (obj) => obj.value === valdata.option.value,
        );
        if (check === false) {
          if (valdata.action === 'select-option') {
            onDirty();
            formik.setFieldValue('nicetohave', newValue);
          }
          if (valdata.action === 'create-option') {
            onDirty();
            const tagsValue = newValue.slice();
            var tagArr = valdata.option.value.split(',');
            tagArr.map((list) => {
              return (
                !list.includes(',') &&
                tagsValue.push({ value: list, label: list })
              );
            });
            const nonSkillFilter = tagsValue.filter(
              (innerData) => !innerData.value.includes(','),
            );
            const nonDsDuplicate =
              nonSkillFilter &&
              nonSkillFilter.filter(
                (value, index, self) =>
                  index ===
                  self.findIndex(
                    (t) =>
                      t.value?.toLocaleLowerCase() ===
                      value.value?.toLocaleLowerCase(),
                  ),
              );
            const jdParseSkillEmptyCheck = nonDsDuplicate.filter(
              (innerData) => innerData.value !== undefined,
            );
            formik.setFieldValue('nicetohave', jdParseSkillEmptyCheck);
          }
        } else {
          Toast('This field already exists.', 'LONG', 'error');
        }
      } else {
        if (valdata.action === 'select-option') {
          onDirty();
          formik.setFieldValue('nicetohave', newValue);
        }
        if (valdata.action === 'create-option') {
          onDirty();
          const tagsValue = newValue.slice();
          var tagArr1 = valdata.option.value.split(',');
          tagArr1.map((list) => {
            return (
              !list.includes(',') &&
              tagsValue.push({ value: list, label: list })
            );
          });
          const nonSkillFilter = tagsValue.filter(
            (innerData) => !innerData.value.includes(','),
          );
          const nonDsDuplicate =
            nonSkillFilter &&
            nonSkillFilter.filter(
              (value, index, self) =>
                index ===
                self.findIndex(
                  (t) =>
                    t.value?.toLocaleLowerCase() ===
                    value.value?.toLocaleLowerCase(),
                ),
            );
          const jdParseSkillEmptyCheck = nonDsDuplicate.filter(
            (innerData) => innerData.value !== undefined,
          );
          formik.setFieldValue('nicetohave', jdParseSkillEmptyCheck);
        }
      }

      if (valdata.action === 'remove-value') {
        onDirty();
        formik.setFieldValue('nicetohave', newValue);
      }
    },
    [onDirty, setFieldValue, formik.values.mandatoryskill],
  );

  // Checking the formik empty for disable the button
  const isFormEmpty = (formValues) => {
    for (const key in formValues) {
      if (Object.prototype.hasOwnProperty.call(formValues, key)) {
        const value = formValues[key];
        if (
          value === undefined ||
          value === null ||
          String(value).trim() !== ''
        ) {
          return false;
        }
      }
    }
    return true;
  };
  return (
    <Flex row style={{ justifyContent: 'space-between', marginTop: '6px' }}>
      {isSubmitLoader && (
        <MyLottieComponent data={robort} width={450} height={320} />
      )}
      <ParsingLoadingModal
        info
        css
        title={'Review Your Job Posting Details'}
        open={popup}
        close={close}
        jd_postcount={jd_postcount}
        isFileUpload={isFileUpload}
        des={
          <Text>
            We&lsquo;ve auto-populated the job information using your provided
            JD. Please take a moment to review and confirm before publishing the
            post.
          </Text>
        }
      />

      <JdrestrictModal open={verifymodel} setverifymodel={setverifymodel} />

      <Flex>
        {/* <Text bold size={14} className={styles.title}>
        Create Your Job
      </Text> */}
        <Text className={styles.desText}>
          Take up your first step in your hiring process with Zita
        </Text>
      </Flex>
      <Flex>
        <Flex row style={{ paddingBottom: '10px' }}>
          {/* <Button
            types="secondary"
            onClick={() => setmodal(true)}
            style={{ marginRight: '10px' }}
          >
            Upload JD
          </Button> */}
          <Flex>
            <Button
              // types="secondary"
              onClick={handleTempOpen}
              style={{ marginRight: '10px' }}
            >
              Import from our templates
            </Button>
          </Flex>
          <Flex>
            <Button onClick={handlefunction2}>
              <Flex row>
                <Flex>
                  <Text style={{ color: 'white', cursor: 'pointer',fontWeight:'bold' }}>
                    Generate by AI
                  </Text>
                </Flex>
                <Flex marginLeft={7} style={{ cursor: 'pointer' }}>
                  <SvgDoublestar height={20} width={20} />
                </Flex>
              </Flex>
            </Button>
          </Flex>
        </Flex>
        <Modal open={openmodel}>
          <Flex className={styles.uploadpopup1}>
            <Flex row between  >
            <Text bold size={14}>
              Generate by AI
            </Text>
              <Flex onClick={() => closemodel()}>
              <SvgClose
                width={10}
                height={10}
                fill={'#888888'}
                cursor={'pointer'}
              />
              </Flex>
              
            </Flex>
            
            <Text style={{ marginTop: '2px' }}>
              Choose your preferred method to create the Job Description (JD)
            </Text>
            <Required />
            <Flex className={styles.container}>
              <Flex className={styles.section1}>
                <Card className={styles.cards} disabled={upload_hide}>
                  <Flex style={{ paddingLeft: '15px' }}>
                    <Text bold size={14} style={{ marginTop: '10px' }}>
                      Import JD
                    </Text>

                    <Text style={{ marginTop: '3px' }}>
                      Quickly import an existing JD from your files for quick
                      posting.
                    </Text>
                  </Flex>
                  <Flex style={{ padding: '20px', marginTop: '10px' }}>
                    <Flex columnFlex className={styles.innerFlex}>
                      <div
                        onDragOver={dragOver}
                        onDragEnter={dragEnter}
                        onDragLeave={dragLeave}
                        onDrop={fileDrop}
                        className={styles.border}
                      >
                        <input
                          key={Date.now()}
                          type="file"
                          accept=".doc,.docx,.pdf,.txt"
                          onChange={handleOnChange}
                          className={styles.displayNone}
                          id="upload__file_upload"
                        />
                        {!checkSelectLength ? (
                          <Flex>
                            <Flex row center middle>
                              <Text color="gray">
                                {'Drag & Drop JD Here or'}
                              </Text>
                              <label
                                className={styles.labelStyle}
                                htmlFor={'upload__file_upload'}
                              >
                                <Text bold color="link">
                                  Browse Files
                                </Text>
                              </label>
                            </Flex>
                            <Text
                              size={12}
                              align="center"
                              color="gray"
                              className={styles.uploadText}
                            >
                              (Upload only .txt, .doc, .docx, .pdf formats)
                            </Text>
                          </Flex>
                        ) : (
                          <Flex row center>
                            <Flex row className={styles.uploadjdfilebox}>
                              <Text
                                className={styles.uploadjdfiletext}
                                color="gray"
                              >
                                {file.name}
                              </Text>
                              <div
                                tabIndex={-1}
                                role={'button'}
                                onKeyPress={() => { }}
                                onClick={handleClear}
                                className={styles.svgClose}
                              >
                                <SvgRoundClose
                                  fill={GARY_4}
                                  width={16}
                                  height={16}
                                />
                              </div>
                            </Flex>
                          </Flex>
                        )}
                      </div>
                      {isMb && (
                        <Text
                          align="center"
                          size={12}
                          color="error"
                          style={{ position: 'absolute', bottom: -22 }}
                        >
                          {FILE_2MB}
                        </Text>
                      )}
                      <Text style={{ color: 'red' }}>{uploaderror}</Text>
                    </Flex>
                    <Flex className={styles.buttongenerate}>
                      <Button
                        onClick={handleSubmit1}
                        disabled={!isFormEmpty(formik.values)}
                      >
                        Upload
                      </Button>
                    </Flex>
                  </Flex>
                </Card>
              </Flex>
              {/* <Flex className={styles.section2}> */}
              {/* <Flex between>
                <Flex className={styles.line1} height={100}></Flex>
                <Flex>
                  <Text
                  // style={{ textAlign: 'center', justifyContent: 'center' }}
                  >
                    Or
                  </Text>
                </Flex>
                <Flex className={styles.line2}></Flex>
              </Flex> */}
              <Flex
                between
                center
                middle
                style={{ display: 'flex' }}
                className={styles.section2}
              >
                {' '}
                <Flex className={styles.line}> </Flex>{' '}
                <Text className={styles.line_OR}>OR</Text>{' '}
                <Flex className={styles.line}></Flex>
              </Flex>
              {/* </Flex> */}
              <Flex className={styles.section3}>
                <Card className={styles.cards} disabled={checkSelectLength}>
                  <Flex style={{ paddingLeft: '20px' }}>
                    <Text bold size={14} style={{ marginTop: '15px' }}>
                      Generate JD
                    </Text>
                    <Text style={{ marginTop: '3px' }}>
                      Generate a JD by filling out a job title and requirements
                      form for AI assistance.
                    </Text>
                  </Flex>
                  <Flex style={{ padding: '20px' }}>
                    <Flex row className={styles.row_bottom}>
                      <Flex flex={2} style={{ marginRight: '15px' }}>
                        <InputText
                          id="jobtitle__jobtitle"
                          name="jobTitle1"
                          label="Job Title"
                          required
                          placeholder={'Eg: Software Engineer'}
                          value={formik.values.jobTitle1}
                          onChange={(e) => {
                            if (formik.values.jobTitle1.length === 0) {
                              formik.setFieldValue(
                                'jobTitle1',
                                e.target.value.trim(),
                              );
                            } else {
                              formik.setFieldValue('jobTitle1', e.target.value);
                            }
                            onDirty();
                          }}
                        />
                        {formik.values.jobTitle1 === '' && (
                          <ErrorMessage
                            name={'jobTitle1'}
                            errors={formik.errors}
                            touched={formik.touched}
                          />
                        )}
                      </Flex>
                      <Flex flex={2} style={{ marginRight: '15px' }}>
                        <InputSearch
                          placeholder="Eg: Information Technology"
                          options={data}
                          setFieldValue={formik.setFieldValue}
                          required
                          name="industryType1"
                          label="Industry Type"
                          initialValue={formik.values.industryType1}
                          onChange={(e) => {
                            formik.setFieldValue(
                              'industryType1',
                              e.target.value,
                            );

                            onDirty();
                          }}
                        />
                        {formik.values.industryType1 === '' && (
                          <ErrorMessage
                            name={'industryType1'}
                            errors={formik.errors}
                            touched={formik.touched}
                          />
                        )}
                      </Flex>
                    </Flex>
                    <Flex row style={{ paddingBottom: '10px' }}>
                      <Flex flex={2} style={{ marginRight: '15px' }}>
                        <InputText
                          id="jobdetails___minimumExperience"
                          name="minexp"
                          label="Minimum Experience"
                          placeholder={'Eg: 1'}
                          maxLength={2}
                          required
                          value={formik.values.minexp}
                          onChange={(event) => {
                            const inputValue = event.target.value;
                            if (!isNaN(inputValue)&&!inputValue.includes(" ")) {
                              formik.setFieldValue('minexp', inputValue);
                            }
                          }}
                        />
                        <ErrorMessage
                          name={'minexp'}
                          errors={formik.errors}
                          touched={formik.touched}
                        />
                      </Flex>
                      <Flex flex={2} style={{ marginRight: '15px' }}>
                        <InputText
                          id="jobdetails___maximumExperience"
                          name="maxexp"
                          label="Maximum Experience"
                          placeholder={'Eg: 4'}
                          maxLength={2}
                          value={formik.values.maxexp}
                          onChange={(event) => {
                            const inputValue = event.target.value;
                            if (!isNaN(inputValue)&&!inputValue.includes(" ")) {
                              formik.setFieldValue('maxexp', inputValue);
                            }
                          }}
                        />
                        <ErrorMessage
                          name={'maxexp'}
                          errors={formik.errors}
                          touched={formik.touched}
                        />
                        {!isEmpty(formik.values.maxexp) &&
                          formik.values.maxexp.trim() &&
                          Number(formik.values.maxexp) <=
                          Number(formik.values.minexp) && (
                            <Text size={12} color="error">
                              {MIN_MAX_EXP}
                            </Text>
                          )}
                      </Flex>
                    </Flex>
                    <Flex row style={{ paddingBottom: '10px' }}>
                      <Flex flex={2} style={{ marginRight: '15px' }}>
                        <LabelWrapper label="Mandatory Competencies" required>
                          <SelectTag
                            isClearable
                            options={skill_list}
                            width
                            isMulti
                            isSearchable
                            isCreate
                            value={formik.values.mandatoryskill}
                            onChange={handleChange}
                            components={{
                              MenuList: (props) => <MenuLists {...props} />,
                            }}
                            placeholder=" Eg: Add required tools, technologies, professional & soft skills here by typing and selecting from suggestions or type to create new, or paste directly."
                          />
                          <ErrorMessage
                            name={'mandatoryskill'}
                            errors={formik.errors}
                            touched={formik.touched}
                          />
                        </LabelWrapper>
                      </Flex>
                      <Flex flex={2} style={{ marginRight: '15px' }}>
                        <LabelWrapper label="Nice to have Competencies">
                          <SelectTag
                            isClearable
                            options={skill_list}
                            width
                            isMulti
                            isSearchable
                            isCreate
                            value={formik.values.nicetohave}
                            onChange={handleChangefunction}
                            components={{
                              MenuList: (props) => <MenuLists {...props} />,
                            }}
                            placeholder="Eg: Add desirable tools, technologies, professional & soft skills here that enhance candidacy by typing and selecting from suggestions or type to create new, or paste directly."
                          />
                          <ErrorMessage
                            name={'nicetohave'}
                            errors={formik.errors}
                            touched={formik.touched}
                          />
                        </LabelWrapper>
                      </Flex>
                    </Flex>
                    <Flex row style={{ paddingBottom: '10px' }}>
                      <Flex flex={2} style={{ marginRight: '15px' }}>
                        <InputText
                          className={styles.textArea}
                          value={formik.values.Overview}
                          textarea
                          label="Role Overview"
                          placeholder={`Provide a brief overview of the main responsibilities and expected outcomes for the role.\n\nFor example, a Software Developer is responsible for designing, building, and maintaining efficient code, contributing to all phases of the development lifecycle, and collaborating with other team members to develop software solutions that advance company objectives.`}
                          onChange={(e) => {
                            if (formik.values.Overview.length === 0) {
                              formik.setFieldValue(
                                'Overview',
                                e.target.value.trim(),
                              );
                            } else {
                              formik.setFieldValue('Overview', e.target.value);
                            }
                          }}
                          required
                        />
                        {formik.values.Overview === '' && (
                          <ErrorMessage
                            name={'Overview'}
                            errors={formik.errors}
                            touched={formik.touched}
                          />
                        )}
                      </Flex>
                    </Flex>
                    <Flex className={styles.buttongenerate}>
                      <Button
                        onClick={formik.handleSubmit}
                        disabled={file.length === undefined}
                      >
                        Generate
                      </Button>
                    </Flex>
                  </Flex>
                </Card>
              </Flex>
            </Flex>
          </Flex>
        </Modal>

        <Modal open={modal}>
          <Flex className={styles.uploadpopup}>
            <Flex row between>
              <Text bold size={14}>
                Generate by AI
              </Text>
              <Flex onClick={() => cancel_module()}>
                <SvgClose
                  width={10}
                  height={10}
                  fill={'#888888'}
                  cursor={'pointer'}
                />
              </Flex>
            </Flex>
            <Flex marginTop={10}>
              <Card className={styles.cardsonly}>
                <Flex style={{ paddingLeft: '15px' }}>
                  <Text bold size={14} style={{ marginTop: '10px' }}>
                    Import JD
                  </Text>

                  <Text style={{ marginTop: '3px' }}>
                    Quickly import an existing JD from your files for quick
                    posting.
                  </Text>
                </Flex>
                <Flex style={{ padding: '20px', marginTop: '10px' }}>
                  <Flex columnFlex className={styles.innerFlex}>
                    <div
                      onDragOver={dragOver}
                      onDragEnter={dragEnter}
                      onDragLeave={dragLeave}
                      onDrop={fileDrop}
                      className={styles.border}
                    >
                      <input
                        key={Date.now()}
                        type="file"
                        accept=".doc,.docx,.pdf,.txt"
                        onChange={handleOnChange}
                        className={styles.displayNone}
                        id="upload__file_upload"
                      />
                      {!checkSelectLength ? (
                        <Flex>
                          <Flex row center middle>
                            <Text color="gray">{'Drag & Drop JD Here or'}</Text>
                            <label
                              className={styles.labelStyle}
                              htmlFor={'upload__file_upload'}
                            >
                              <Text bold color="link">
                                Browse Files
                              </Text>
                            </label>
                          </Flex>
                          <Text
                            size={12}
                            align="center"
                            color="gray"
                            className={styles.uploadText}
                          >
                            (Upload only .txt, .doc, .docx, .pdf formats)
                          </Text>
                        </Flex>
                      ) : (
                        <Flex row center>
                          <Flex row className={styles.uploadjdfilebox}>
                            <Text
                              className={styles.uploadjdfiletext}
                              color="gray"
                            >
                              {file.name}
                            </Text>
                            <div
                              tabIndex={-1}
                              role={'button'}
                              onKeyPress={() => { }}
                              onClick={handleClear}
                              className={styles.svgClose}
                            >
                              <SvgRoundClose
                                fill={GARY_4}
                                width={16}
                                height={16}
                              />
                            </div>
                          </Flex>
                        </Flex>
                      )}
                    </div>
                    {isMb && (
                      <Text
                        align="center"
                        size={12}
                        color="error"
                        style={{ position: 'absolute', bottom: -22 }}
                      >
                        {FILE_2MB}
                      </Text>
                    )}
                    <Text style={{ color: 'red' }}>{uploaderror}</Text>
                  </Flex>
                  <Flex className={styles.buttongenerate}>
                    <Button onClick={handleSubmit1}>Upload</Button>
                  </Flex>
                </Flex>
              </Card>
            </Flex>
          </Flex>
        </Modal>
      </Flex>
    </Flex>
  );
};

export default UploadJd;
