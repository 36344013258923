import { TextField } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Formik, useFormik } from 'formik';
import { momentLocalizer } from 'react-big-calendar';
import { useDispatch } from 'react-redux';
import {
  format,
  isBefore,
  addDays,
  differenceInMinutes,
  parse,
} from 'date-fns';
import { SvgAddInterviewers, SvgCalendar } from '../../icons';
import SvgAdd from '../../icons/SvgAdd';
import { AppDispatch } from '../../store';
import {
  Button,
  Flex,
  InputSearch,
  InputText,
  Modal,
  SelectTag,
  Text,
} from '../../uikit';
import { getJdMiddleware } from '../applicantprofilemodule/store/middleware/applicantProfileMiddleware';

import { CrossButton } from '../../uikit/v2';
import { isEmpty } from '../../uikit/helper';
import Required from '../../uikit/Required/required';
import useUnsavedChangesWarning from '../common/useUnsavedChangesWarning';
import { THIS_FIELD_REQUIRED, timePattern } from '../constValue';
import { timezonesdata } from '../meetingscheduler/events/eventType';
import MenuLists from '../common/MenuList';
import { rolevaluemiddleware } from './store/middleware/calendarmiddleware';
import AddInterviewerSlider from './AddInterviewerSlider';
import InterviewerIcon from './InterviewerIcon';
import styles from './styles/createScheduleForm.module.css';
import {
  CALENDAR,
  CalendarEventType,
  EditEventDetails,
  EventMeetingType,
  GetJDResponse,
  GlobalZoneType,
  meetingFormProps,
  TeamMemberType,
  UserInfo,
  UserType,
} from './types';
import { formatTime, getNewDateTimes } from './util';

interface Props {
  meetingForm: meetingFormProps;
  applicants: UserType[];
  editEventDetails?: EditEventDetails | null;
  username: string;
  calendarProvider: CALENDAR | null;
  currentUserLabel: string;
  jd_id?: number;
  jd_name?: string;
  cand_id?: number;
  cand_name?: string;
  cand_email?: string;
  setMeetingForm: React.Dispatch<React.SetStateAction<meetingFormProps>>;
  setViewMeetingSummary: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentUserLabel: React.Dispatch<React.SetStateAction<string>>;
  currentUserEvents: CalendarEventType[];
  teamMembers: TeamMemberType[];
  currentUser: UserInfo;
  handleCloseSchedulingForm: () => void;
  updateCurrentApplicantId: (applicantId: number) => void;
  setOpenScheduleForm: any;
  setopenmodel: any;
  openmodel: any;
  formik: any;
  plan: any;
}

const MeetingSchedulingForm = ({
  meetingForm,
  applicants,
  editEventDetails,
  username,
  cand_name,
  currentUserLabel,
  jd_name,
  setMeetingForm,
  setViewMeetingSummary,
  setCurrentUserLabel,
  handleCloseSchedulingForm,
  currentUserEvents,
  teamMembers,
  cand_email,
  cand_id,
  jd_id,
  calendarProvider,
  updateCurrentApplicantId,
  currentUser,
  setOpenScheduleForm,
  setopenmodel,
  openmodel,
  formik,
  plan,
}: Props) => {
  const dispatch: AppDispatch = useDispatch();
  const [applicantJDList, setApplicantJDList] = useState([]);
  const [globalZones, setGlobalZones] = useState<GlobalZoneType[]>(null);
  const [openAddInterviewerModal, setOpenAddInterviewerModal] = useState(false);
  const [currentApplicantId, setCurrentApplicantId] = useState<number | null>(
    null,
  );
  const [time, SetTime] = useState([]);
  const [iscandidatename, setcandidatename] = useState<any>();
  const { routerPrompt, onDirty, onPristine } = useUnsavedChangesWarning();
  const [list, setlist] = useState('');
  const [errors, setErrors] = useState([]);

  const [role, setrole] = useState<any>([]);
  const updatestate = (val) => {
    const interviewerExists = formik.values.interviewers?.some(
      (item) => item?.userId === val?.userId,
    );
    if (!interviewerExists) {
      const newInterviewer = {
        firstName: val?.firstName,
        lastName: val?.lastName,
        role: val?.role,
        userId: val?.userId,
      };
      setMeetingForm((form) => {
        if (form.interviewer.map((e) => e?.userId !== val?.userId)) {
          return {
            ...form,
            interviewer: [...form?.interviewer, val],
          };
        } else {
          return {
            ...form,
            interviewer: form?.interviewer?.filter(
              (item) => item?.userId !== val?.userId,
            ),
          };
        }
      });
      formik.setFieldValue('interviewers', [
        ...formik.values.interviewers,
        newInterviewer,
      ]);
    } else {
      const updatedInterviewers = formik.values.interviewers?.filter(
        (item) => item?.userId !== val?.userId,
      );
      formik.setFieldValue('interviewers', updatedInterviewers);
    }
  };

  useEffect(() => {
    localStorage.setItem('role', JSON.stringify(formik.values.interviewers));
  }, [formik.values]);

  useEffect(() => {
    setcandidatename(cand_name);
  }, [cand_name]);

  useEffect(() => {
    if (
      localStorage.getItem('Applicantname') !== '' &&
      localStorage.getItem('Applicantname') !== null &&
      localStorage.getItem('jd_id') !== '' &&
      localStorage.getItem('jd_id') !== null
    ) {
      setMeetingForm((form) => ({
        ...form,
        applicant: { ...form.applicant, email: cand_email },
        job: {
          ...form.job,
          label: localStorage.getItem('Applicantname'),
          value: Number(localStorage.getItem('jd_id')),
        },
      }));
    }
  }, [localStorage.getItem('jd_id'), localStorage.getItem('Applicantname')]);

  const eventMeetingTypes: {
    value: EventMeetingType;
    label: EventMeetingType;
  }[] = [
      {
        value: 'Onsite Interview',
        label: 'Onsite Interview',
      },
      {
        value: 'Phone Interview',
        label: 'Phone Interview',
      },
    ];

  if (calendarProvider === CALENDAR.Google) {
    eventMeetingTypes.push({
      value: 'Google Meet Interview',
      label: 'Google Meet Interview',
    });
  } else if (calendarProvider === CALENDAR.Outlook) {
    eventMeetingTypes.push({
      value: 'Microsoft Teams Interview',
      label: 'Microsoft Teams Interview',
    });
  }

  useEffect(() => {
    updateCurrentApplicantId(currentApplicantId);
  }, [currentApplicantId]);
  useEffect(() => {
    dispatch(rolevaluemiddleware()).then((res) => {
      setrole(res.payload);
    });
  }, []);

  useEffect(() => {
    const timezones = moment.tz.names();
    localStorage.setItem('remTime', 'mins');
    [
      'applicant',
      'applicant_id',
      'jd',
      'jd_id',
      'eDate',
      'timezone',
      'event_type',
      'notes',
      'privateNotes',
      'location',
      'interviewers',
      'remMin',
    ].forEach((item) => localStorage.removeItem(item));

    localStorage.setItem(
      'timezone',
      Intl.DateTimeFormat().resolvedOptions().timeZone,
    );
    localStorage.setItem('sDate', new Date().toString());
    localStorage.setItem('remMin', '15');
    if (editEventDetails) {
      setCurrentUserLabel(editEventDetails.applicant.name);
    } else if (cand_name && jd_name && cand_id && jd_id && cand_email) {
      setCurrentApplicantId(cand_id);
      setCurrentUserLabel(cand_name);
      setMeetingForm((form) => ({
        ...form,
        applicant: { ...form.applicant, email: cand_email, id: cand_id },
        job: { ...form.job, label: jd_name, value: jd_id },
      }));
    }

    setGlobalZones(
      timezones.map((timezone) => {
        return { label: timezone, value: timezone };
      }),
    );
  }, []);

  const closeAddInterviewerSlider = () => {
    setOpenAddInterviewerModal(!openAddInterviewerModal);
  };

  const handleContinue = () => {
    const newErrors = editEventDetails
      ? meetingForm?.attentee.map((item) => {
        if (
          item.role === '' ||
          item.role === null ||
          item.role === undefined
        ) {
          return 'Role is required';
        }
        return null;
      })
      : formik.values.interviewers.map((item) => {
        if (item.role === '' || item.role === undefined) {
          return 'Role is required';
        }
        return null;
      });

    setErrors(
      newErrors.filter((error) => error !== null && error !== undefined),
    );
    if (localStorage.getItem('Applicantname') !== '') {
      localStorage.setItem(
        'Applicantsname',
        localStorage.getItem('Applicantname'),
      );
    }
    localStorage.setItem('Applicantname', '');
    localStorage.setItem('Jdname', '');
    localStorage.setItem('jdid', '');
    localStorage.setItem('can_mail', '');
    localStorage.setItem('can_id', '');
    setMeetingForm((form) => {
      let jobError = !form.job.label ? true : false;
      let applicantError = !form.applicant.name ? true : false;
      let eventTypeError = !form.eventType.value ? true : false;
      let timeZoneError = !editEventDetails
        ? form.timeZone.value === null
          ? true
          : false
        : false;
      let dateError = form.date.value === null ? true : false;
      let locationError =
        form.eventType?.value === 'Onsite Interview'
          ? form?.location?.name !== undefined &&
            form?.location?.name?.length !== 0
            ? false
            : form?.location?.value === ''
              ? true
              : false
          : false;
      return {
        ...form,
        job: { ...form.job, error: jobError },
        location: { ...form.location, error: locationError },
        applicant: { ...form.applicant, error: applicantError },
        eventType: { ...form.eventType, error: eventTypeError },
        timeZone: { ...form.timeZone, error: timeZoneError },
        date: { ...form.date, error: dateError },
        startTime: {
          ...form.startTime,
          errorMessage: !form.startTime.value ? 'Start time is required' : null,
        },
        endTime: {
          ...form.endTime,
          errorMessage: !form.endTime.value ? 'End time is required' : null,
        },
      };
    });
    if (!newErrors.some((error) => error)) {
      if (editEventDetails) {
        if (
          meetingForm.eventType.value &&
          meetingForm.startTime.value &&
          meetingForm.date.value !== null &&
          meetingForm.endTime.value &&
          new Date(meetingForm.startTime.value) <
          new Date(meetingForm.endTime.value) &&
          meetingForm.eventType.value === 'Onsite Interview' &&
          meetingForm.location.name !== ''
        ) {
          setMeetingForm((form) => {
            const { startDateTime, endDateTime } = getNewDateTimes(
              form.date.value,
              form.startTime.value,
              form.endTime.value,
            );
            return {
              ...form,
              startDateTime,
              endDateTime,
            };
          });
          setViewMeetingSummary(true);
          setopenmodel(true);
        } else if (
          meetingForm.eventType.value &&
          meetingForm.startTime.value &&
          meetingForm.date.value !== null &&
          meetingForm.endTime.value &&
          new Date(meetingForm.startTime.value) <
          new Date(meetingForm.endTime.value) &&
          meetingForm.eventType.value !== 'Onsite Interview'
        ) {
          setMeetingForm((form) => {
            const { startDateTime, endDateTime } = getNewDateTimes(
              form.date.value,
              form.startTime.value,
              form.endTime.value,
            );
            return {
              ...form,
              startDateTime,
              endDateTime,
            };
          });
          setViewMeetingSummary(true);
          setopenmodel(true);
        }
      } else {
        if (meetingForm.eventType.value === 'Onsite Interview') {
          if (
            meetingForm.applicant.name &&
            meetingForm.job.label &&
            meetingForm.location.name &&
            meetingForm.eventType.value &&
            meetingForm.timeZone.value &&
            meetingForm.date.value !== null &&
            meetingForm.startTime.value &&
            meetingForm.endTime.value &&
            new Date(meetingForm.startTime.value) <
            new Date(meetingForm.endTime.value)
          ) {
            setMeetingForm((form) => {
              const { startDateTime, endDateTime } = getNewDateTimes(
                form.date.value,
                form.startTime.value,
                form.endTime.value,
              );
              return {
                ...form,
                startDateTime,
                endDateTime,
              };
            });
            setViewMeetingSummary(true);
            setopenmodel(true);
          }
        } else {
          if (
            meetingForm.applicant.name &&
            meetingForm.job.label &&
            meetingForm.eventType.value &&
            meetingForm.timeZone.value &&
            meetingForm.date.value !== null &&
            meetingForm.startTime.value &&
            meetingForm.endTime.value &&
            new Date(meetingForm.startTime.value) <
            new Date(meetingForm.endTime.value)
          ) {
            setMeetingForm((form) => {
              const { startDateTime, endDateTime } = getNewDateTimes(
                form.date.value,
                form.startTime.value,
                form.endTime.value,
              );
              return {
                ...form,
                startDateTime,
                endDateTime,
              };
            });

            setopenmodel(true);
            setViewMeetingSummary(true);
          }
        }
      }
    }
  };
  const [valid, setValid] = useState(false);

  const handleJobRole = (value: number, label: string) => {
    localStorage.setItem('jd', label);
    localStorage.setItem('jd_id', value.toString());
    setMeetingForm((form) => ({
      ...form,
      job: { ...form.job, label, value, error: false },
    }));
  };

  const handleReminderFormat = (val: string) => {
    localStorage.setItem('remTime', val);
    setMeetingForm((form) => ({
      ...form,
      reminder: { ...form.reminder, format: val },
    }));
  };

  const handleReminderValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMeetingForm((form) => ({
      ...form,
      reminder: { ...form.reminder, value: Number(e.target.value) },
    }));
    localStorage.setItem('remMin', e.target.value.toString());
  };

  const reminderOptions = [
    {
      value: 'mins',
      label: 'mins',
    },
    {
      value: 'hours',
      label: 'hours',
    },
  ];

  const handleEventType = (value: EventMeetingType) => {
    localStorage.setItem('event_type', value);
    let haveLocation = value === 'Onsite Interview' ? true : false;
    setMeetingForm((form) => ({
      ...form,
      eventType: { ...form.eventType, value, error: false },
      location: { ...form.location, isHave: haveLocation },
    }));
  };
  const handleChangeDate = (value: Date | null) => {
    setMeetingForm((form) => ({
      ...form,
      date: { ...form.date, value, error: null },
    }));
  };
  const [isstarttimeupdate, setstarttimeupdate] = useState<any>();
  // Function to update the date part of inputDate with the date part of meetingform.data.value
  const updateDate = (input, newDate) => {
    input.setFullYear(
      newDate.getFullYear(),
      newDate.getMonth(),
      newDate.getDate(),
    );
    return input;
  };

  const handleStartTime = (value?: any) => {
    if (timePattern.test(value?.label)) {
      const timeString = value?.label || '';
      const inputDate = new Date();
      inputDate.setHours(0, 0, 0, 0);
      const [hoursStr, minutesStr] = timeString.split(':');
      const hours = parseInt(hoursStr) % 12;
      const minutes = parseInt(minutesStr) || 0;
      inputDate.setHours(
        inputDate.getHours() + hours + (timeString.includes('PM') ? 12 : 0),
      );
      inputDate.setMinutes(inputDate.getMinutes() + minutes);
      const dateTimeFormat = new Intl.DateTimeFormat('en-US', {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        timeZoneName: 'long',
      });

      if (
        meetingForm.endTime.value !== null &&
        convertTo24HourFormat(isdats2) <= convertTo24HourFormat(value.value) &&
        checkthetimeperiod(isdats2, value.value)
      ) {
        setMeetingForm((form) => ({
          ...form,
          startTime: {
            ...form.startTime,
            value: null,
            errorMessage: 'Start time must be less then end time',
          },
        }));
        setMeetingForm((form) => ({
          ...form,
          endTime: {
            ...form.endTime,
            errorMessage: '',
          },
        }));
      } else {
        // Check if meetingform.data.value is not null
        if (
          meetingForm?.date?.value !== undefined &&
          meetingForm?.date?.value !== null
        ) {
          const newDate = new Date(meetingForm.date.value);
          const inputs = updateDate(new Date(inputDate), newDate);
          const outputTimeString = dateTimeFormat.format(inputs);
          const outputTimeDate = moment(
            outputTimeString,
            'ddd, MMM D, YYYY, h:mm:ss A z',
            'en',
          );
          setMeetingForm((form) => ({
            ...form,
            startTime: {
              ...form.startTime,
              value: outputTimeDate.toDate(),
              errorMessage: null,
            },
            endTime: { ...form.endTime, errorMessage: null },
          }));
        } else {
          const outputTimeString = dateTimeFormat.format(inputDate);
          const outputTimeDate = moment(
            outputTimeString,
            'ddd, MMM D, YYYY, h:mm:ss A z',
            'en',
          );
          setMeetingForm((form) => ({
            ...form,
            startTime: {
              ...form.startTime,
              value: outputTimeDate.toDate(),
              errorMessage: null,
            },
            endTime: { ...form.endTime, errorMessage: null },
          }));
        }
      }

      if (value.value) {
        localStorage.setItem('startTime', value.toString());
      }
    } else {
      setMeetingForm((form) => ({
        ...form,
        startTime: {
          ...form.startTime,
          value: null,
          errorMessage: 'Please give valid time(e.g. 9:30 AM)',
        },
      }));
      setMeetingForm((form) => ({
        ...form,
        endTime: {
          ...form.endTime,
          errorMessage: '',
        },
      }));
    }
  };

  // converting the time format of 12 hours to 24 hours format.
  const convertTo24HourFormat = (timeString) => {
    if (!timeString) {
      return '00:00';
    }
    const [timeperiod, period] = timeString.split(' ') || [];
    const [hours, minutes] = timeperiod?.split(':') || [];
    if (!hours || !minutes) {
      return '00:00';
    }
    let hours24 = parseInt(hours, 10);
    if (period === 'PM' && hours24 !== 12) {
      hours24 += 12;
    } else if (period === 'AM' && hours24 === 12) {
      hours24 = 0;
    }
    // var newTime = hours24 < 10 ? parseInt('0' + hours24) : hours24;
    var newTime = hours24 < 10 ? '0' + hours24 : hours24.toString();

    return `${newTime}:${minutes}`;
  };

  // checking the starttime and endtime is equal or not.
  const checkthetimeperiod = (timeString1, timeString2) => {
    const [timeperiod1, period1] = timeString1 ? timeString1.split(' ') : [];
    const [timeperiod2, period2] = timeString2 ? timeString2.split(' ') : [];
    if (period1 === period2) {
      return true;
    } else {
      return false;
    }
  };

  const handleEndTime = (value?: any) => {
    if (timePattern.test(value?.label)) {
      const timeString = value?.label || '';
      const inputDate = new Date();
      inputDate.setHours(0, 0, 0, 0);
      const [hoursStr, minutesStr] = timeString?.split(':');
      let hours = parseInt(hoursStr) % 12;
      const minutes = parseInt(minutesStr) || 0;

      if (isdats?.includes('PM') && timeString?.includes('AM')) {
        hours += 24;
      }
      inputDate.setHours(
        inputDate?.getHours() + hours + (timeString?.includes('PM') ? 12 : 0),
      );
      inputDate.setMinutes(inputDate.getMinutes() + minutes);
      const dateTimeFormat = new Intl.DateTimeFormat('en-US', {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        timeZoneName: 'long',
      });

      // const outputTimeString = dateTimeFormat.format(inputDate);
      // const outputTimeDate = moment(
      //   outputTimeString,
      //   'ddd, MMM D, YYYY, h:mm:ss A z',
      //   'en',
      // );
      if (meetingForm?.startTime?.value === null) {
        setMeetingForm((form) => ({
          ...form,
          endTime: {
            ...form.endTime,
            value: null,
            errorMessage: 'Please choose start time first',
          },
        }));
        setMeetingForm((form) => ({
          ...form,
          startTime: {
            ...form.startTime,
            errorMessage: '',
          },
        }));
      } else if (
        convertTo24HourFormat(isdats) >= convertTo24HourFormat(value?.value) &&
        checkthetimeperiod(isdats, value.value)
      ) {
        setMeetingForm((form) => ({
          ...form,
          endTime: {
            ...form.endTime,
            value: null,
            errorMessage: 'End time must be greater than start time',
          },
        }));
        setMeetingForm((form) => ({
          ...form,
          startTime: {
            ...form.startTime,
            errorMessage: '',
          },
        }));
      } else {
        if (
          meetingForm?.date?.value !== undefined &&
          meetingForm?.date?.value !== null
        ) {
          const newDate = new Date(meetingForm.date.value);
          const inputs = updateDate(new Date(inputDate), newDate);
          const outputTimeString = dateTimeFormat.format(inputs);
          const outputTimeDate = moment(
            outputTimeString,
            'ddd, MMM D, YYYY, h:mm:ss A z',
            'en',
          );
          setMeetingForm((form) => ({
            ...form,
            endTime: {
              ...form.endTime,
              value: outputTimeDate.toDate(),
              errorMessage: null,
            },
          }));
        } else {
          const outputTimeString = dateTimeFormat.format(inputDate);
          const outputTimeDate = moment(
            outputTimeString,
            'ddd, MMM D, YYYY, h:mm:ss A z',
            'en',
          );
          setMeetingForm((form) => ({
            ...form,
            endTime: {
              ...form.endTime,
              value: outputTimeDate.toDate(),
              errorMessage: null,
            },
          }));
        }
      }
      if (value) {
        localStorage.setItem('endTime', value.toString());
      }
    } else {
      setMeetingForm((form) => ({
        ...form,
        endTime: {
          ...form.endTime,
          value: null,
          errorMessage: 'Please give valid time(e.g. 9:30 AM)',
        },
      }));
      setMeetingForm((form) => ({
        ...form,
        startTime: {
          ...form.startTime,
          errorMessage: '',
        },
      }));
    }
  };

  const handleLocation = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target?.value.trim();

    setMeetingForm((form) => ({
      ...form,
      location: {
        ...form.location,
        name: value.length === 0 ? e.target?.value?.trim() : e.target?.value,
        value: value.length === 0 ? e.target?.value?.trim() : e.target?.value,
        error: false,
      },
    }));
    localStorage.setItem('location', e.target.value?.trim()?.toString());
  };
  const notesHandler = (e: { target: HTMLTextAreaElement }) => {
    setMeetingForm((form) => ({ ...form, notes: e.target?.value }));
    localStorage.setItem('notes', e.target.value.toString());
  };

  const privateNotesHandler = (e: { target: HTMLTextAreaElement }) => {
    setMeetingForm((form) => ({ ...form, privateNotes: e.target.value }));
    localStorage.setItem('privateNotes', e.target.value.toString());
  };

  const addTeamInterviewer = (memberInfo: TeamMemberType) => {
    if (editEventDetails) {
      setMeetingForm((form) => {
        if (!form.attentee.includes(memberInfo)) {
          return {
            ...form,
            attentee: [...form.attentee, memberInfo],
          };
        }
        return form;
      });
    }
    formik.setValues((values) => {
      if (!values.interviewers.includes(memberInfo)) {
        return {
          ...values,
          interviewers: [...values.interviewers, memberInfo],
        };
      }
      return values;
    });
  };
  const removeTeamInterviewer = (memberInfo: TeamMemberType) => {
    if (editEventDetails) {
      setMeetingForm((form) => ({
        ...form,
        attentee: form?.attentee?.filter(
          (item) => item.userId !== memberInfo.userId,
        ),
      }));

      formik.setValues((values) => ({
        ...values,
        interviewers: values?.interviewers?.filter(
          (item) => item.userId !== memberInfo.userId,
        ),
      }));
    }
    setMeetingForm((form) => ({
      ...form,
      interviewer: form?.interviewer?.filter(
        (item) => item.userId !== memberInfo.userId,
      ),
    }));

    formik.setValues((values) => ({
      ...values,
      interviewers: values?.interviewers?.filter(
        (item) => item.userId !== memberInfo.userId,
      ),
    }));
  };

  const handleSelectApplicant = (
    applicantId: number,
    label: string,
    email: string,
  ) => {
    setCurrentApplicantId(applicantId);
    setCurrentUserLabel(label);
    setMeetingForm((form) => ({
      ...form,
      applicant: { id: applicantId, name: label, email, error: false },
      job: { ...form.job, value: null, label: null },
    }));
    localStorage.setItem('applicant', label);
    localStorage.setItem('applicant_id', applicantId.toString());

    dispatch(getJdMiddleware({ userId: applicantId })).then((res) => {
      setApplicantJDList(
        res.payload.jd.map((items: GetJDResponse) => {
          return { value: items.id, label: items.jobTitle };
        }),
      );
    });
  };

  // useEffect for creating the time intervel data for showing the option for time selecter
  useEffect(() => {
    const timeSlots = [];
    const startTime = new Date();
    startTime.setHours(0, 0, 0);
    const endTime = new Date();
    endTime.setHours(23, 45, 0);
    const defaulMin = '30 Minutes';
    const timeIncrement = parseInt(defaulMin);

    let currentTime = startTime;
    let index = 0;
    while (currentTime <= endTime) {
      const hour = currentTime.getHours() % 12 || 12;
      const minute = currentTime.getMinutes().toString().padStart(2, '0');
      const ampm = currentTime.getHours() >= 12 ? 'PM' : 'AM';
      const timeSlot = {
        id: index,
        value: `${hour}:${minute} ${ampm}`,
        label: `${hour}:${minute} ${ampm}`,
      };
      timeSlots.push(timeSlot);
      currentTime = new Date(currentTime.getTime() + timeIncrement * 60000);
      index++;
      SetTime(timeSlots);
    }
  }, []);

  const handleChangeTimeZone = (timezone: string) => {
    moment.tz.setDefault(timezone);
    momentLocalizer(moment);
    setMeetingForm((form) => ({
      ...form,
      timeZone: { value: timezone, error: false },
    }));
    const settz = timezonesdata.find((t) => t.label === timezone).timezone;
    localStorage.setItem('timezone', settz);
  };

  const ApplicantView = (
    <div>
      <label className={styles.label}>Applicant*</label>
      {editEventDetails || cand_name ? (
        <div>
          <InputText
            disabled={true}
            value={editEventDetails ? currentUserLabel : cand_name}
          />
        </div>
      ) : meetingForm.applicant?.name ? (
        <SelectTag
          value={{
            label: meetingForm.applicant?.name,
            value: meetingForm.applicant?.id,
          }}
          isSearchable={true}
          labelBold
          onChange={(option) =>
            handleSelectApplicant(option.value, option.label, option.email)
          }
          options={applicants}
        />
      ) : (
        <SelectTag
          placeholder="Select Applicant"
          isSearchable={true}
          labelBold
          options={applicants}
          onChange={(option) =>
            handleSelectApplicant(option.value, option.label, option.email)
          }
        />
      )}
      {meetingForm?.applicant?.error && (
        <p className={styles.warn}>This field is required.</p>
      )}
    </div>
  );

  const JobTitleView = (
    <div>
      <label className={styles.label}>Job Title *</label>
      <div>
        {editEventDetails || jd_name ? (
          <div>
            <InputText
              disabled={true}
              value={editEventDetails ? meetingForm?.job?.label : jd_name}
            />
          </div>
        ) : (
          <div>
            {meetingForm?.job?.value === null ||
              meetingForm?.job?.value === 0 ? (
              <SelectTag
                placeholder="Select Job"
                isSearchable={true}
                labelBold
                value={null}
                onChange={(option) =>
                  handleJobRole(option?.value, option.label)
                }
                options={applicantJDList}
              />
            ) : (
              <SelectTag
                defaultValue={{
                  label: meetingForm?.job?.label || '', // Fallback to empty string if label is null
                  value: meetingForm?.job?.value,
                }}
                isSearchable={true}
                labelBold
                onChange={(option) => handleJobRole(option.value, option.label)}
                options={applicantJDList}
              />
            )}
          </div>
        )}
        {meetingForm?.job?.error && (
          <p className={styles.warn}>This field is required.</p>
        )}
      </div>
    </div>
  );

  const DateView = (
    <div className={styles.dateview}>
      <label className={styles.label} style={{ marginBottom: '5px' }}>
        Date *
      </label>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          label={' '}
          value={meetingForm?.date?.value}
          onChange={handleChangeDate}
          renderInput={(params) => (
            <TextField
              {...params}
              style={{ width: 'auto !important' }}
              autoComplete="off"
            />
          )}
          minDate={new Date()}
        />
      </LocalizationProvider>
      {meetingForm?.date?.error && (
        <p className={styles.warn}>This field is required.</p>
      )}
    </div>
  );

  // function for formating the the time(eg.9:00 AM).
  const getFormattedTime = (dateString) => {
    if (dateString) {
      const options: Intl.DateTimeFormatOptions = {
        hour: 'numeric',
        minute: '2-digit',
        hour12: true,
      };
      return new Date(dateString).toLocaleTimeString('en-US', options);
    } else {
      return null;
    }
  };

  const [isdats, setdatas] = useState<any>(null);
  const [isdats2, setdatas2] = useState<any>(null);

  // setState for format convertion of start and end time (eg.9:00 AM)
  useEffect(() => {
    setdatas(getFormattedTime(meetingForm?.startTime?.value));
    setdatas2(getFormattedTime(meetingForm?.endTime?.value));
  }, [meetingForm?.startTime, meetingForm?.endTime]);

  // function for splitting the time as a am or pm.
  const splittiming = (value) => {
    if (value?.includes('AM')) {
      return 'AM';
    } else if (value?.includes('PM')) {
      return 'PM';
    }
  };
  // function for checking the start time and end time is in same date
  const areDatesEqual = (dateString1, dateString2) => {
    const date1 = new Date(dateString1);
    const date2 = new Date(dateString2);
    if (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    ) {
      return true;
    } else {
      return false;
    }
  };

  // setting date and time when they select the end is in am and start is in pm. they converting the end time to next day.
  useEffect(() => {
    if (
      splittiming(getFormattedTime(meetingForm?.startTime?.value)) === 'PM' &&
      splittiming(getFormattedTime(meetingForm?.endTime?.value)) === 'AM' &&
      areDatesEqual(meetingForm?.startTime?.value, meetingForm?.endTime?.value)
    ) {
      const timeString = getFormattedTime(meetingForm?.endTime?.value) || '';
      const inputDate = new Date();
      inputDate.setHours(0, 0, 0, 0);

      const [hoursStr, minutesStr] = timeString?.split(':');
      let hours = parseInt(hoursStr) % 12;
      const minutes = parseInt(minutesStr) || 0;
      hours += 24;
      inputDate.setHours(
        inputDate.getHours() + hours + (timeString?.includes('PM') ? 12 : 0),
      );
      inputDate.setMinutes(inputDate.getMinutes() + minutes);
      const dateTimeFormat = new Intl.DateTimeFormat('en-US', {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        timeZoneName: 'long',
      });
      const outputTimeString = dateTimeFormat.format(inputDate);
      const outputTimeDate = moment(
        outputTimeString,
        'ddd, MMM D, YYYY, h:mm:ss A z',
        'en',
      );
      setMeetingForm((form) => ({
        ...form,
        endTime: {
          ...form.endTime,
          value: outputTimeDate.toDate(),
        },
      }));
    }
  }, [meetingForm?.endTime, meetingForm?.startTime]);

  const timeOptions = [];

  if (isdats !== null) {
    timeOptions.push({ label: isdats });
  }

  const TimingView = (
    <div>
      <label className={styles.label}>Time *</label>
      <Flex between>
        <Flex>
          <div className={styles.timeInputWrapper}>
            <Flex
              start
              width={'100%'}
              style={{
                display: 'flex',
                flexDirection: 'column',
                minWidth: '132px',
              }}
            >
              <SelectTag
                options={time}
                isSearchable
                isCreate
                placeholder={'e.g. 8:30 AM'}
                name="starttime"
                value={
                  isdats !== null
                    ? { label: isdats, value: isdats }
                    // .find((option) => option.label === isdats)
                    : ''
                }
                onChange={(e) => {
                  handleStartTime(e);
                }}
              ></SelectTag>
              {meetingForm?.startTime?.errorMessage !==
                'Start time must be less then end time' &&
                meetingForm?.startTime?.errorMessage !==
                'Please give valid time(e.g. 9:30 AM)' && (
                  <>
                    <p className={styles.warn} style={{ marginTop: '3px' }}>
                      {meetingForm?.startTime?.errorMessage}
                    </p>
                  </>
                )}
            </Flex>
            <p
              className={styles.to}
              style={{
                fontSize: '13px',
                marginBottom:
                  meetingForm?.startTime?.errorMessage ||
                    meetingForm?.endTime?.errorMessage
                    ? '10px'
                    : 0,
              }}
            >
              to
            </p>
            <Flex
              end
              width={'100%'}
              style={{
                display: 'flex',
                flexDirection: 'column',
                minWidth: '132px',
              }}
            >
              <SelectTag
                options={time}
                isSearchable={true}
                isCreate
                placeholder={'e.g. 9:00 AM'}
                name="starttime"
                value={
                  isdats2 !== null
                    ? { label: isdats2, value: isdats2 }
                    // .find((option) => option.label === isdats2)
                    : ''
                }
                onChange={(e) => {
                  handleEndTime(e);
                }}
              ></SelectTag>
              {meetingForm?.endTime?.errorMessage !==
                'End time must be greater than start time' &&
                meetingForm?.endTime?.errorMessage !==
                'Please choose start time first' &&
                meetingForm?.endTime?.errorMessage !==
                'Please give valid time(e.g. 9:30 AM)' && (
                  <>
                    <p className={styles.warn} style={{ marginTop: '3px' }}>
                      {meetingForm?.endTime?.errorMessage}
                    </p>
                  </>
                )}
            </Flex>
          </div>
        </Flex>
        <Flex>
          {(meetingForm?.startTime?.errorMessage ===
            'Please give valid time(e.g. 9:30 AM)' ||
            meetingForm?.startTime?.errorMessage ===
            'Start time must be less then end time') && (
              <p className={styles.warn} style={{ marginTop: '12px' }}>
                {meetingForm?.startTime?.errorMessage}
              </p>
            )}

          {(meetingForm?.endTime?.errorMessage ===
            'Please give valid time(e.g. 9:30 AM)' ||
            meetingForm?.endTime?.errorMessage ===
            'End time must be greater than start time' ||
            meetingForm?.endTime?.errorMessage ===
            'Please choose start time first') && (
              <p className={styles.warn} style={{ marginTop: '12px' }}>
                {meetingForm?.endTime?.errorMessage}
              </p>
            )}
        </Flex>
      </Flex>
    </div>
  );
  const TimeZoneView = () => {
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const tzone = `${moment
      .tz(userTimezone)
      .format('Z')} (${userTimezone})`.trim();
    const timeZoneLabel =
      timezonesdata?.find((e) => e?.timezone === meetingForm?.timeZone?.value)
        ?.label ||
      timezonesdata?.find((e) => e?.label === meetingForm?.timeZone?.value)
        ?.label;
    const value = meetingForm?.timeZone?.value ? timeZoneLabel : tzone;
    return (
      <div>
        <label className={styles.label}>Choose your Timezone</label>
        <SelectTag
          defaultValue={{
            label: value,
            value,
          }}
          isSearchable={true}
          labelBold
          // options={globalZones}
          options={timezonesdata}
          onChange={(option) => handleChangeTimeZone(option.label)}
        />
        {meetingForm?.timeZone?.error && (
          <p className={styles.warn}>Select a timezone</p>
        )}
      </div>
    );
  };
  const EventTypeView = (
    <div>
      <label className={styles.label}>Event Type *</label>
      {editEventDetails ? (
        <SelectTag
          defaultValue={{
            label: editEventDetails.eventType,
            value: editEventDetails.eventType,
          }}
          value={eventMeetingTypes?.find(function (option) {
            return option.value === meetingForm?.eventType?.value;
          })}
          labelBold
          options={eventMeetingTypes}
          onChange={(option) => handleEventType(option.value)}
        />
      ) : (
        <SelectTag
          labelBold
          value={eventMeetingTypes.find(function (option) {
            return option.value === meetingForm?.eventType?.value;
          })}
          options={eventMeetingTypes}
          onChange={(option) => handleEventType(option.value)}
        />
      )}
      {meetingForm?.eventType?.error && (
        <p className={styles.warn}>This field is required.</p>
      )}
    </div>
  );

  const RemindarView = (
    <div className={styles.reminder}>
      <label className={styles.label}>Reminder</label>
      <div>
        <InputText
          keyboardType="number"
          value={meetingForm?.reminder?.value ? meetingForm.reminder.value.toString() : meetingForm.reminder.toString()}
          onChange={handleReminderValue}
          min={1}
        />
        <div style={{ marginLeft: '10px', width: '100px', flex: 1 }}>
          <SelectTag
            labelBold
            options={reminderOptions}
            defaultValue={{
              label: meetingForm.reminder.format,
              value: meetingForm.reminder.format,
            }}
            onChange={(option) => handleReminderFormat(option.value)}
          />
        </div>
      </div>
    </div>
  );

  const handleChange = (ind, e, user?: any) => {
    const updatedRole = e.target?.value || null;
    // Check if the interviewers array has enough elements
    const updatedNames = [...formik.values.interviewers];

    // Update the role of the interviewer at the specified index
    updatedNames[ind] = {
      ...updatedNames[ind],
      role: updatedRole?.trim(),
    };
    formik.setFieldValue('interviewers', updatedNames);
    setdata(user !== undefined && user);
    if (editEventDetails) {
      if (updatedRole === null) {
        setMeetingForm((form) => ({
          ...form,
          attentee: form.attentee.map((item) => {
            return item.userId === user?.userId
              ? { ...item, role: e.target.innerText }
              : item;
          }),
        }));
      } else {
        setMeetingForm((form) => ({
          ...form,
          attentee: form.attentee.map((item) => {
            return item.userId === user?.userId
              ? { ...item, role: updatedRole?.trim() }
              : item;
          }),
        }));
      }
    }
    // setname(updatedNames);

    localStorage.setItem('role', JSON.stringify(formik.values.interviewers));
    onDirty();

  };

  // handle function for handling the onkeypress on input field on role.
  const handlekeyChange = (ind, e, user?: any) => {
    const updatedRole = e.target?.value || null;
    // Check if the interviewers array has enough elements
    const updatedNames = [...formik.values.interviewers];

    // Update the role of the interviewer at the specified index
    updatedNames[ind] = {
      ...updatedNames[ind],
      role: updatedRole?.trim(),
    };
    formik.setFieldValue('interviewers', updatedNames);
    setdata(user !== undefined && user);
    if (editEventDetails) {
      if (updatedRole === null) {
        setMeetingForm((form) => ({
          ...form,
          attentee: form.attentee.map((item) => {
            return item.userId === user?.userId
              ? { ...item, role: e.target.innerText }
              : item;
          }),
        }));
      } else {
        setMeetingForm((form) => ({
          ...form,
          attentee: form.attentee.map((item) => {
            return item.userId === user?.userId
              ? { ...item, role: updatedRole?.trim() }
              : item;
          }),
        }));
      }
    }
    // setname(updatedNames);

    localStorage.setItem('role', JSON.stringify(formik.values.interviewers));
    onDirty();

    // condition for handling the onkeypress when click the enter the cursor will removed from the input box.
    if (e.key === 'Enter') {
      e.preventDefault();
      e.target.blur();
    }
  };
  const [isdata, setdata] = useState<any>();
  const localString = localStorage.getItem('role');
  const local = localString ? JSON.parse(localString) : [];
  useEffect(() => {
    const data = formik?.values?.interviewer?.role;
    setMeetingForm((form) => ({
      ...form,
      attentee: form?.attentee?.map((item) =>
        item.userId === isdata?.userId ? { ...item, role: data } : item,
      ),
    }));
  }, [formik?.values?.interviewer?.role]);
  const AddInterviewerView = (
    <div className={styles.notes1}>
      <label
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: '5px',
          marginTop: '10px',
        }}
      >
        <Text bold size={13}>
          Interviewers
        </Text>

        <Flex
          row
          center
          onClick={closeAddInterviewerSlider}
          style={{ cursor: 'pointer' }}
        >
          <SvgAdd width={10} height={10} fill="#581854" />
          <Text bold style={{ padding: '0 0 0 5px' }} color="link">
            Add Interviewers
          </Text>
        </Flex>
      </label>
      <div
        style={{ borderBottom: '0.5px solid #581845', marginBottom: '10px' }}
      ></div>
      {editEventDetails !== null ? (
        <>
          {meetingForm &&
            meetingForm?.attentee?.map((user, index) => (
              <div
                key={index}
                className={styles.notes2}
                style={{
                  marginBottom:
                    index !== formik.values.interviewers.length - 1
                      ? '10px'
                      : '0px',
                }}
              >
                <InputText
                  label="Interviewer Name"
                  required
                  value={`${user.firstName} ${user.lastName}`}
                  disabled
                />
                <Flex>
                  <InputSearch
                    setFieldValue={formik.setFieldValue}
                    name={`interviewer.role`}
                    label="Role"
                    placeholder="Add a role"
                    options={role}
                    initialValue={user?.role !== null ? user?.role : ''}
                    onChange={(e) => handleChange(index, e, user)}
                    onkeyPress={(e) => handlekeyChange(index, e, user)}
                    required
                  />
                  {(meetingForm.attentee[index]?.role === '' ||
                    meetingForm.attentee[index]?.role === undefined ||
                    meetingForm.attentee[index]?.role === undefined) &&
                    errors && <div className={styles.warn}>{errors}</div>}
                </Flex>
              </div>
            ))}
        </>
      ) : (
        <>
          {formik.values.interviewers.length > 0 &&
            formik.values.interviewers.map((user, index) => (
              <div
                key={index}
                className={styles.notes2}
                style={{
                  marginBottom:
                    index !== formik.values.interviewers.length - 1
                      ? '10px'
                      : '0px',
                }}
              >
                <InputText
                  label="Interviewer Name"
                  required
                  value={`${user.firstName} ${user.lastName}`}
                  disabled
                />
                <Flex>
                  <InputSearch
                    setFieldValue={formik.setFieldValue}
                    name={`interviewers[${index}].role`}
                    label="Role"
                    placeholder="Add a roles"
                    options={role}
                    initialValue={
                      formik.values.interviewers[index]?.role !== null
                        ? formik.values.interviewers[index]?.role
                        : ''
                    }
                    onChange={(e) => handleChange(index, e)}
                    onkeyPress={(e) => handlekeyChange(index, e)}
                    required
                  />
                  {errors.length !== 0 &&
                    (!local[index]?.role ||
                      local[index]?.role === undefined) && (
                      <div className={styles.warn}>{errors[0]}</div>
                    )}
                </Flex>
              </div>
            ))}
        </>
      )}
      <>
        {openAddInterviewerModal && (
          <AddInterviewerSlider
            currentUserEvents={currentUserEvents}
            currentUser={currentUser}
            removeTeamInterviewer={removeTeamInterviewer}
            addTeamInterviewer={addTeamInterviewer}
            teamMembers={teamMembers}
            userLabel={currentUserLabel}
            jd={meetingForm.job.label}
            formik={formik}
            meetingForm={meetingForm}
            editEventDetails={editEventDetails}
            username={username}
            interviewer={formik.values?.attentee}
            setlist={setlist}
            openAddInterviewerModal={openAddInterviewerModal}
            closeAddInterviewerSlider={closeAddInterviewerSlider}
            selectedInterviewers={meetingForm.attentee}
            updatestate={updatestate}
          />
        )}
      </>
    </div>
  );

  const LocationView = (
    <>
      {meetingForm?.location?.isHave ||
        (editEventDetails &&
          meetingForm?.eventType?.value === 'Onsite Interview') ? (
        <div className={styles.location}>
          <label className={styles.label}>Location*</label>
          {editEventDetails && meetingForm?.location?.value === '' ? (
            <InputText
              value={meetingForm.location.name}
              textarea={true}
              onChange={handleLocation}
              style={{ height: '50px' }}
              className={styles.location}
              placeholder="Add Location"
              required
            />
          ) : (
            <InputText
              value={meetingForm.location.name}
              textarea={true}
              onChange={handleLocation}
              style={{ height: '50px' }}
              className={styles.location}
              placeholder="Add Location"
              required
            />
          )}
          {meetingForm.location.error && (
            <p className={styles.warn}>This field is required.</p>
          )}
        </div>
      ) : null}
    </>
  );

  const NotesView = (
    <div className={styles.notes}>
      <label
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: '7px',
        }}
      >
        <p style={{ color: '#581845', fontSize: '13px' }}>Notes</p>
        <p style={{ fontSize: '13px' }}>Visible to candidates</p>
      </label>
      <InputText
        value={meetingForm.notes}
        textarea={true}
        placeholder="Add a note"
        onChange={notesHandler}
        style={{ height: '50px' }}
      />
    </div>
  );

  const PrivateNotesView = (
    <div className={styles.notes}>
      <label
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: '7px',
        }}
      >
        <p style={{ color: '#581845', fontSize: '13px' }}>Private Notes</p>
        <p style={{ fontSize: '13px' }}>Visible only to the team members</p>
      </label>
      <InputText
        value={meetingForm.privateNotes}
        textarea={true}
        placeholder="Add a note"
        onChange={privateNotesHandler}
        style={{ height: '50px' }}
      />
    </div>
  );

  const ActionButtonView = (
    <div
      className={styles.actionButtonWrapper}
      style={{ borderTop: '1px solid #c3c3c3' }}
    >
      <div className={styles.buttonContainer}>
        <button
          onClick={handleCloseSchedulingForm}
          className={styles.cancelButton}
        >
          Cancel
        </button>
      </div>
      <div>
        <Button onClick={handleContinue} className={styles.continueButton}>
          Continue
        </Button>
      </div>
    </div>
  );

  const DurationView = () => {
    const { startTime, endTime } = meetingForm;
    if (
      startTime.value &&
      !startTime.errorMessage &&
      endTime.value &&
      !endTime.errorMessage
    ) {
      // getting the start time for calculating the total hours between starttime to endtime.
      const timeStrings: string = isdats || '';
      const inputDatefor: Date = new Date();
      inputDatefor.setHours(0, 0, 0, 0);
      const hours =
        (parseInt(timeStrings.split(':')[0]) % 12) +
        (timeStrings.includes('PM') ? 12 : 0);
      const minutes = parseInt(timeStrings.split(':')[1]) || 0;
      inputDatefor.setHours(inputDatefor.getHours() + hours, minutes);
      const outputTimeStrings: string = inputDatefor.toLocaleTimeString();

      // getting the end time for calculating the total hours between starttime to endtime.
      const timeStrings2: string = isdats2 || '';
      const inputDate2: Date = new Date();
      inputDate2.setHours(0, 0, 0, 0);
      const hours2 =
        (parseInt(timeStrings2.split(':')[0]) % 12) +
        (timeStrings2.includes('PM') ? 12 : 0);
      const minutes2 = parseInt(timeStrings2.split(':')[1]) || 0;
      inputDate2.setHours(inputDate2.getHours() + hours2, minutes2);
      const outputTimeStrings2: string = inputDate2.toLocaleTimeString();

      const parseTime = (timeStr) => parse(timeStr, 'HH:mm:ss', new Date());
      const time1 = parseTime(outputTimeStrings);
      let time2 = parseTime(outputTimeStrings2);
      if (isBefore(time2, time1)) {
        time2 = addDays(time2, 1);
      }
      const minuteDifference = differenceInMinutes(time2, time1);
      return (
        <div className={styles.duration}>
          <p
            style={{ marginBottom: '4px', color: '#581845', fontSize: '13px' }}
          >
            Duration
          </p>
          <p>{formatTime(minuteDifference)?.replaceAll('-', '')}</p>
        </div>
      );
    }
    return (
      <div className={styles.duration}>
        <p style={{ marginBottom: '4px', color: '#581845', fontSize: '13px' }}>
          Duration
        </p>
        <p style={{ fontSize: '13px' }}>00 : 00</p>
      </div>
    );
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        overflow: 'unset',
      }}
    >
      <Flex
        row
        center
        style={{
          position: 'relative',
          padding: '25px 0px 0px',
          margin: '0px 25px',
          borderBottom: '0.5px solid #581845',
        }}
      >
        <Text
          size={14}
          bold
          className={styles.formTitle}
          style={{ marginBottom: '5px' }}
        >
          Schedule Events
        </Text>
      </Flex>

      <Flex marginLeft={25} marginTop={5}>
        <Required />
      </Flex>
      <div className={styles.meetingForm}>
        {ApplicantView}
        {JobTitleView}
        {DateView}
        {TimingView}
        {<DurationView />}
        {<TimeZoneView />}
        {EventTypeView}
        {RemindarView}
        {LocationView}
        {AddInterviewerView}
        {NotesView}
        {PrivateNotesView}
      </div>
      <Flex style={{ padding: '0px 25px 25px 25px' }}>{ActionButtonView}</Flex>
    </div>
  );
};

export default MeetingSchedulingForm;
