import { SetStateAction, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Tab from '../../uikit/Tab/Tab';
import Tabs from '../../uikit/Tab/Tabs';
import { EmpPoolEntity } from './bulkImportTypes';
import CandidateDatabaseTab from './CandidateDatabaseTab';
import Applicants from './applicants';
import LogFileTab from './LogFileTab';
type Props = {
  emp_pool?: EmpPoolEntity[];
  params: any;
  total_count: number;
  completed: number;
  incompleted: number;
  handleTotal: () => void;
  handleSubmit: () => void;

  handleCompleted: () => void;

  handleInCompeleted: () => void;
  // handleApplicantView: () => void;
  searchValue: any;
  jd_id: any;
  searchHandleChange: any;
  features_balance: number;
  setFeaturesBalance: (a: number | null) => void;
  isSearch: number;
  formik: any;
  setPageNumber: (a: number) => void;
  pageNumber: number;
  upDateloader: boolean
  setbulktab?: any;
  isBulkTab?:any;
  istableloadertrue?: boolean;
};


const BulkImportTabs = ({
  emp_pool,
  total_count,
  completed,
  incompleted,
  handleTotal,
  handleSubmit,
  jd_id,
  handleCompleted,
  handleInCompeleted,
  searchValue,
  searchHandleChange,
  features_balance,
  setFeaturesBalance,
  isSearch,
  formik,
  setPageNumber,
  pageNumber,
  upDateloader,
  setbulktab,
  params,
  isBulkTab,
  istableloadertrue
}: Props) => {
  const [tabKey, setKey] = useState('0');
  const [isjd_iddata, setjd_iddata] = useState<any>(null);
  const useQuery = new URLSearchParams(window.location.search);
  const query = useQuery;
  const history = useHistory();
  const tab: any = query.get('tab');
  const jd_ids: any = query.get('jd_ids');
  if (tab) {
    setKey(tab)
    if (query.has('tab')) {
      query.delete('tab');
      history.replace({
        search: query.toString(),
      });
    }
  }
  if (jd_ids) {
    setjd_iddata(jd_ids)
    if (query.has('jd_ids')) {
      query.delete('jd_ids');
      history.replace({
        search: query.toString(),
      });
    }
  }
  useEffect(() => {
    if (tabKey !== '1') {
      setjd_iddata(null)
    }
    setbulktab(tabKey)
  }, [tabKey])

  return (
    <Tabs
      activeKey={tabKey}
      onSelect={(keys: SetStateAction<string>) => setKey(keys)}
    >
      <Tab title={'Candidate Database'} eventKey={'0'}>
        {tabKey === '0' &&
          <CandidateDatabaseTab
          // emp_pool={emp_pool}
          // total_count={total_count}
          // completed={completed}
          // incompleted={incompleted}
          handleTotal={handleTotal}
          handleSubmit={handleSubmit}
          handleCompleted={handleCompleted}
          handleInCompeleted={handleInCompeleted}
          searchValue={searchValue}
          searchHandleChange={searchHandleChange}
          setKey={setKey}
          // features_balance={features_balance}
          setFeaturesBalance={setFeaturesBalance}
          isSearch={isSearch}
          formik={formik}
          setPageNumber={setPageNumber}
          pageNumber={pageNumber} 
          isBulkTab={isBulkTab}
          // upDateloader={upDateloader}
          />
        }
      </Tab>
      <Tab title={'Job Applicants'} eventKey={'1'} >
        {tabKey === '1' &&
          <Applicants
          emp_pool={emp_pool}
          total_count={total_count}
          jd_id={jd_id}
          completed={completed}
          incompleted={incompleted}
          // handleTotal={handleTotal}
          // handleSubmit={handleSubmitWithJd}
          // handleInCompeleted={handleInCompeleted}
          searchValue={searchValue}
          isjd_iddata={isjd_iddata}
          setjd_iddata={setjd_iddata}
          searchHandleChange={searchHandleChange}
          setKey={setKey}
          features_balance={features_balance}
          setFeaturesBalance={setFeaturesBalance}
          istableloadertrue={istableloadertrue}
          setPageNumber={setPageNumber}
          pageNumber={pageNumber}
          upDateloader={upDateloader}
          isBulkTab={tabKey} 
          params={params}          
          />
        }
      </Tab>
      <Tab title={'Upload Log'} eventKey={'2'}>
        <LogFileTab getKey={tabKey} />
      </Tab>
    </Tabs>
  );
};

export default BulkImportTabs;
